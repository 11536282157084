import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson ProductInfo component.
 *
 * Generated React component. Do not modify.
 */
export function ProductInfo({
  testId,
  className,
  title,
  titleSuffix,
  subtitle,
  imgSrc,
  imgSrcSet,
  imgSizes,
  imgAlt,
  imgProps,
  children,
  ...otherProps /* in <div> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      'c-product-info ': true,
    },
    className
  ).trim()

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={divClassNameList}>
      <div className="c-product-info__media">
        <h3 className="c-product-info__title">
          {title}
          <span className="u-heading-alternative">{titleSuffix}</span>
        </h3>
        <figure className="c-product-info__figure" role="group">
          <img
            className="c-product-info__image"
            src={imgSrc}
            srcSet={imgSrcSet}
            sizes={imgSizes}
            alt={imgAlt}
            {...imgProps}
          />
          <figcaption className="c-product-info__caption">{subtitle}</figcaption>
        </figure>
      </div>
      <div className="c-product-info__aside">{children}</div>
    </div>
  )
}

ProductInfo.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  className: PropTypes.string, // Bronson template: 'modifiers'.
  title: PropTypes.node, // Bronson template: 'product-info-title'.
  titleSuffix: PropTypes.node, // Bronson template: 'product-info-title-suffix'.
  subtitle: PropTypes.node, // Bronson template: 'product-info-subtitle'.
  imgSrc: PropTypes.string, // Bronson template: 'product-info-img'.
  imgSrcSet: PropTypes.string,
  imgSizes: PropTypes.string,
  imgAlt: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  imgProps: PropTypes.object,
  children: PropTypes.node,
}
