import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Badge } from '../Badge/Badge'
import { Button } from '../Button/Button'
import { NotificationCenterItem } from './NotificationCenterItem/NotificationCenterItem'

const notClosed = (item) => !item.closed
const mouseEvent = 'click'

/**
 * Bronson NotificationCenter component.
 *
 * @param {string} [toggleLabel] - The toggle label.
 * @param {string} [panelHeading] - The heading text.
 * @param {boolean} [defaultOpen=false] - Show/hide the notification center on default.
 * @param {{id:(string|number), title: string, read: boolean}[]} [notifications] - A collection of notification items to render.
 * @param {string} [closeButtonLabel] - A label for the close button.
 * @param {string} [children] - Children to render inside the NotificationCenterItems from.
 * @param {string} [testId] - Value for the `[data-testid]` attribute on NotificationCenter element.
 * @param {object} [otherProps] - Other properties to pass to the NotificationCenter element.
 * @return {JSX.Element} - The NotificationCenter component.
 * @constructor
 */
export function NotificationCenter({
  toggleLabel,
  panelHeading,
  defaultOpen = false,
  testId,
  children,
  notifications,
  closeButtonLabel = 'CLOSE',
  ...otherProps /* in <div> tag */
}) {
  const ncPanelRef = useRef(null)

  const [open, setOpen] = useState(defaultOpen)
  const [itemsToShow, setItemsToShow] = useState([])

  useEffect(() => {
    setItemsToShow(notifications.filter(notClosed))
  }, [notifications])

  const countUnread = itemsToShow && itemsToShow.filter((item) => !item.read).length

  useEffect(() => {
    const handleClick = (event) => {
      if (ncPanelRef.current?.contains(event.target)) {
        return
      }
      setOpen(false)
    }
    document.addEventListener(mouseEvent, handleClick)
    return () => {
      document.removeEventListener(mouseEvent, handleClick)
    }
  }, [])

  const onToggleOpen = (event) => {
    event.nativeEvent.stopImmediatePropagation()
    setOpen((newOpen) => !newOpen)
  }

  const updateItem = (item, items) => items.map((i) => (i.id === item.id ? { ...item } : i))

  const notificationCenterClassNameList = classNames('c-notification-center', 'js-notification-center', {
    'is-active': open,
  }).trim()

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={notificationCenterClassNameList}>
      <button
        onClick={onToggleOpen}
        className="c-notification-center__toggle js-notification-center__toggle"
        type="button"
        aria-expanded={open}
      >
        <span className="c-notification-center__toggle__label">{toggleLabel}</span>
        {countUnread > 0 && (
          <Badge modifier="notification" wrapperClass="c-notification-center__toggle__badge">
            {countUnread}
          </Badge>
        )}
      </button>
      {itemsToShow.length > 0 && (
        <section
          className="c-notification-center__panel js-notification-center__panel"
          aria-hidden={!open}
          ref={ncPanelRef}
        >
          <header className="c-notification-center__panel__header">
            <h2 className="c-notification-center__panel__heading">{panelHeading}</h2>
            <Button
              onClick={onToggleOpen}
              className="c-notification-center__panel__close js-notification-center__toggle"
              link
              small
              simple
              icon
              iconReversed
              iconViaCss
            >
              {closeButtonLabel}
            </Button>
          </header>
          {itemsToShow.map((n) => {
            const notificationCenterItem = children(n)
            return React.cloneElement(notificationCenterItem, {
              key: n.id,
              unread: !n.read,
              title: n.title,
              onClose: () => {
                notificationCenterItem.props.onClose && notificationCenterItem.props.onClose(n)
                // eslint-disable-next-line no-param-reassign
                n.closed = true
                setItemsToShow((items) => items.filter(notClosed))
              },
              onClick: () => {
                notificationCenterItem.props.onClick && notificationCenterItem.props.onClick(n)
                setOpen(false)
              },
              onMarkReadUnread: () => {
                notificationCenterItem.props.onMarkReadUnread && notificationCenterItem.props.onMarkReadUnread(n)
                // eslint-disable-next-line no-param-reassign
                n.read = !n.read
                setItemsToShow((items) => updateItem(n, items))
              },
            })
          })}
        </section>
      )}
    </div>
  )
}

NotificationCenter.propTypes = {
  toggleLabel: PropTypes.string, // Bronson template: 'toggle-label'.
  panelHeading: PropTypes.string, // Bronson template: 'panel-heading'.
  defaultOpen: PropTypes.bool, // Bronson template: 'open'.
  closeButtonLabel: PropTypes.string, // Bronson template: 'close-button-label'
  testId: PropTypes.string, // Added for `[data-testid]` attribute.
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
      read: PropTypes.bool.isRequired,
    })
  ),
  children: PropTypes.func, // Bronson template: 'items'. Use 'NotificationCenter.Item' component.
}

NotificationCenter.Item = NotificationCenterItem
NotificationCenter.Item.displayName = 'NotificationCenter.Item'
