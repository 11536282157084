import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// TODO question this and discuss
export function Wrapper({ children, pageWrapper = false }) {
  if (pageWrapper) {
    return <div className="o-main-container">{children}</div>
  }
  return <main>{children}</main>
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  pageWrapper: PropTypes.bool,
}

export function Layout(props) {
  const componentListClass = classNames(
    {
      'o-layout  ': true,
      'o-layout--middle  ': props.middle,
      'o-layout--equal-height  ': props.equalHeight,
      'o-layout--center  ': props.center,
      'o-layout--right  ': props.right,
      'o-layout--flush  ': props.flush,
      'o-layout--divider  ': props.divider,
    },
    props.className
  ).trim()

  return <div className={componentListClass}>{props.children}</div>
}

Layout.propTypes = {
  className: PropTypes.string,
  middle: PropTypes.bool,
  equalHeight: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
  flush: PropTypes.bool,
  divider: PropTypes.bool,
  children: (props) => {
    if (React.Children.toArray(props.children).some((child) => child.type !== LayoutItem)) {
      // Do not throw an error, accept other types that may encapsulate a layout item.
      // return new Error("'Layout' children should be of type 'Layout.Item'")
    }
    return undefined
  },
}

// "Layout-Item" is Bronson doc (not Column)
export function LayoutItem(props) {
  const componentListClass = classNames(
    {
      'o-layout__item  ': true,
      [`u-${props.default}  `]: props.default,
      [`u-${props.xl}@xl  `]: props.xl,
      [`u-${props.l}@l  `]: props.l,
      [`u-${props.m}@m  `]: props.m,
      [`u-${props.s}@s  `]: props.s,
      [`u-${props.xs}@xs  `]: props.xs,
    },
    props.className
  ).trim()

  return <div className={componentListClass}>{props.children}</div>
}

const allowedFractions = [
  '1/1',
  '1/2',
  '2/2',
  '1/3',
  '2/3',
  '3/3',
  '1/4',
  '2/4',
  '3/4',
  '4/4',
  '1/5',
  '2/5',
  '3/5',
  '4/5',
  '5/5',
  '1/6',
  '2/6',
  '3/6',
  '4/6',
  '5/6',
  '6/6',
  '1/12',
  '2/12',
  '3/12',
  '4/12',
  '5/12',
  '6/12',
  '7/12',
  '8/12',
  '9/12',
  '10/12',
  '11/12',
  '12/12',
]

LayoutItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  default: PropTypes.oneOf(allowedFractions),
  xl: PropTypes.oneOf(allowedFractions),
  l: PropTypes.oneOf(allowedFractions),
  m: PropTypes.oneOf(allowedFractions),
  s: PropTypes.oneOf(allowedFractions),
  xs: PropTypes.oneOf(allowedFractions),
}

LayoutItem.displayName = 'Layout.Item'
Layout.Item = LayoutItem
