/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import imagesloaded from 'imagesloaded'
import PropTypes from 'prop-types'
import Flickity from 'flickity'
import './basic-slider.styles.css'

/**
 * BasicSlider component.
 * @desc Internal component to utilise a {@link Flickity} slider.
 * @internal
 *
 * @param {string} [elementType='div'] - The element type to render for the main slider element.
 * @param {string} [options] - Pass-through {@link Flickity} options.
 * @param {string} [className] - Additional classes to append.
 * @param {function} [flickityRef] - A callback function that get the current {@link Flickity} ref passed.
 * @param {function} [onReady=()={}] - A callback function that gets called with the current {@link Flickity} ref passed when the slider passes its initialization steps.
 * @param {string} [testId] - Sets the `[data-testid]` attribute on table.
 * @param {React.ReactNodeArray} [children] - The children to render as {@link Flickity} slides.
 * @return {JSXElement} - The BasicSlider element.
 * @constructor
 */
export function BasicSlider({
  elementType = 'div',
  options,
  className,
  flickityRef,
  onReady = () => {},
  testId,
  children,
}) {
  const CustomElement = `${elementType}`
  const flkty = useRef(null)
  const [ready, setReady] = useState(false)
  const [isAlreadyReady, setIsAlreadyReady] = useState(false)
  const [flickityNode, setFlickityNode] = useState(null)

  /**
   * Initialize selected index and draggable and reload cells.
   */
  useEffect(() => {
    const { draggable, initialIndex } = options
    if (ready && !isAlreadyReady) {
      setIsAlreadyReady(true)
      const { isActive } = flkty.current
      flkty.current.deactivate()
      flkty.current.selectedIndex = initialIndex || 0
      if (draggable === undefined) {
        flkty.current.options.draggable = children ? children.length > 1 : false
      } else {
        flkty.current.options.draggable = draggable
      }
      if (isActive) {
        flkty.current.activate()
      }
      if (options.imagesLoaded && flickityNode) {
        imagesloaded(flickityNode, () => {
          flkty.current.reloadCells()
        })
      }
    } else if (ready && isAlreadyReady) {
      onReady(flkty?.current)
    } else {
      flkty.current.reloadCells()
    }
    return () => {}
  }, [options, children, flickityNode, ready, isAlreadyReady, onReady])

  /**
   * Add children to flickity slider element via React Portal and set ready state.
   */
  const renderPortal = useCallback(() => {
    if (flickityNode) {
      const sliderNode = flickityNode.querySelector('.flickity-slider')
      const element = createPortal(children, sliderNode)
      const setFlickityReady = () => {
        if (!ready) {
          const changeToReady = () => setReady(true)
          if (options.imagesLoaded) {
            imagesloaded(flickityNode, changeToReady)
          } else {
            changeToReady()
          }
        }
        return null
      }
      setTimeout(setFlickityReady, 0)
      return element
    }
    return null
  }, [flickityNode, children, ready, options.imagesLoaded])

  /**
   * Create the Flickity object and save underlying node element to a React state.
   * Using callback refs, @see https://reactjs.org/docs/refs-and-the-dom.html#callback-refs .
   */
  const flickityWrapperRef = useCallback(
    (node) => {
      if (!flkty.current) {
        flkty.current = new Flickity(node, options)
        if (flickityRef) {
          flickityRef(flkty.current)
        }
        setFlickityNode(node)
      }
      return null
    },
    [options, flickityRef, setFlickityNode]
  )

  return (
    <CustomElement className={className} ref={flickityWrapperRef} data-testid={testId}>
      {renderPortal()}
    </CustomElement>
  )
}

BasicSlider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  elementType: PropTypes.string,
  flickityRef: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
  testId: PropTypes.string, // Added for data-testid attribute.
  onReady: PropTypes.func, // Added for data-testid attribute.
}

export default BasicSlider
