import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'
/*
 * Bronson MediaTeaser component.
 *
 * Generated React component. Do not modify.
 */
export function MediaTeaser({
  children,
  className,
  video,
  imgProps,
  pretitle,
  title,
  onButtonClick,
  buttonLabel,
  linkButton,
  buttonModifier,
  testId,
  reversed,
  vertical,
  fullLink,
  fullLinkFooter,
  buttonProps,
  ...otherProps /* in <section> tag */
}) {
  // generated
  const sectionClassNameList = classNames(
    {
      'c-media-teaser ': true,
      // main class modifier convenience prop extension
      'c-media-teaser--reversed ': reversed, // Convenience prop from Bronson variants.
      'c-media-teaser--vertical ': vertical, // Convenience prop
      'c-media-teaser--full-link': fullLink,
    },
    className
  ).trim()

  const btnClassNameList = classNames(
    {
      'c-media-teaser__link': linkButton && !fullLink,
    },
    buttonModifier
  ).trim()

  const renderIfFullLink = () => {
    if (fullLink) {
      return fullLinkFooter
    }
    return null
  }

  function renderIfButtonLabel() {
    if (buttonLabel) {
      return linkButton ? (
        <>
          <Button
            className={btnClassNameList}
            onClick={onButtonClick}
            element="a"
            icon="semantic-forward"
            link
            small
            iconReversed
            {...buttonProps}
          >
            {buttonLabel}
          </Button>
        </>
      ) : (
        <>
          <Button className={btnClassNameList} onClick={onButtonClick} {...buttonProps}>
            {buttonLabel}
          </Button>
        </>
      )
    }
    return null
  }

  // generated main result
  return (
    <section {...otherProps} data-testid={testId} className={sectionClassNameList}>
      <div className="c-media-teaser__media">
        {video || (imgProps && <img className="c-media-teaser__image js-object-fit" alt="" {...imgProps} />)}
      </div>
      <div className="c-media-teaser__caption">
        <div className="c-media-teaser__pretitle">{pretitle}</div>
        <h3 className="c-media-teaser__title">{title}</h3>
        <div className="c-media-teaser__body">{children}</div>
        <div className="c-media-teaser__footer">
          {renderIfFullLink()}
          {renderIfButtonLabel()}
        </div>
      </div>
    </section>
  )
}

MediaTeaser.propTypes = {
  children: PropTypes.node, // Bronson template: 'media-teaser-text'.
  className: PropTypes.string, // Bronson template: 'media-teaser-modifier'.
  video: PropTypes.node, // Bronson template: 'bronson-video--video-youtube'.
  imgProps: PropTypes.shape({
    alt: PropTypes.string,
    srcSet: PropTypes.string,
    src: PropTypes.string,
    sizes: PropTypes.string,
  }), // Bronson template: 'srcset'.
  pretitle: PropTypes.string, // Bronson template: 'media-teaser-pretitle'.
  title: PropTypes.string, // Bronson template: 'media-teaser-title'.
  onButtonClick: PropTypes.func, // Bronson template: 'media-teaser-button-link-href'.
  buttonLabel: PropTypes.string, // Bronson template: 'media-teaser-button-text'.
  linkButton: PropTypes.bool, // Bronson template: 'media-teaser-has-link-button'.
  buttonModifier: PropTypes.string, // Bronson template: 'media-teaser-button-modifier'.
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  reversed: PropTypes.bool, // Convenience prop for c-media-teaser--reversed (Bronson template: 'media-teaser-modifier').
  vertical: PropTypes.bool, // Convenience prop for c-media-teaser--vertical.
  fullLink: PropTypes.bool, // Convenience prop for c-media-teaser--full-link.
  fullLinkFooter: PropTypes.node, // see Bronson media teaser full link
}
