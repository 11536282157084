import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import deprecated from '../../utils/deprecated'

/**
 * Bronson ButtonContainer object (component).
 * The props can be mixed and matched if need be.
 * @param children
 * @param {string} [className] - Additional class names for the container.
 * @param {string} [testId] - The testId string.
 * @param {boolean} [center] - Align the items from the center.
 * @param {boolean} [centerWrap] - Vertically center the items. This prop is deprecated since Bronson 8.0.0.
 * @param {boolean} [left] - Align the items to the left.
 * @param {boolean} [right] - Align the items to the right.
 * @param {boolean} [stretch] - Stretch the items to the container width.
 * @param {boolean} [nav] - Brand-specific alignment of the items.
 * @param {boolean} [vertical] - Stack the items vertically.
 * @param {boolean} [noWrap] - Do not wrap items into a new row.
 * @param {boolean} [wrapReverse] - Reverse wrap items into a new row.
 * @param {boolean} [reverse] - Reverse the order of the items.
 * @param {boolean} [leftSmall] - Align the items to the left on small screens only.
 * @param {boolean} [centerSmall] - Align the items from the center on small screens only.
 * @param {boolean} [rightSmall] - Align the items to the right on small screens only.
 * @param {boolean} [verticalSmall] - Vertically center the items on small screens only.
 * @param {boolean} [reverseSmall] - Reverse the order of the items on small screens only.
 * @param {object} [otherProps] - Other properties to pass to the ButtonContainer element.
 * @return {JSX.Element} - The ButtonContainer element.
 * @constructor
 */
export function ButtonContainer({
  children,
  className,
  testId,
  center,
  centerWrap,
  left,
  right,
  stretch,
  nav,
  vertical,
  noWrap,
  wrapReverse,
  reverse,
  leftSmall,
  centerSmall,
  rightSmall,
  verticalSmall,
  reverseSmall,
  ...otherProps /* in <div> tag */
}) {
  const divClassNameList = classNames(
    {
      'o-button-container ': true,
      // main class modifier convenience prop extension
      'o-button-container--center ': center, // Convenience prop
      'o-button-container--left ': left, // Convenience prop
      'o-button-container--right ': right, // Convenience prop
      'o-button-container--nav ': nav, // Convenience prop
      'o-button-container--stretch ': stretch, // Convenience prop
      'o-button-container--vertical ': vertical, // Convenience prop
      'o-button-container--nowrap ': noWrap, // Convenience prop
      'o-button-container--wrap-reverse ': wrapReverse, // Convenience prop
      'o-button-container--reverse ': reverse, // Convenience prop
      /**
       * `o-button-container--center-wrap` was replaced in Bronson.
       * @see https://jira.tools.platform.vwfs.io/browse/BRON-5562
       */
      'o-button-container--center o-button-container--vertical ': centerWrap, // Convenience prop
      'o-button-container--left@s ': leftSmall, // Convenience prop
      'o-button-container--center@s ': centerSmall, // Convenience prop
      'o-button-container--right@s ': rightSmall, // Convenience prop
      'o-button-container--vertical@s ': verticalSmall, // Convenience prop
      'o-button-container--reverse@s ': reverseSmall, // Convenience prop
    },
    className
  ).trim()

  function renderChildren() {
    return React.Children.map(children, (child) => {
      if (!child) {
        return null
      }
      const buttonClassName = `o-button-container__button ${child.props.className ? child.props.className : ''}`
      return React.cloneElement(child, { className: buttonClassName })
    })
  }

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={divClassNameList}>
      {renderChildren()}
    </div>
  )
}

ButtonContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  center: PropTypes.bool,
  centerWrap: deprecated(PropTypes.bool, 'Please use "center" and "vertical" instead.'), // Deprecated since Bronson 8.0.0
  left: PropTypes.bool,
  right: PropTypes.bool,
  stretch: PropTypes.bool,
  nav: PropTypes.bool,
  vertical: PropTypes.bool,
  noWrap: PropTypes.bool,
  wrapReverse: PropTypes.bool,
  reverse: PropTypes.bool,
  leftSmall: PropTypes.bool,
  centerSmall: PropTypes.bool,
  rightSmall: PropTypes.bool,
  verticalSmall: PropTypes.bool,
  reverseSmall: PropTypes.bool,
}
