import React, { useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TableContext = createContext()
const TheadOrTbodyContext = createContext()

/**
 * Bronson Table component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-table--table.html
 *
 * @param {(Table.Caption | Table.Thead | Table.Tbody | Table.Tfoot)} children - use one of {@link Table.Caption}, {@link Table.Thead}, {@link Table.Tbody}, {@link Table.Tfoot}
 * @param {boolean} [auto] - center table and don't use full-width
 * @param {boolean} [noScroll] - prevent horizontal scrollbar
 * @param {boolean} [noHover] - without hover effects
 * @param {boolean} [colored] - adds alternate colors to rows
 * @param {boolean} [bordered] - add vertical border to cells
 * @param {boolean} [highlight] - highlight certain cells, {@link Table.Td#highlight}
 * @param {boolean} [wide] - wide variant with larger fonts and paddings
 * @param {boolean} [narrow] - narrow variant with smaller fronts and paddings
 * @param {boolean} [responsive] - responsive layout, @see {@link tableHeader}
 * @param {boolean} [tableHeader] - ordered list of table header for responsive variant
 * @param {boolean} [stickyHead] - sticky table head row
 * @param {boolean} [stickyColumn] - sticky first column
 * @param {string} [className] - additional classes on 'c-table-wrapper' div element
 * @param {string} [testId] - data-testid attribute on table
 * @return {JSX.Element} - Table element.
 */
export const Table = ({
  children,
  auto,
  noScroll,
  noHover,
  colored,
  bordered,
  highlight,
  wide,
  narrow,
  responsive,
  tableHeader,
  stickyHead,
  stickyColumn,
  className,
  testId,
}) => {
  const componentListClass = classNames(
    'c-table-wrapper',
    {
      'c-table-wrapper--auto': auto,
      'c-table-wrapper--no-scroll': noScroll,
      'c-table--colored': colored,
      'c-table--bordered': bordered,
      'c-table--highlight': highlight,
      'c-table--wide': wide,
      'c-table--narrow': narrow,
      'c-table--responsive js-table-responsive': responsive,
      'c-table--sticky-head': stickyHead,
      'c-table--sticky-column': stickyColumn,
      'c-table--no-hover': noHover,
    },
    className
  ).trim()

  return (
    <div className={componentListClass}>
      <TableContext.Provider value={{ tableHeader, responsive }}>
        <table role="table" data-testid={testId}>
          {children}
        </table>
      </TableContext.Provider>
    </div>
  )
}

Table.propTypes = {
  children: PropTypes.node,
  auto: PropTypes.bool,
  noScroll: PropTypes.bool,
  noHover: PropTypes.bool,
  colored: PropTypes.bool,
  bordered: PropTypes.bool,
  highlight: PropTypes.bool,
  wide: PropTypes.bool,
  narrow: PropTypes.bool,
  responsive: PropTypes.bool, // Bronson template 'table-responsive'
  tableHeader: PropTypes.arrayOf(PropTypes.string),
  stickyHead: PropTypes.bool,
  stickyColumn: PropTypes.bool,
  className: PropTypes.string,
  testId: PropTypes.string,
}

Table.Caption = Caption
Table.Thead = Thead
Table.Tbody = Tbody
Table.Tfoot = Tfoot
Table.Tr = Tr
Table.Th = Th
Table.Td = Td

/**
 * Caption component (table caption).
 *
 * @param {string} children - caption/title of the table
 * @return {JSX.Element} - Caption element.
 */
function Caption({ children }) {
  return <caption>{children}</caption>
}

Caption.propTypes = {
  children: PropTypes.string,
}

/**
 * Thead component (table head).
 *
 * @param {Table.Tr} children - use {@link Table.Tr} and {@link Table.Th} inside
 * @param {string} [testId] - data-testid attribute on thead
 * @return {JSX.Element} - Thead element.
 */
function Thead({ children, testId }) {
  return (
    <TheadOrTbodyContext.Provider value={{ type: 'head' }}>
      <thead data-testid={testId}>{children}</thead>
    </TheadOrTbodyContext.Provider>
  )
}

/**
 * Tbody component (table body).
 *
 * @param {Table.Tr} children - use {@link Table.Tr} and respective {@link Table.Td} or {@link Table.Th} inside
 * @param {string} [testId] - data-testid attribute on tbody
 * @return {JSX.Element} - Tbody element.
 */
function Tbody({ children, testId }) {
  return (
    <TheadOrTbodyContext.Provider value={{ type: 'body' }}>
      <tbody data-testid={testId}>{children}</tbody>
    </TheadOrTbodyContext.Provider>
  )
}

/**
 * Tfoot component (table footer).
 *
 * @param {Table.Tr} children - use {@link Table.Tr} and respective {@link Table.Td} or {@link Table.Th} inside
 * @param {string} [testId] - data-testid attribute on tfoot
 * @return {JSX.Element} - Tfoot element.
 */
function Tfoot({ children, testId }) {
  return <tfoot data-testid={testId}>{children}</tfoot>
}

const childrenProps = {
  children: PropTypes.node,
  testId: PropTypes.string,
}

Thead.propTypes = childrenProps
Tbody.propTypes = childrenProps
Tfoot.propTypes = childrenProps

/**
 * Tr component (table row).
 *
 * @param {(Table.Td | Table.Th)} children - use respective {@link Table.Td} or {@link Table.Th}
 * @param {object} [otherProps] - further props applied to <i>tr</i> element
 * @return {JSX.Element} - Tr element.
 */
function Tr({ children, ...otherProps }) {
  const tableCtx = useContext(TableContext)
  const headOrBodyCtx = useContext(TheadOrTbodyContext)

  return (
    <tr role="row" {...otherProps}>
      {headOrBodyCtx && headOrBodyCtx.type === 'body' && tableCtx.responsive
        ? React.Children.map(children, (child, i) => {
            // skip <th> and assume it is the first child
            if (i === 0) {
              return child
            }
            return React.cloneElement(
              child,
              tableCtx.tableHeader && tableCtx.tableHeader[i - 1]
                ? { 'data-columnheader': tableCtx.tableHeader[i - 1] }
                : {}
            )
          })
        : children}
    </tr>
  )
}

Tr.propTypes = {
  children: PropTypes.node,
}

/**
 * Th component (table head cell).
 *
 * @param {HTMLElement} children - content of the head cell
 * @param {object} [otherProps] - further props applied to <i>th</i> element
 * @return {JSX.Element} - Th element.
 */
function Th({ children, ...otherProps }) {
  const context = useContext(TableContext)
  let scopeVal = null
  let roleVal = null
  if (context.type === 'head') {
    scopeVal = children && 'col'
    roleVal = 'columnheader'
  } else if (context.type === 'body') {
    scopeVal = 'row'
    roleVal = 'rowheader'
  }
  return (
    <th scope={scopeVal} role={roleVal} {...otherProps}>
      {children}
    </th>
  )
}

Th.propTypes = {
  children: PropTypes.node,
}

/**
 * Td component (table data cell).
 *
 * @param {HTMLElement} children - content of the data cell
 * @param {boolean} [highlight] - cell is highlighted
 * @param {string} [className] - additional classes on td element
 * @param {object} [otherProps] - further props applied to <i>td</i> element
 * @return {JSX.Element} - Td element.
 */
function Td({ className, highlight, children, ...otherProps }) {
  const componentListClass = classNames(
    'c-table__cell',
    {
      'c-table__cell--highlightW': highlight,
    },
    className
  ).trim()
  return (
    <td role="cell" className={componentListClass} {...otherProps}>
      {children}
    </td>
  )
}

Td.propTypes = {
  className: PropTypes.string,
  highlight: PropTypes.bool,
  children: PropTypes.node,
}
