import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson RangeSlider component.
 *
 * Generated React component. Do not modify.
 */
export function RangeSlider({
  className,
  id,
  min,
  max,
  value: customValue,
  onChange: customOnChange,
  testId,
  ...otherProps /* in <input> tag */
}) {
  const [value, setValue] = useState(customValue)

  React.useEffect(() => {
    setValue(customValue)
  }, [customValue])

  function onChange(e) {
    const newValue = e.target === undefined ? e : e.target.value
    setValue(newValue)
    customOnChange(e)
  }

  // generated
  const divClassNameList = classNames(
    {
      'c-range-slider ': true,
    },
    className
  ).trim()

  // generated main result
  return (
    <div className={divClassNameList}>
      <input
        {...otherProps}
        data-testid={testId}
        className="c-range-slider__input"
        id={id}
        type="range"
        value={value}
        onChange={onChange}
        min={min}
        max={max}
      />
    </div>
  )
}

RangeSlider.propTypes = {
  className: PropTypes.string, // Bronson template: 'range-slider-modifier'.
  id: PropTypes.string, // Bronson template: 'range-slider-id'.
  value: PropTypes.string, // Bronson template: 'range-slider-value'.
  min: PropTypes.string, // Bronson template: 'range-slider-min'.
  max: PropTypes.string, // Bronson template: 'range-slider-max'.
  // Bronson template: 'range-slider-attributes'. Replaced by {...otherProps}.
  testId: PropTypes.string, // Added for data-testid attribute.
  onChange: PropTypes.func,
}
