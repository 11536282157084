/* eslint-disable react-hooks/exhaustive-deps */
// TODO: check deps
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoIcon } from '../InfoIcon/InfoIcon'

const AccordionContext = React.createContext({})

/*
 * Bronson DescriptionListAccordion component.
 */
export function DescriptionListAccordion({
  className,
  multipleOpen,
  totalLabel,
  totalLabelNote,
  totalValue,
  headerSplit,
  // defaultOpen,
  testId,
  children,
  withBackground,
  // disableJsFx,
  ...otherProps /* in <div> tag */
}) {
  const [open, setOpen] = useState([])

  const contextValue = {
    // the value of "open" state
    isOpen,
    // callback function to toggle the open states
    toggleOpen: (ref) => toggleOpen(ref),
    // callback function to update the open states
    updateOpen: (ref, refToBeOpen) => updateOpen(ref, refToBeOpen),
    headerSplit,
  }

  function isOpen(ref) {
    return open.indexOf(ref) >= 0
  }

  /*
   * Updates the open state for a given item (ref) with a target condition (refToBeOpen bool).
   * Subsequently updates other states accordingly.
   */
  function updateOpen(ref, refToBeOpen) {
    if (multipleOpen) {
      const index = open.indexOf(ref)
      if (refToBeOpen) {
        // should be open
        if (index < 0) {
          // not yet in open list, add
          setOpen(open.concat(open, [ref]))
        }
        // should not be open
      } else if (index >= 0) {
        // present in list, remove
        setOpen(open.filter((value) => value !== ref))
      }
    } else if (refToBeOpen) {
      // set as only open
      setOpen([ref])
    } else {
      // set none to be open
      setOpen([])
    }
  }

  /*
   * Change open state for the given item (ref) without the need to know the current state.
   */
  function toggleOpen(ref) {
    updateOpen(ref, !(open.indexOf(ref) >= 0))
  }

  // generated
  const divClassNameList = classNames(
    {
      'c-accordion ': true,
      'js-accordion ': true,
      'js-is-fx ': false, // && !disableJsFx, // currently generally disabled
      // main class modifier convenience prop extension
      'c-accordion--bg-panel ': withBackground, // Convenience prop from Bronson variants.
      'c-accordion--header-split': headerSplit,
      'c-description-list-accordion__accordion': true,
    },
    className
  ).trim()

  // generated main result
  return (
    <section className="c-description-list-accordion">
      <div
        {...otherProps}
        data-testid={testId}
        className={divClassNameList}
        data-accordion-multiple={!multipleOpen && 'true'}
      >
        <AccordionContext.Provider value={contextValue}>{children}</AccordionContext.Provider>
      </div>
      <dl className="c-description-list-accordion__item  c-description-list-accordion__item--total">
        <dt className="c-description-list-accordion__item__label">
          <div className="c-description-list-accordion__item__label-text">{totalLabel}</div>
          <div className="c-description-list-accordion__item__label-note">{totalLabelNote}</div>
        </dt>
        <dd className="c-description-list-accordion__item__value">{totalValue}</dd>
      </dl>
    </section>
  )
}

DescriptionListAccordion.propTypes = {
  className: PropTypes.string, // Bronson template: 'accordion-modifier'.
  multipleOpen: PropTypes.bool, // Bronson template: 'group'.
  totalLabel: PropTypes.node,
  totalLabelNote: PropTypes.node,
  totalValue: PropTypes.node,
  headerSplit: PropTypes.bool,
  defaultOpen: (props) => {
    if (!props.defaultOpen && props.defaultOpen !== 0) {
      return undefined
    }

    if (Array.isArray(props.defaultOpen) && !props.multipleOpen) {
      return new Error('defaultOpen prop should be an integer when multipleOpen is false')
    }

    if (Number.isInteger(props.defaultOpen) && props.multipleOpen) {
      return new Error('defaultOpen prop should be an array of integers when multipleOpen is true')
    }

    return undefined
  },
  testId: PropTypes.string, // Added for data-testid attribute.
  // disableJsFx: PropTypes.bool, // Bronson template: 'disable-js-fx'.
  /* Convenience props */
  withBackground: PropTypes.bool, // Convenience prop for c-accordion--bg-panel (Bronson template: 'accordion-modifier').
}

/*
 * Bronson AccordionItem component.
 */
export function AccordionItem({
  className,
  title,
  subtitle,
  titleSplit,
  icon,
  tooltip,
  nonCollapsable,
  testId,
  children,
  defaultOpen,
  ...otherProps
}) {
  const itemRef = useRef(null)

  const context = React.useContext(AccordionContext)

  useEffect(() => {
    context.updateOpen(itemRef, defaultOpen)
  }, [defaultOpen])

  const childDivClassNameList = classNames(
    {
      'c-accordion__header ': true,
      'js-accordion__title ': true,
      'c-description-list-accordion__header': true,
      'is-active': context.isOpen(itemRef),
    },
    className
  ).trim()

  /**
   * Needed e.g. because IconList within the panel would result in icons staying on screen even when collapsed.
   * This is handled by Bronson JS otherwise.
   */
  const panelStyle = {
    display: context.isOpen(itemRef) ? 'inherit' : 'none',
  }

  const defaultCursor = nonCollapsable
    ? {
        cursor: 'default',
      }
    : {}

  return (
    <>
      <div
        className={childDivClassNameList}
        onClick={() => !nonCollapsable && context.toggleOpen(itemRef)}
        data-testid={testId}
        style={defaultCursor}
        {...otherProps}
      >
        {!nonCollapsable && <span className="c-accordion__title-icon" />}
        <h3 className="c-accordion__title">
          {nonCollapsable ? (
            <div className="c-accordion__title-label js-accordion__title-label" style={defaultCursor}>
              <span className="c-accordion__title-label-text c-description-list-accordion__title-label">
                {title}
                {icon && (
                  <InfoIcon className="c-accordion__title-info-icon" element="span" icon={icon}>
                    {tooltip}
                  </InfoIcon>
                )}
              </span>
              {context.headerSplit && (
                <span className="c-accordion__title-label-text c-description-list-accordion__title-value">
                  {titleSplit}
                </span>
              )}
            </div>
          ) : (
            <button
              className="c-accordion__title-label js-accordion__title-label"
              aria-expanded={context.isOpen(itemRef)}
              type="button"
            >
              <span className="c-accordion__title-label-text c-description-list-accordion__title-label">
                {title}
                {icon && (
                  <InfoIcon className="c-accordion__title-info-icon" element="span" icon={icon}>
                    {tooltip}
                  </InfoIcon>
                )}
              </span>
              {context.headerSplit && (
                <span className="c-accordion__title-label-text c-description-list-accordion__title-value">
                  {titleSplit}
                </span>
              )}
            </button>
          )}
        </h3>
        {subtitle && <p className="c-accordion__subtitle">{subtitle}</p>}
      </div>
      <div className="c-accordion__panel js-accordion__panel" aria-hidden={!context.isOpen(itemRef)} style={panelStyle}>
        <div className="c-accordion__content c-description-list-accordion__content">{children}</div>
      </div>
    </>
  )
}

DescriptionListAccordion.Item = AccordionItem
DescriptionListAccordion.Item.displayName = 'DescriptionListAccordion.Item'

DescriptionListAccordion.Item.propTypes = {
  defaultOpen: PropTypes.bool,
  title: PropTypes.node, // Bronson template: 'title'.clear
  subtitle: PropTypes.node, // Bronson template: 'subtitle'.
  titleSplit: PropTypes.node, // Bronson template: 'title-split'
  icon: PropTypes.string, // Bronson template: 'icon'.
  tooltip: PropTypes.node, // Bronson template: 'tooltip'.
  nonCollapsable: PropTypes.bool,
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'content', use DescriptionListAccordion.DL
}

/*
 * Bronson DescriptionItem component.
 */
export function DescriptionItem({ label, labelNote, children }) {
  return (
    <dl className="c-description-list-accordion__item">
      <dt className="c-description-list-accordion__item__label">
        <div className="c-description-list-accordion__item__label-text">{label}</div>
        {labelNote && <div className="c-description-list-accordion__item__label-note">{labelNote}</div>}
      </dt>
      <dd className="c-description-list-accordion__item__value">{children}</dd>
    </dl>
  )
}

DescriptionListAccordion.DL = DescriptionItem
DescriptionListAccordion.DL.displayName = 'DescriptionListAccordion.DL'

DescriptionListAccordion.DL.propTypes = {
  label: PropTypes.node,
  labelNote: PropTypes.node,
  children: PropTypes.node,
}
