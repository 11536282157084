import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormContext } from './FormContext'

/*
 * Bronson Form component.
 */
export function Form({ floatingLabel, className, children, ...otherProps /* in <form> tag */ }) {
  const contextValue = {
    floatingLabel,
  }

  const formClassNameList = classNames(
    {
      'js-floating-label ': floatingLabel,
      'is-js-on ': floatingLabel,
    },
    className
  ).trim()

  return (
    <form className={formClassNameList} {...otherProps}>
      <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
    </form>
  )
}

Form.propTypes = {
  floatingLabels: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}
