import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * @callback onClickCB
 * @param {Object} event - event object
 * @return {Void}
 */

/**
 * Bronson Logo component.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-logo.html
 *
 * The props can be mixed and matched if need be.
 *
 * @param {string} [className] - Additional class names on anchor element <a>.
 * @param {string} [src] - Source of the logo image, when not set background image is used
 * @param {string} [srcSet] - Set of sources of the logo image
 * @param {string} [breakpoint] - Breakpoint use to switch logo images
 * @param {string} [title] -Link title
 * @param {string} [alt] - Alternate text
 * @param {string} [ariaLabel] - Aria label text
 * @param {('bentley' | 'cupra' | 'lamborghini' | 'porsche' | 'commercial-vehicle-de' | 'commercial-vehicle-en' | 'commercial-vehicle-nl' | 'commercial-vehicle-fr' | 'commercial-vehicle-es')} [specificLogo] - Convenience to set specific brand logo as background image
 * @param {onClickCB} [onClick] - Callback function on click
 * @param {string} [testId] - data-testid attribute set on anchor element <a>
 * @param {Object} otherProps - Other props to be passed to anchor element <a>
 *
 * @return {JSX.Element} - The Logo element.
 */
export function Logo({
  className,
  src,
  srcSet,
  breakpoint,
  title,
  alt,
  ariaLabel,
  specificLogo,
  onClick,
  testId,
  ...otherProps /* in <a> tag */
}) {
  const aClassNameList = classNames(
    'c-logo ',
    {
      'c-logo--has-image ': src,
    },
    `c-logo--${specificLogo}`,
    className
  ).trim()

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <a
      className={aClassNameList}
      title={title}
      data-testid={testId}
      aria-label={ariaLabel || alt || 'Logo'}
      onClick={onClick}
      {...otherProps}
    >
      {src && (
        <picture>
          {breakpoint && srcSet && <source srcSet={srcSet} media={`(max-width: ${breakpoint})`} />}
          <img src={src} alt={alt} className="c-logo__image" />
        </picture>
      )}
    </a>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  breakpoint: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
  specificLogo: PropTypes.oneOf([
    'bentley',
    'cupra',
    'lamborghini',
    'porsche',
    'commercial-vehicle-de',
    'commercial-vehicle-en',
    'commercial-vehicle-nl',
    'commercial-vehicle-fr',
    'commercial-vehicle-es',
  ]),
  testId: PropTypes.string,
  onClick: PropTypes.func,
}
