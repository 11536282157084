import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'

/**
 * Bronson DataOverview component.
 *
 * Generated React component. Do not modify.
 */
export function DataOverview({
  className,
  icon,
  title,
  children,
  testId,
  buttonLabel,
  buttonProps,
  headerLanguage = 'de',
  ...otherProps /* in <article> tag */
}) {
  const articleClassNameList = classNames('c-data-overview ', className).trim()

  // generated
  function renderIfDataOverviewIcon() {
    if (icon) {
      return (
        <div className="c-data-overview__icon">
          <i className={`c-icon c-icon--[${icon}]`} aria-hidden="true" role="img" />
        </div>
      )
    }
    return null
  }

  function renderIfDataOverviewButton() {
    if (buttonLabel) {
      return (
        <Button
          element="a"
          link
          small
          simple
          className="c-data-overview__button"
          icon="true" // as currently in Bronson template for activating the css icon
          iconViaCss
          iconReversed
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )
    }
    return null
  }

  // generated main result
  return (
    <article {...otherProps} data-testid={testId} className={articleClassNameList}>
      <header className="c-data-overview__header" lang={headerLanguage}>
        {renderIfDataOverviewIcon()}
        <h4 className="c-data-overview__title">{title}</h4>
        {renderIfDataOverviewButton()}
      </header>
      <div className="c-data-overview__body">{children}</div>
    </article>
  )
}

DataOverview.propTypes = {
  /**
   * Bronson template: 'modifiers'.
   */
  className: PropTypes.string,
  /**
   * Bronson template: 'data-overview-icon'.
   */
  icon: PropTypes.string,
  /**
   * Bronson template: 'data-overview-title'.
   */
  title: PropTypes.string,
  /**
   * Bronson template: 'content'.
   */
  children: PropTypes.node,
  buttonLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: PropTypes.object,
  headerLanguage: PropTypes.string, // Bronson template: data-overview-header-language
  /**
   * Added for data-testid attribute.
   */
  testId: PropTypes.string,
}
