/* eslint-disable react-hooks/exhaustive-deps */
// TODO: check deps
import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const CustomSelectContext = React.createContext({})

const hasOptionProps = (child) => child.props?.optionKey && child?.props.optionValue

const getOptions = (children) => {
  return children.map((child) => {
    if (process.env.NODE_ENV !== 'production' && !hasOptionProps(child)) {
      console.error('Check that all "CustomSelect.Item"s contain properties "optionKey" and "optionValue".')
    }
    return {
      key: child.props.optionKey,
      value: child.props.optionValue,
    }
  })
}

const findOption = (options, key) => {
  const option = options?.find((o) => o.key === key)
  return option?.value
}

const mouseEvent = 'click'

/*
 * Bronson CustomSelect component.
 *
 */
export function CustomSelect({
  error,
  handle,
  label,
  defaultOptionKey = '',
  defaultOpen = false,
  inputClassName,
  freeText,
  hint,
  inputProps,
  icon,
  children,
  onChange,
  testId,
  ...otherProps /* in <div> tag */
}) {
  const ncSelectRef = useRef(null)

  const [open, setOpen] = useState(defaultOpen)
  const [option, setOption] = useState(defaultOptionKey)

  const options = useMemo(() => (defaultOptionKey ? getOptions(children) : null), [children])

  const [value, setValue] = useState(findOption(options, defaultOptionKey) || '')

  useEffect(() => {
    const handleClick = (event) => {
      if (ncSelectRef.current?.contains(event.target)) {
        return
      }
      setOpen(false)
    }
    document.addEventListener(mouseEvent, handleClick)
    return () => {
      document.removeEventListener(mouseEvent, handleClick)
    }
  }, [])

  function updateValue(newValue, newOptionKey) {
    setOpen(false)
    setValue(findOption(options, newOptionKey) || newValue)
    newOptionKey && setOption(newOptionKey)
    onChange(newOptionKey || newValue)
  }

  const contextValue = {
    // current option
    optionKey: option,
    // current value
    value,
    // the value of "open" state
    isOpen: open,
    // callback function to close the dropdown, gets the render children of the selected item.
    select: updateValue,
  }

  /*
   * Change open state
   */
  function toggleOpen() {
    setOpen(!open)
  }

  // generated
  const divClassNameList = classNames({
    'c-custom-select ': true,
    'is-open ': open,
  }).trim()

  // generated
  const inputClassNameList = classNames(
    {
      'c-input__input ': true,
      'is-error ': error,
    },
    inputClassName
  ).trim()

  // generated
  const iClassNameList = classNames({
    'c-icon ': true,
    [`c-icon--[${icon}] `]: true,
  }).trim()

  // generated main result
  return (
    <>
      {label && <label htmlFor={handle}>{label}</label>}
      <div className={divClassNameList} ref={ncSelectRef}>
        <div className="c-input" {...otherProps}>
          {freeText ? (
            <>
              <input
                data-testid={`${testId}-free-text`}
                className={inputClassNameList}
                id={handle}
                type="text"
                placeholder={hint}
                onChange={(e) => updateValue(e.target.value)}
                value={value}
                {...inputProps}
              />
            </>
          ) : (
            <>
              <span className={inputClassNameList} {...inputProps} onClick={() => toggleOpen()}>
                {value}
              </span>
            </>
          )}
          <button
            data-testid={`${testId}-toggle-open`}
            className="c-input__addon c-input__addon--no-background"
            onClick={() => toggleOpen()}
            type="button"
          >
            <i className={iClassNameList} />
          </button>
        </div>

        <ul className="c-custom-select__dropdown">
          <CustomSelectContext.Provider value={contextValue}>
            {children /* Use 'CustomSelect.Item' component. */}
          </CustomSelectContext.Provider>
        </ul>
      </div>
    </>
  )
}

CustomSelect.propTypes = {
  handle: PropTypes.string, // Bronson template: 'handle'.
  label: PropTypes.node, // Bronson template: 'custom-select-label'.
  defaultOpen: PropTypes.bool, // Bronson template: 'custom-select-is-open'.
  inputClassName: PropTypes.string, // Bronson template: 'input-modifier'.
  freeText: PropTypes.bool, // Bronson template: 'custom-select-has-free-text'.
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object, // Bronson template: 'input-attribute'.
  icon: PropTypes.string, // Bronson template: 'custom-select-icon'.
  children: PropTypes.arrayOf(PropTypes.element), // Bronson template: 'custom-select-item'. Use 'CustomSelect.Item' component.
  hint: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  error: PropTypes.bool, // Convenience prop,
  testId: PropTypes.string, // Added for data-testid attribute. added as '{testId}-toggle-open' to toggle open/close and '{testId}-free-text" to the input
}

/*
 * Bronson CustomSelectItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function CustomSelectItem({
  state,
  optionKey,
  optionValue,
  children,
  testId,
  onClick,
  ...otherProps /* in <li> tag */
}) {
  const context = React.useContext(CustomSelectContext)

  const [active, setActive] = useState()

  useEffect(() => {
    if (context.optionKey) {
      setActive(!!(context.optionKey === optionKey))
    } else {
      setActive(!!(context.value === children))
    }
    // set active dependend on our content being the currently selected/typed content of the main component.
  }, [context.value, context.optionKey, optionKey, children])

  function onClickHandler(e) {
    context.select(children, optionKey)
    if (onClick) {
      onClick(e)
    }
  }

  // generated
  const liClassNameList = classNames({
    'c-custom-select__item ': true,
    'is-active ': active,
    [`${state} `]: state,
  }).trim()

  // generated main result
  return (
    <li {...otherProps} data-testid={testId} className={liClassNameList} onClick={onClickHandler}>
      {children}
    </li>
  )
}

CustomSelectItem.propTypes = {
  state: PropTypes.string, // Bronson template: 'state'.
  children: PropTypes.node, // Bronson template: 'title'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onClick: PropTypes.func,
  optionKey: PropTypes.string,
  optionValue: PropTypes.string,
}

CustomSelectItem.displayName = 'CustomSelect.Item'
CustomSelect.Item = CustomSelectItem
