import React from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson Blockquote component.
 *
 * Generated React component. Do not modify.
 */
export function Blockquote({ children, footerSource, footerCite, testId, ...otherProps /* in <blockquote> tag */ }) {
  // generated main result
  return (
    <blockquote {...otherProps} data-testid={testId} className="c-blockquote">
      <p className="c-blockquote__text">{children}</p>
      <footer className="c-blockquote__footer">
        <span className="c-blockquote__source">{footerSource}</span>
        <cite className="c-blockquote__cite">{footerCite}</cite>
      </footer>
    </blockquote>
  )
}

Blockquote.propTypes = {
  children: PropTypes.node, // Bronson template: 'text'.
  footerSource: PropTypes.node, // Bronson template: 'footer-source'.
  footerCite: PropTypes.node, // Bronson template: 'footer-cite'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
