import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'
import { Heading } from '../Heading/Heading'
import { Paragraph } from '../Paragraph/Paragraph'

/**
 * Bronson Modal component.
 *
 * @param {string} [title] - The modal title heading text.
 * @param {string} [children] - The contents of the modal paragraph.
 * @param {boolean} [left] - Left align the modal content.
 * @param {boolean} [center] - Center align the modal content.
 * @param {"info"|"success"|"warning"|"error"} [status] - An optional modal status.
 * @param {boolean} [shown] - Controls if the modal is visible per default.
 * @param {string} [hideCloseButton] - Show/hide the close button. @TODO: Prop not in Bronson spec.
 * @param {boolean} [closeAny] - Controls if clicking any element inside the modal closes it.
 * @param {function} [onClose] - Callback when the modal is being closed.
 * @param {function} [onCancel] - Callback when the cancel button is clicked.
 * @param {function} [onConfirm] - Callback when the confirm button is clicked.
 * @param {function} [onClickOutside] - Callback when the an outside click occurred.
 * @param {string} [buttonCloseLabel] - Text for the close button `[aria-label]` attribute.
 * @param {string} [buttonCancelLabel] - Text for the cancel button `[aria-label]` attribute.
 * @param {string} [buttonCancelText] - Text for the cancel button content.
 * @param {boolean} [confirmationDisabled] - Disable the confirmation button.
 * @param {string} [buttonConfirmLabel] - Text for the confirmation button `[aria-label]` attribute.
 * @param {string} [buttonConfirmText] - Text for the confirmation button content.
 * @param {('button'|'submit'|'reset')} [buttonConfirmType] - Set the default behavior of the confirmation button.
 *                                                            Implicitly set to 'submit'.
 * @param {string} [className] - Additional modifiers.
 * @param {string} [id] - A unique identifier for the modal.
 * @param {boolean} [asPortal] - Controls if the modal is placed under a different container {@link portalContainer} within the DOM.
 * @param {HTMLElement} [portalContainer] - Node element to place the modal if {@link asPortal} is set.
 *                                          If the portalContainer is empty, 'document.body' will be used.
 *  @param {string} [testId] - Value for the `[data-testid]` attribute.
 * @return {(ReactPortal|JSX.Element)} - The Modal component or a {@link ReactPortal}.
 * @constructor
 */
export function Modal({
  title,
  children,
  left,
  center,
  status,
  shown,
  hideCloseButton,
  closeAny,
  onClose,
  onCancel,
  onConfirm,
  onClickOutside,
  buttonCloseLabel,
  buttonCancelLabel,
  buttonCancelText,
  confirmationDisabled,
  buttonConfirmLabel,
  buttonConfirmText,
  buttonConfirmType,
  className,
  testId,
  id,
  asPortal,
  portalContainer,
}) {
  const modalWrapper = useRef(null)

  const componentModalClass = classNames(
    {
      'c-modal  ': true,
      'c-modal--left  ': left,
      'c-modal--center  ': center,
      [`c-modal--${status}  `]: status,
    },
    className
  ).trim()

  function renderChildren() {
    if (typeof children === 'string') {
      return <Paragraph>{children}</Paragraph>
    }
    return children
  }

  function onClick(e) {
    if (modalWrapper.current !== e.currentTarget || closeAny) {
      onClose()
    }
    e.stopPropagation()
  }

  function onKeyDown(e) {
    if (e.keyCode === 27) {
      onClose()
    }
  }

  if (shown) {
    document.addEventListener('keydown', onKeyDown, false)
  } else {
    document.removeEventListener('keydown', onKeyDown, false)
  }

  const modal = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid={testId}
      className={componentModalClass}
      aria-hidden={!shown}
      id={id}
      onClick={closeAny ? onClick : onClickOutside}
    >
      <div className="c-modal__overlay  " tabIndex="-1" />
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className="c-modal__wrapper"
        role="dialog"
        aria-labelledby="modal-title-label"
        onClick={onClick}
        ref={modalWrapper}
      >
        <header className="c-modal__header">
          <div className="c-modal__title__wrapper">
            {status && <i className="c-modal__status-icon" />}
            <Heading level="2" className="c-modal__title" id="modal-title-label">
              {title}
            </Heading>
          </div>
          {!hideCloseButton && (
            <button
              type="button"
              className="c-modal__close-btn  js-modal-close"
              onClick={onClick}
              aria-label={buttonCloseLabel}
            />
          )}
        </header>

        <div className="c-modal__content" role="document">
          {renderChildren()}
        </div>
        <div className="c-modal__footer">
          <div className="c-modal__actions">
            {buttonCancelText && (
              <div className="c-modal__actions__item">
                <Button type="button" onClick={onCancel} secondary aria-label={buttonCancelLabel}>
                  {buttonCancelText}
                </Button>
              </div>
            )}
            {buttonConfirmText && (
              <div className="c-modal__actions__item">
                <Button
                  type={buttonConfirmType}
                  onClick={closeAny ? onClick : onConfirm}
                  aria-label={buttonConfirmLabel}
                  disabled={confirmationDisabled}
                >
                  {buttonConfirmText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  return asPortal ? ReactDOM.createPortal(modal, portalContainer || document.body) : modal
}

Modal.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  /**
   * For some reason, using just `PropTypes.node` makes storybook crash here.
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  shown: PropTypes.bool,
  closeAny: PropTypes.bool,
  buttonConfirmText: PropTypes.string,
  buttonConfirmLabel: PropTypes.string,
  buttonConfirmType: PropTypes.string,
  buttonCloseLabel: PropTypes.string,
  buttonCancelLabel: PropTypes.string,
  buttonCancelText: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClickOutside: PropTypes.func,
  left: PropTypes.bool,
  center: PropTypes.bool,
  status: PropTypes.string,
  confirmationDisabled: PropTypes.bool,
  portalContainer: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(Element)]),
  asPortal: PropTypes.bool,
}
