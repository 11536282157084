import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

/**
 * Indicator Dot
 * @desc Visual indicator to communicate a status.
 * It includes default statuses like `default`, `info`, `success`, `warning`, `error` and a `unread` status, e.g. for messages.
 *
 * @param {string} [ariaLabelStatus] - An optional label to use to convey additional meaning to the status dot. Useful for error or warnings.
 * @param {string} [className] - Modifier class(es) to apply to the component.
 * @param {string} [label] - An optional label to display beside the indicator dot.
 * @param {('info'|'success'|'warning'|'error'|'unread')} [status] - A status identifier to signalize the current state.
 * @param {Tooltip} [tooltip=null] - Replaces the dot span with a {@link Tooltip}.
 * @param {string} testId - Set the value of `[data-testid]` attribute on main component element.
 * @return {JSX.Element} - Returns the IndicatorDot component.
 * @constructor
 */
export function IndicatorDot({ ariaLabelStatus, className, label, status, tooltip = null, testId }) {
  const indicatorDotClassNameList = classNames(
    {
      'c-indicator-dot': true,
      [`c-indicator-dot--${status}`]: status,
    },
    className
  ).trim()

  return (
    <span className={indicatorDotClassNameList} data-testid={testId}>
      {/* If a {@link tooltip} was passed we use this or fallback to our non-interactive <span> */}
      {tooltip ?? <span className="c-indicator-dot__dot" aria-label={ariaLabelStatus} />}
      {/* Only render a label if {@link label} was passed. */}
      {label && <span className="c-indicator-dot__label">{label}</span>}
    </span>
  )
}

IndicatorDot.propTypes = {
  className: PropTypes.string, // Bronson template: 'modifiers'.
  label: PropTypes.string, // Bronson template: 'label'.
  status: PropTypes.oneOf(['info', 'success', 'warning', 'error', 'unread']), // Bronson template: 'status'.
  tooltip: PropTypes.node, // Bronson template: 'tooltip'.
  testId: PropTypes.string,
  /* Convenience props */
  ariaLabelStatus: PropTypes.string, // Convenience prop to pass `[aria-label]` for status dot.
}
