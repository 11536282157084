import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'

/*
 * Bronson FormSection component.
 *
 * Generated React component. Do not modify.
 */
export function FormSection({
  // disableJsFx,
  className,
  title,
  linkLabel,
  onLinkClick,
  subtitle,
  testId,
  success,
  children,
  defaultOpen = false,
  disabled,
  headerContent,
  noTruncate,
  onClick,
  onChange,
  ...otherProps /* in <div> tag */
}) {
  const [open, setOpen] = useState(defaultOpen)

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  function toggleOpen() {
    setOpen(!open)

    if (onChange) {
      onChange(!open)
    }
  }

  // generated
  const divClassNameList = classNames(
    {
      'c-form-section ': true,
      'js-form-section ': true,
      'js-is-fx ': false, // !disableJsFx,
      'js-is-ready': true,
      'is-open': open,
      // main class modifier convenience prop extension
      'c-form-section--success ': success, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  const sectionHeaderClassNameList = classNames(
    {
      'c-form-section__header ': true,
      'js-form-section__header ': true,
      'is-active': open,
      'c-form-section__header--no-truncate': noTruncate,
    },
    className
  ).trim()

  const sectionPanelClassNameList = classNames(
    {
      'c-form-section__panel ': true,
      'js-form-section__panel ': true,
      'is-open': open,
    },
    className
  ).trim()

  /**
   * Note: Custom header content is only agreed on by Bronson for specific cases like Customer Portal.
   */
  function renderIfHeaderContent() {
    return headerContent || null
  }

  function renderIfNotHeaderContent() {
    return headerContent ? null : (
      <>
        <span className="c-form-section__title js-form-section__trigger" aria-expanded={open}>
          <span className="c-form-section__title-text" role="heading">
            {title}
          </span>
          {renderIfFormSectionShowLink()}
        </span>
        {renderIfFormSectionSubtitle()}
      </>
    )
  }

  // generated
  function renderIfFormSectionShowLink() {
    if (linkLabel) {
      return (
        <Button element="span" className="c-form-section__title-link" link small onClick={onLinkClick}>
          {linkLabel}
        </Button>
      )
    }
    return null
  }

  // generated
  function renderIfFormSectionSubtitle() {
    if (subtitle) {
      return <span className="c-form-section__subtitle">{subtitle}</span>
    }
    return null
  }

  const panelClosedStyle = {
    height: '0px',
    overflowY: 'hidden', // overflow-y in html
    visibility: 'hidden',
  }

  // generated main result
  return (
    <section {...otherProps} data-testid={testId} className={divClassNameList} aria-disabled={disabled}>
      <button
        className={sectionHeaderClassNameList}
        aria-expanded={open}
        onClick={() => !disabled && [toggleOpen(), onClick && onClick()]}
        type="button"
      >
        <span className="c-form-section__header-content">
          {renderIfNotHeaderContent()}
          {renderIfHeaderContent()}
        </span>
        <span className="c-form-section__header-icon" />
      </button>
      <div className={sectionPanelClassNameList} style={open ? {} : panelClosedStyle} aria-hidden={!open}>
        <div className="c-form-section__content">{children}</div>
      </div>
    </section>
  )
}

FormSection.propTypes = {
  // disableJsFx: PropTypes.bool, // Bronson template: 'disable-js-fx'.
  className: PropTypes.string, // Bronson template: 'form-section-modifier'.
  title: PropTypes.node, // Bronson template: 'form-section-title'.
  linkLabel: PropTypes.string, // Bronson template: 'form-section-link-label'.
  onLinkClick: PropTypes.func,
  subtitle: PropTypes.string, // Bronson template: 'form-section-subtitle'.
  children: PropTypes.node,
  defaultOpen: PropTypes.bool, // Bronson template: 'form-section-expanded'.
  testId: PropTypes.string, // Added for data-testid attribute.
  headerContent: PropTypes.node,
  noTruncate: PropTypes.bool, // Bronson template: 'no-truncate'.
  onClick: PropTypes.func,
  /* Convenience props */
  success: PropTypes.bool, // Convenience prop for c-form-section--success (Bronson template: 'form-section-modifier').
}
