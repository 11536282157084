/* eslint-disable react-hooks/exhaustive-deps */
// TODO: check deps
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormFieldContext } from '../FormField/FormFieldContext'

/*
 * Bronson Textarea component.
 *
 * Generated React component. Do not modify.
 */
export function Textarea({
  modifierStates,
  id,
  error,
  maxCounter,
  enableMaxLength = true,
  charCounter,
  testId,
  onChange,
  children,
  errorMessage,
  ...otherProps /* in <textarea> tag */
}) {
  const [currentCount, setCurrentCount] = useState(0)

  const context = useContext(FormFieldContext)

  useEffect(() => {
    if (context && context.setElementActive) {
      context.setElementActive(!!children)
    }
  }, [children])

  // generated
  const textareaClassNameList = classNames({
    'c-input__input ': true,
    'is-error': error,
    [`${modifierStates} `]: modifierStates,
  }).trim()

  const counterClassNameList = classNames({
    'c-input__counter ': true,
    'c-input__counter--alert': currentCount >= maxCounter,
  }).trim()

  // generated main result
  return (
    <div className="c-input c-input--textarea">
      {charCounter && maxCounter && (
        <div className={counterClassNameList} id={`${id}-counter`} aria-live="polite">
          <span className="u-visually-hidden">Text length:</span>
          {currentCount}
          <span aria-hidden="true">/</span>
          <span className="u-visually-hidden">of</span>
          {maxCounter}
          <span className="u-visually-hidden">allowed characters</span>
        </div>
      )}
      <textarea
        data-testid={testId}
        className={textareaClassNameList}
        id={id}
        cols="30"
        rows="10"
        aria-labelledby={error && `error-${id}`}
        onChange={(event) => {
          charCounter && setCurrentCount(event.target.value.length)
          return [
            onChange && onChange(event),
            context.setElementActive && context.setElementActive(!!event.target.value),
          ]
        }}
        aria-describedby={charCounter && `${id}-counter`}
        {...(enableMaxLength && maxCounter ? { maxLength: maxCounter } : {})}
        {...otherProps}
        defaultValue={children}
      />
      {errorMessage}
    </div>
  )
}

Textarea.propTypes = {
  modifierStates: PropTypes.string, // Bronson template: 'modifier-states'.
  id: PropTypes.string, // Bronson template: 'id'.
  // Bronson template: 'attr'. Replaced by {...otherProps}.
  error: PropTypes.bool, // Bronson template: 'error'.
  maxCounter: PropTypes.number, // max counter of the TextArea
  enableMaxLength: PropTypes.bool, // enable max length limitation, see HTML maxLength attribute (default: true)
  charCounter: PropTypes.bool, // adds a character counter to the TextArea
  testId: PropTypes.string, // Added for data-testid attribute.
  onChange: PropTypes.func,
  errorMessage: PropTypes.element, //  Bronson template: 'error-message', use ErrorMessage component
}
