import { useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * Asynchronously observe resizes of a target reference element.
 * Please note that IE11 and other legacy browser do not implement the ResizeObserver API
 * and therefore need to polyfill the missing functionality.
 * @param {RefObject<HTMLElement>} ref – The reference to observe resizes on.
 * @param {function} onResizeHandler - The callback function to invoke upon observed resizes.
 * @example
 *    const myTarget = useRef(null)
 *
 *    export function Example({}) {
 *      const myTarget = useRef(null);
 *      useResizeObserver({
 *        ref: myTarget,
 *        onResizeHandler: (entry) => {
 *          // Do your stuff on every observed resize here.
 *        },
 *      });
 *      return (
 *        <div ref={myTarget}>👋</div>
 *      );
 *    };
 */
function useResizeObserver({ ref, onResizeHandler } = {}) {
  useEffect(() => {
    const { current } = ref
    /**
     * If we have an {@link HTMLElement} to observe.
     */
    if (current) {
      const resizeObserver = new ResizeObserver((entries) => {
        onResizeHandler(entries)
      })
      resizeObserver.observe(current)
      return () => resizeObserver.unobserve(current)
    }
    return false
  }, [onResizeHandler, ref])
}

useResizeObserver.propTypes = {
  // Use a function or the instance of a DOM native element.
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  onResizeHandler: PropTypes.func,
}

export default useResizeObserver
