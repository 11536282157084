import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useLazyRender } from '../../features/useLazyRender'

const STATE_ID = 'expandable-content'

/*
 * Bronson ExpandableContent component.
 *
 * Generated React component. Do not modify.
 */
export function ExpandableContent({
  className,
  // disableJsFx,
  pageWrapClassName,
  triggerLabel,
  children,
  triggerLabelClose,
  testId,
  noBackground,
  defaultOpen = false,
  noPageWrap = false,
  lazyRender = false,
  ...otherProps /* in <div> tag */
}) {
  const [open, setOpen] = useState(defaultOpen)
  const { isRendered, setRendered } = useLazyRender(lazyRender)

  useEffect(() => {
    updateOpen(defaultOpen)
  }, [defaultOpen])

  const toggleOpen = () => {
    updateOpen(!open)
  }

  const updateOpen = (newOpen) => {
    setOpen(newOpen)
    if (newOpen) {
      setRendered(STATE_ID)
    }
  }

  // generated
  const divClassNameList = classNames(
    {
      'c-expandable-content ': true,
      'js-is-fx ': false, // !disableJsFx, // currently generally disabled
      // main class modifier convenience prop extension
      'c-expandable-content--no-background ': noBackground, // Convenience prop from Bronson variants.
      'is-closed-within': !open,
      'js-is-ready': true,
    },
    className
  ).trim()

  // generated
  const divClassNameList2 = noPageWrap ? '' : classNames('o-page-wrap', pageWrapClassName).trim()

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={divClassNameList}>
      <div className={divClassNameList2}>
        <button
          className="c-expandable-content__trigger c-expandable-content__trigger-open"
          aria-expanded={open}
          onClick={toggleOpen}
          type="button"
        >
          <span className="c-expandable-content__trigger-text c-expandable-content__trigger-text-open">
            {triggerLabel}
          </span>
          <i className="c-expandable-content__icon" />
        </button>
        {isRendered(STATE_ID) && (
          <div className="c-expandable-content__collapse js-is-ready" aria-hidden={!open}>
            <section className="c-expandable-content__content">{children}</section>
            <button
              className="c-expandable-content__trigger c-expandable-content__trigger-close"
              aria-expanded={open}
              onClick={toggleOpen}
              type="button"
            >
              <span className="c-expandable-content__trigger-text c-expandable-content__trigger-text-close">
                {triggerLabelClose}
              </span>
              <i className="c-expandable-content__icon" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

ExpandableContent.propTypes = {
  defaultOpen: PropTypes.bool, // set the default open state
  className: PropTypes.string, // Bronson template: 'expandable-content-modifier'.
  // disableJsFx: PropTypes.bool, // Bronson template: 'disable-js-fx'.
  pageWrapClassName: PropTypes.string, // Bronson template: 'expandable-content-page-wrap-modifier'. E.g. o-page-wrap--small
  triggerLabel: PropTypes.string, // Bronson template: 'expandable-content-trigger-label'.
  children: PropTypes.node, // Bronson template: 'expandable-content-content'.
  triggerLabelClose: PropTypes.string, // Bronson template: 'expandable-content-trigger-label-close'.
  testId: PropTypes.string, // Added for data-testid attribute.
  noPageWrap: PropTypes.bool, // o-page-wrap is not added to the expandable content and thus no container or margins are added
  lazyRender: PropTypes.bool, // enables conditional rendering of hidden elements to improve performance in case of complex children, default is false
  /* Convenience props */
  noBackground: PropTypes.bool, // Convenience prop for c-expandable-content--no-background (Bronson template: 'expandable-content-modifier'). Hint: not considered as best practice, should be used carefully
}
