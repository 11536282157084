import React from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson UploadContainer component.
 *
 * Generated React component. Do not modify.
 */
export function UploadContainer({ upload, testId, children, ...otherProps /* in <ul> tag */ }) {
  // generated main result
  return (
    <div className="c-upload-container">
      <div className="c-upload-container__field">{upload}</div>
      <ul {...otherProps} data-testid={testId} className="c-upload-container__itemlist">
        {children /* Use 'UploadItem' component. */}
      </ul>
    </div>
  )
}

UploadContainer.propTypes = {
  upload: PropTypes.node, // Bronson template: 'bronson-upload'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'upload-items'. Use 'UploadItem' component.
}
