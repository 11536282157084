import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Toggle component.
 *
 * Generated React component. Do not modify.
 */
export function Toggle({
  className,
  id,
  name,
  onOff,
  ab,
  checkedA,
  onChange,
  textA,
  textB,
  textOther,
  inputType,
  children,
  testId,
  ...otherProps /* in <input> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      'c-toggle ': true,
      'c-toggle--on-off ': onOff,
      'c-toggle--a-b': ab,
    },
    className
  ).trim()

  // generated
  function renderIfToggleOnOff() {
    if (onOff) {
      return <span className="c-toggle__text">{textOther}</span>
    }
    return null
  }

  const renderAB = (
    <>
      <input
        type="radio"
        className="c-toggle__input"
        id={`${id}-a`}
        name={`${name || 'toggle'}`}
        checked={checkedA}
        onChange={onChange}
      />
      <label htmlFor={`${id}-a`} className="c-toggle__text">
        {textOther || textA}
      </label>
      <input
        type="radio"
        className="c-toggle__input"
        id={`${id}-b`}
        name={`${name || 'toggle'}`}
        checked={!checkedA}
        onChange={onChange}
      />
      <label htmlFor={`${id}-b`} className="c-toggle__text">
        {children || textB}
      </label>
    </>
  )

  // generated main result
  return (
    <div className={divClassNameList}>
      {ab ? (
        renderAB
      ) : (
        <label htmlFor={id}>
          {renderIfToggleOnOff()}
          <input
            {...otherProps}
            onChange={onChange}
            data-testid={testId}
            className="c-toggle__input"
            type={inputType}
            id={id}
            name={`${name || 'toggle'}`}
          />
          <span className="c-toggle__text">{children}</span>
        </label>
      )}
    </div>
  )
}

Toggle.propTypes = {
  className: PropTypes.string, // Bronson template: 'toggle-modifier'.
  id: PropTypes.string, // Bronson template: 'id'.
  name: PropTypes.string, // Bronson template: 'toggle-name'.
  onOff: PropTypes.bool, // Bronson template: 'toggle-on-off'.
  ab: PropTypes.bool, // Bronson template: 'toggle-a-b'.
  checkedA: PropTypes.bool, // checked state of a-b toggle (if false, 'b' is checked)
  onChange: PropTypes.func,
  textA: PropTypes.string, // for a-b toggle (maps to children)
  textB: PropTypes.string, // for a-b toggle (maps to 'textOther')
  textOther: PropTypes.node, // Bronson template: 'toggle-text-other'.
  inputType: PropTypes.string, // Bronson template: 'toggle-input-type'.
  // Bronson template: 'toggle-attributes'. Replaced by {...otherProps}.
  children: PropTypes.node, // Bronson template: 'toggle-text'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
