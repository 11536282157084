import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson ToastNotifications component.
 *
 * Generated React component. Do not modify.
 */
export function ToastNotifications({ description, testId, children, ...otherProps /* in <aside> tag */ }) {
  // generated main result
  return (
    <aside
      {...otherProps}
      data-testid={testId}
      className="c-toast-notifications js-toast-notifications"
      aria-label={description}
    >
      {children /* Use 'ToastNotification' component. */}
    </aside>
  )
}

ToastNotifications.propTypes = {
  description: PropTypes.string, // Bronson template: 'description'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'toast-notification'. Use 'ToastNotification' component.
}

/*
 * Bronson ToastNotification component (nested).
 *
 * Generated React component. Do not modify.
 */
export function ToastNotification({
  className,
  children,
  closeLabel,
  open = true,
  onClose,
  info,
  success,
  warning,
  error,
  ...otherProps /* in <article> tag */
}) {
  // generated
  const articleClassNameList = classNames(
    {
      'c-toast-notification ': true,
      'c-toast-notification--info': info,
      'c-toast-notification--success': success,
      'c-toast-notification--warning': warning,
      'c-toast-notification--error': error,
      'is-closed': !open,
    },
    className
  ).trim()

  const toastRef = useRef(null)
  /*
  const [initialHeight, setInitialHeight] = useState(null);

  // CSS transitions on auto height to 0 needs JS workaround 
  useLayoutEffect( ()=> {
    if(toastRef && toastRef.current) {
      let h = toastRef.current.clientHeight;
      console.log("open " + !!open)
      if(!!open && !initialHeight) {
        console.log("initial height "+ h)
        console.log("open "+ !!open)
        setInitialHeight(h);
      }
    }
    }, [open, initialHeight]) */

  if (!open) {
    // eslint-disable-next-line no-param-reassign
    otherProps.style = {
      ...otherProps.style,
      overflowY: 'hidden',
      visibility: 'hidden',
    }
  }

  // generated main result
  return (
    <article
      {...otherProps}
      className={articleClassNameList}
      aria-live="polite"
      aria-hidden={!open}
      role="status"
      ref={toastRef}
    >
      <div className="c-toast-notification__content">
        <div className="c-toast-notification__body">
          <p>{children}</p>
        </div>
        <button
          className="c-toast-notification__close"
          type="button"
          title={closeLabel}
          aria-label={closeLabel}
          onClick={onClose}
        />
      </div>
    </article>
  )
}

ToastNotification.propTypes = {
  className: PropTypes.string, // Bronson template: 'modifier'.
  children: PropTypes.node, // Bronson template: 'text'.
  closeLabel: PropTypes.string, // Bronson template: 'close.label'.
  info: PropTypes.bool, // convenience prop for 'c-toast-notification--info' (Bronson template: 'modifier')
  success: PropTypes.bool, // convenience prop for 'c-toast-notification--success' (Bronson template: 'modifier')
  warning: PropTypes.bool, // convenience prop for 'c-toast-notification--warning' (Bronson template: 'modifier')
  error: PropTypes.bool, // convenience prop for 'c-toast-notification--error' (Bronson template: 'modifier')
  open: PropTypes.bool, // open state
  onClose: PropTypes.func, // callback on close (see also convenience hook 'useToastOpen')
}

export const useToastOpen = (defaultOpen = true) => {
  const [open, setOpen] = useState(defaultOpen)

  return {
    props: { open: !!open, onClose: useCallback(() => setOpen(false), []) },
    open,
    setOpen,
  }
}
