import React from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson ErrorMessage component.
 *
 * Generated React component. Do not modify.
 */
export function ErrorMessage({ id, children, testId, ...otherProps /* in <p> tag */ }) {
  // generated main result
  return (
    <p {...otherProps} data-testid={testId} className="c-error-message" id={`error-${id}`}>
      {children}
    </p>
  )
}

ErrorMessage.propTypes = {
  id: PropTypes.string, // Bronson template: 'id'.
  children: PropTypes.node, // Bronson template: 'error'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
