import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson FoldOut component.
 *
 * Generated React component.
 */
export function FoldOut({
  productInfoSummary,
  testId,
  defaultOpen = false,
  children,
  // replaced by productInfoSummary, for <7.5.0
  headerImageSrc,
  headerImageAlt,
  title,
  subtitle,
  info,
  bronson700CompatibilityForArrow,
  // ---
  ...otherProps /* in <div> tag */
}) {
  const [open, setOpen] = useState(defaultOpen)

  useEffect(() => {
    setOpen(defaultOpen)
  }, [defaultOpen])

  function toggleOpen() {
    setOpen(!open)
  }

  // generated
  function renderIfFoldOutHeaderImageSrc() {
    if (headerImageSrc) {
      return (
        <span className="c-fold-out__img">
          <img src={headerImageSrc} alt={headerImageAlt} />
        </span>
      )
    }
    return null
  }

  // generated
  function renderIfFoldOutSubtitle() {
    if (subtitle) {
      return <span className="c-fold-out__subtitle">{subtitle}</span>
    }
    return null
  }

  // generated
  function renderIfProductInfoSummary() {
    if (productInfoSummary) {
      return <>{productInfoSummary}</>
    }
    return null
  }

  function renderIfNotProductInfoSummary700() {
    if (!productInfoSummary) {
      return (
        <>
          {renderIfFoldOutHeaderImageSrc()}
          <span className="c-fold-out__heading">
            <span className="c-fold-out__title">{title}</span>
            {renderIfFoldOutSubtitle()}
          </span>
          <span className="c-fold-out__info">{info}</span>
        </>
      )
    }
    return null
  }

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className="c-fold-out js-fold-out js-is-ready">
      <button
        className="c-fold-out__header js-fold-out__toggle"
        type="button"
        aria-expanded={open}
        onClick={() => toggleOpen()}
      >
        {renderIfProductInfoSummary()}
        {renderIfNotProductInfoSummary700()}

        {bronson700CompatibilityForArrow && <i className="c-fold-out__icon" aria-hidden="true" />}
      </button>
      <div className="c-fold-out__content js-fold-out__content" aria-hidden={!open}>
        {children}
      </div>
      <button
        className="c-fold-out__footer js-fold-out__toggle"
        type="button"
        aria-expanded={open}
        onClick={() => toggleOpen()}
      />
    </div>
  )
}

FoldOut.propTypes = {
  productInfoSummary: PropTypes.node,
  defaultOpen: PropTypes.bool,
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node,
  // replaced by productInfoSummary, for <7.5.0
  headerImageSrc: PropTypes.string, // Bronson template: 'fold-out-header-img'.
  headerImageAlt: PropTypes.string,
  title: PropTypes.node, // Bronson template: 'fold-out-title'.
  subtitle: PropTypes.node, // Bronson template: 'fold-out-subtitle'.
  info: PropTypes.node, // Bronson template: 'fold-out-info'.
  bronson700CompatibilityForArrow: PropTypes.bool,
}
