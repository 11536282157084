/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: @Bronson check usage of anchors!
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../../Button/Button'
import { IndicatorDot } from '../../IndicatorDot/IndicatorDot'
import { Tooltip } from '../../Tooltip/Tooltip'

/**
 * Bronson NotificationCenterItem component.
 * @param {string} [statusReadLabel] - Label to use for adding status meaning to indicator.
 * @param {string} [statusUnreadLabel] - Label to use for adding status meaning to indicator.
 * @param {boolean} [showUnreadButton=true] - Whether to show or hide the unread button.
 * @param {string} [markAsReadLabel] - Label for "Mark as read" button.
 * @param {string} [markAsUnreadLabel] - Label for "Mark as unread" button.
 * @param {boolean} [close=true] - Show/hide the item’s close button.
 * @param {string} [closeLabel] - The close button label.
 * @param {boolean} [timestamp] - Show/hide the {@link timestampText} element.
 * @param {string} [timestampText] - Literal expression for the {@link datetime} string.
 * @param {string} [datetime] - Datetime string for the item.
 * @param {boolean} [unread] - Signalizes if the current item is read or unread. Managed from {@link NotificationCenter.notifications}.
managed from NotificationCenter.notifications}
 * @param {HTMLElement} [title] - An optional title element. Managed from {@link NotificationCenter.notifications}.
 * @param {function} [onClose] - Callback when closing the item.
 * @param {function} [onClick] - Callback for the heading title.
 * @param {function} [onMarkReadUnread] - Callback for the showUnreadButton.
 * @param {string} [children] - Children to render inside the NotificationCenterItem body element.
 * @param {string} [testId] - Value for the `[data-testid]` attribute on NotificationCenterItem element.
 * @param {object} [otherProps] - Other properties to pass to the NotificationCenterItem <article> element.
 * @return {JSX.Element} - The NotificationCenterItem component.
 * @constructor
 */
export function NotificationCenterItem({
  statusReadLabel,
  statusUnreadLabel,
  showUnreadButton = true,
  markAsReadLabel,
  markAsUnreadLabel,
  close = true,
  closeLabel,
  children,
  timestamp,
  timestampText,
  datetime,
  testId,
  unread,
  title,
  onClose,
  onClick,
  onMarkReadUnread,
  ...otherProps
}) {
  // generated
  const articleClassNameList = classNames('c-notification-center__item ', {
    'c-notification-center__item--read ': !unread,
  }).trim()

  // generated
  function renderIfTitle() {
    if (title) {
      return (
        <h3 className="c-notification-center__item__heading">
          <a onClick={onClick} className="c-notification-center__item__heading-link">
            {title}
          </a>
        </h3>
      )
    }
    return null
  }

  const handleOnCLose = (event) => {
    event.nativeEvent.stopImmediatePropagation()
    onClose()
  }

  /**
   * Renders an {@link IndicatorDot} to signalize read/unread state.
   * @return {JSX.Element}
   */
  const renderNotificationCenterIndicator = () => {
    if (unread) {
      const args = {
        className: 'c-notification-center__item__status',
        status: 'unread',
        tooltip: (
          <Tooltip className="c-indicator-dot__dot" content={statusReadLabel}>
            {' '}
          </Tooltip>
        ),
      }
      return <IndicatorDot {...args} />
    }
    return null
  }

  // generated
  function renderIfClose() {
    if (close) {
      return (
        <button className="c-notification-content-center__item__close" onClick={handleOnCLose} type="button">
          <span className="c-notification-content-center__item__close-label">{closeLabel}</span>
        </button>
      )
    }
    return null
  }

  // generated
  function renderIfText() {
    if (children) {
      return <div className="c-notification-center__item__body">{children}</div>
    }
    return null
  }

  // generated
  function renderIfTimestamp() {
    if (timestamp) {
      return (
        <time dateTime={datetime} className="c-notification-center__item__timestamp">
          {timestampText}
        </time>
      )
    }
    return null
  }

  // generated
  function renderIfShowUnreadButton() {
    if (showUnreadButton) {
      return (
        <Button small link className="c-notification-center__item__mark-read-toggle" onClick={onMarkReadUnread}>
          {unread ? markAsReadLabel : markAsUnreadLabel}
        </Button>
      )
    }
    return null
  }

  // generated main result
  return (
    <article {...otherProps} data-testid={testId} className={articleClassNameList}>
      {renderNotificationCenterIndicator()}
      {renderIfTitle()}
      {renderIfClose()}
      {renderIfText()}
      {renderIfTimestamp()}
      {renderIfShowUnreadButton()}
    </article>
  )
}

NotificationCenterItem.propTypes = {
  statusReadLabel: PropTypes.string, // Bronson template: 'status-read-label'.
  statusUnreadLabel: PropTypes.string, // Bronson template: 'status-unread-label'.
  title: PropTypes.node, // Bronson template: 'title', managed from NotificationCenter.notifications
  close: PropTypes.bool, // Bronson template: 'close'.
  onClose: PropTypes.func, // onClose callback (default logic managed)
  closeLabel: PropTypes.string, // Bronson template: 'close-label'.
  children: PropTypes.node, // Bronson template: 'text'.
  timestamp: PropTypes.bool, // Bronson template: 'timestamp'.
  timestampText: PropTypes.string, // Bronson template: 'timestamp.text'.
  datetime: PropTypes.string, // Bronson template: 'datetime'.
  showUnreadButton: PropTypes.bool, // Bronson template: 'show-unread-button'.
  include: PropTypes.string, // Bronson template: 'include'.
  unread: PropTypes.bool, // Bronson template: 'unread', managed from NotificationCenter.notifications
  markAsReadLabel: PropTypes.string, // label for "Mark as read" button
  markAsUnreadLabel: PropTypes.string, // label for "Mark as unread" button
  onMarkReadUnread: PropTypes.func, // callback when "read/unread" button is clicked (default logic managed)
  testId: PropTypes.string, // Added for data-testid attribute.
  onClick: PropTypes.func, // onClick callback (default logic managed)
}
