import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'
import { SectionHeading } from '../SectionHeading/SectionHeading'
import { TagList } from '../TagList/TagList'
import { Tag } from '../Tag/Tag'
import { ProductSearchContext } from '../ProductSearch/ProductSearchContext'
import { productFilterReducerActions } from '../ProductSearch/ProductSearchReducer'

/**
 * The ProductSearchHeader component is used inside the {ProductSearch} to provide a list
 * of activated filters from the {ProductSearchFilter} component.
 * It also contains configurable action buttons for sorting and other functionalities.
 * @param className
 * @param children
 * @param {Array<Button>} [actionsButtons] - A list of action buttons.
 * @param {FormField} [actionsSort]  - Use <FormField> with internal <Select>.
 * @param {boolean} [hasFilterToggle] - Whether to display the filter toggle button.
 * @param {string} filterLabelId - The filter label id.
 * @param {string} filterLabelText - The filter label content.
 * @param {string} filterResetLabel - The filter reset button label.
 * @param {string} filterToggleLabel - The filter toggle button label.
 * @param {string} title
 * @param {string} testId
 * @param {function} [tagFilter] - A function transform the passed filters.
 * @param {function} [tagFormatter] - A function transform a filter value.
 * @param otherProps
 * @return {JSX.Element}
 * @constructor
 */
export function ProductSearchHeader({
  className,
  children,
  actionsButtons,
  actionsSort,
  filterLabelId,
  filterLabelText,
  filterResetLabel,
  filterToggleLabel,
  hasFilterToggle,
  testId,
  title,
  tagFilter = (val) => val,
  tagFormatter = (val) => val,
  ...otherProps /* in <section> tag */
}) {
  const { state, dispatch } = useContext(ProductSearchContext)
  const divClassName = classNames('c-product-search-header', className).trim()

  console.log('state ', state)

  /**
   * The button to show/hide the {@link ProductSearchFilter} inside an {@link InteractionLayer}.
   * @type {JSX.Element}
   */
  const ProductSearchHeaderFilterToggle = (
    <div className="c-product-search-header__filter-toggle">
      <Button
        icon="semantic-filter"
        iconReversed
        type="button"
        onClick={() => dispatch({ type: productFilterReducerActions.showFilters })}
      >
        {filterToggleLabel}
      </Button>
    </div>
  )

  /**
   * Applies the {@link tagFilter}, maps each field and applies the {@link tagFormatter} to the value.
   * @param filters
   * @return {*}
   */
  const toTags = (filters) => {
    const res = filters.filter(tagFilter).map((field) => ({
      id: field.field,
      value: tagFormatter(field),
    }))
    console.log('tags ', res)
    return res
  }

  const tags = toTags(state.filters)

  /**
   * Contains the active filter tags and an optional reset button.
   * @type {JSX.Element}
   */
  const ProductSearchHeaderFilter = (
    <div className="c-product-search-header__filter">
      <span className="c-product-search-header__filter__label" id={filterLabelId}>
        {filterLabelText}
      </span>
      <TagList className="c-product-search-header__filter__tags" aria-describedby={filterLabelId} role="group">
        {/* Filter TagList */}
        {tags.map(({ id, value }) => (
          <Tag
            key={id}
            close
            info
            onCloseClick={() => dispatch({ type: productFilterReducerActions.deleteFilter, payload: id })}
          >
            {value}
          </Tag>
        ))}
      </TagList>
      <div className="c-product-search-header__filter__reset">
        <Button link onClick={() => dispatch({ type: productFilterReducerActions.deleteAllFilter })}>
          {filterResetLabel}
        </Button>
      </div>
    </div>
  )

  const ProductSearchHeaderActions = (
    <div className="c-product-search-header__actions">
      {/* Optional Action Buttons */}
      {actionsButtons?.map((actionButton) => (
        <div className="c-product-search-header__actions__item" key={actionButton.key}>
          {actionButton}
        </div>
      ))}

      {/* Sort Button */}
      <div className="c-product-search-header__actions__item  c-product-search-header__actions__item--wrap">
        <div className="c-product-search-header__sort">
          {/* actionsSort */}
          {actionsSort}
        </div>
      </div>
    </div>
  )

  return (
    <section {...otherProps} className={divClassName}>
      <SectionHeading alternative={false} level="1">
        {title}
      </SectionHeading>

      {/* Optional Filter Toggle */}
      {hasFilterToggle && ProductSearchHeaderFilterToggle}

      <div className="c-product-search-header__body">
        {/* Display filters only when tags are available */}
        {!tags.length && <p>No filters set yet</p>}
        {!!tags.length && ProductSearchHeaderFilter}
        {ProductSearchHeaderActions}
      </div>
    </section>
  )
}

ProductSearchHeader.propTypes = {
  className: PropTypes.string, // Bronson template: 'product-info-summary-modifier'.
  title: PropTypes.string, // Bronson template: 'title'.
  testId: PropTypes.string, // Added for data-testid attribute.
  // Filter
  filterLabelId: PropTypes.string, // Bronson template: 'filter.label.id'.
  filterLabelText: PropTypes.string, // Bronson template: 'filter.label.text'.
  filterResetLabel: PropTypes.string, // Bronson template: 'filter.reset.label'.
  // FilterToggle
  filterToggleLabel: PropTypes.string, // Bronson template: 'filter.toggle.label'.
  // Actions
  actionsButtons: PropTypes.arrayOf(PropTypes.element), // Bronson template: 'actions.buttons'.
  actionsSort: PropTypes.element, // Bronson template: 'actions.sort'.
  // Convenience
  hasFilterToggle: PropTypes.bool, // Bronson template: 'filter.toggle'.
  tagFormatter: PropTypes.func,
  tagFilter: PropTypes.func,
}
