import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 *
 * @param className
 * @constructor
 */
export function ProductSearchFilter({ className, children, ...otherProps /* in <div> tag */ }) {
  const divClassName = classNames('c-product-search-filter', className).trim()

  return (
    <div {...otherProps} className={divClassName}>
      {children}
    </div>
  )
}

ProductSearchFilter.propTypes = {
  className: PropTypes.string, // Bronson template: 'product-info-summary-modifier'.
}
