import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function PageWrap({ size, children, ...otherProps }) {
  const pageWrapClassName = classNames({
    'o-page-wrap ': true,
    [`o-page-wrap--${size}`]: size,
  }).trim()

  return (
    <div {...otherProps} className={pageWrapClassName}>
      {children}
    </div>
  )
}

export const pageWrapSizes = ['medium', 'small', 'xsmall', 'xxsmall']

PageWrap.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(pageWrapSizes),
}
