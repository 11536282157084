/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: @Bronson check usage of anchors!
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const SiteNavContext = React.createContext({})

/**
 * Bronson SiteNav component.
 *
 * @param children
 * @param {string} [className] - Additional class names for the container.
 * @param {string} [testId] - The testId string.
 * @param {string} [openMenuText] - The label for the open menu toggle.
 * @param {string} [closeMenuText] - The label for the close menu toggle.
 * @param {string} [title='Show navigation'] - The SiteNav button title attribute value.
 * @param {object} [otherProps] - Other properties to pass to the SiteNav element.
 * @return {JSX.Element} - The SiteNav component.
 * @constructor
 */
export function SiteNav({
  testId,
  children,
  openMenuText,
  closeMenuText,
  className,
  title = 'Show navigation',
  ...otherProps /* in <div> tag */
}) {
  const [menuOpen, setMenuOpen] = useState(false)

  const [open, setOpen] = useState(null)

  /*
  React.useEffect(() => {
    console.log("set open to %o", open);
  }, [open]);
  */

  const contextValue = {
    // the value of "open" state
    open,
    // callback function to update "open" state to a new ref object
    setOpen: (ref) => (ref === open ? setOpen(null) : setOpen(ref)),
    // callback function to close the menu
    closeMenu: () => setMenuOpen(false),
  }

  const classNameList = classNames(
    {
      'c-site-nav': true,
      'js-site-nav': true,
      // "js-is-ready": true,
      'is-active': menuOpen,
    },
    className
  ).trim()

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={classNameList}>
      <button
        className="c-site-nav__toggle js-site-nav__toggle"
        type="button"
        title={title}
        onClick={() => setMenuOpen(!menuOpen)}
        aria-expanded={menuOpen}
      >
        <i className="c-site-nav__toggle-icon" aria-hidden="true" role="img" />
        <span className="c-site-nav__toggle-open-label">{openMenuText || 'Menu'}</span>
        <span className="c-site-nav__toggle-close-label">{closeMenuText || 'Close Menu'}</span>
      </button>
      <div className="c-site-nav__panel js-site-nav__navs" aria-hidden={!menuOpen}>
        <nav className="c-site-nav__navs" aria-label="primary">
          <SiteNavContext.Provider value={contextValue}>
            {children /* Use 'SiteNav.Area' component. */}
          </SiteNavContext.Provider>
        </nav>
      </div>
    </div>
  )
}

SiteNav.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'site-nav'. Use 'SiteNav.Area' component.
  title: PropTypes.string, // custom title for SiteNav button
  openMenuText: PropTypes.string,
  closeMenuText: PropTypes.string,
  className: PropTypes.string,
}

/**
 * Bronson SiteNavArea component (nested).
 *
 * @param children
 * @param {object} [otherProps] - Other properties to pass to the SiteNav element.
 * @return {JSX.Element} - The SiteNavArea component.
 * @constructor
 */
function SiteNavArea({ children, ...otherProps /* in <ul> tag */ }) {
  // generated main result
  return (
    <ul {...otherProps} className="c-site-nav__nav">
      {children /* Use 'SiteNav.Item' component. */}
    </ul>
  )
}

SiteNavArea.propTypes = {
  children: PropTypes.node, // Bronson template: 'site-nav-item'. Use 'SiteNav.Item' component.
}

SiteNavArea.displayName = 'SiteNav.Area'
SiteNav.Area = SiteNavArea

/**
 * Bronson SiteNavItem component (nested).
 *
 * @param children
 * @param {string} [navClassName] - Additional class names for the container.
 * @param {boolean} [topAtBreakpoint] - Flag to show items on top when the SiteNav is collapsed.
 * @param {string} [className] - Additional class names for the container.
 * @param {boolean} [language] - Flag to activate the language variant.
 * @param {boolean} [reversed] - Flag to reverse the order.
 * @param {string} [label] - The link label text.
 * @param {boolean} [icon] - Flag to display the SiteNavItem icon.
 * @param {string} [dropdownTitle] - Text to display inside the SiteNavItem dropdown element.
 * @param {boolean} [active] - Marks the current SiteNavItem as active.
 * @param {function} [onClick] - Click handler for the SiteNavItem link.
 * @param {object} [otherProps] - Other properties to pass to the SiteNavItem element.
 * @return {JSX.Element} - The SiteNavItem component.
 * @constructor
 */
function SiteNavItem({
  navClassName,
  topAtBreakpoint,
  className,
  language,
  reversed,
  label,
  icon,
  dropdownTitle,
  active,
  onClick,
  children,
  ...otherProps /* in <li> tag */
}) {
  const areaRef = useRef(null)

  // generated
  const liClassNameList = classNames(
    {
      'c-site-nav__item ': true,
      'js-dropdown ': children,
      'c-site-nav__item--top-at-breakpoint ': topAtBreakpoint,
      'c-site-nav__item--language': language,
    },
    navClassName
  ).trim()

  // generated
  const aClassNameList = classNames(
    {
      'c-site-nav__link ': true,
      'js-dropdown__button ': children,
      'is-active': active,
      'is-reversed': reversed,
    },
    className
  ).trim()

  // generated
  function renderIfIcon() {
    if (icon) {
      return <i className="c-site-nav__link-icon" aria-hidden="true" role="img" />
    }
    return null
  }

  // generated
  function renderIfDropdownTitle() {
    if (dropdownTitle) {
      return <li className="c-site-nav-dropdown__title">{dropdownTitle}</li>
    }
    return null
  }

  // generated
  function renderIfDropdownItems(open) {
    if (children) {
      return (
        <nav className="c-site-nav-dropdown js-dropdown__panel" aria-hidden={open !== areaRef}>
          <ul className="c-site-nav-dropdown__list">
            {renderIfDropdownTitle()}
            {children /* Use 'SiteNav.Item.DropdownItem' component. */}
          </ul>
        </nav>
      )
    }
    return null
  }

  // generated main result
  return (
    <SiteNavContext.Consumer>
      {(value) => (
        <li
          ref={areaRef}
          {...otherProps}
          className={liClassNameList}
          tabIndex="0"
          onBlur={() => [
            // only close all items if the open item is this one, as this could have changed.
            value.open === areaRef && value.setOpen(null),
          ]}
          style={{ outline: 'none' }}
        >
          <a
            className={aClassNameList + (value.open === areaRef ? ' is-active' : '')}
            /**
             * Note: OnMouseDown is applied before onBlur, other than onClick.
             *       If the action is performed in onClick, onBlur could already be performed, closing another menu.
             *       In case of e.g. VW6, closing another menu could move content, so the actual click doesn't
             *       happen anymore, which is an undesired behavior.
             *       Test this with VW6 menu and 2 items with subitems, open item 1 and click on item 2
             *       (which will move as item 1 submenu disappears just before)
             */
            onMouseDown={(event) => [
              // when clicked...
              onClick && [
                // if click action available...
                !children && [
                  // if no subitems (this is a direct action)...
                  value.setOpen(null),
                  value.closeMenu(),
                ],
                onClick(event), // perform the click action
              ],
              children && [
                // if subitems...
                value.setOpen(areaRef), // open this item (after onClick)
              ],
            ]}
            // no onClick action, see onMouseDown and comment above.
            aria-expanded={children && value.open === areaRef}
          >
            <span className="c-site-nav__link-label">{label}</span>
            {renderIfIcon()}
          </a>
          {renderIfDropdownItems(value.open)}
        </li>
      )}
    </SiteNavContext.Consumer>
  )
}

SiteNavItem.propTypes = {
  navClassName: PropTypes.string, // Bronson template: 'nav-modifier'.
  topAtBreakpoint: PropTypes.bool, // Bronson template: 'top-at-breakpoint'.
  className: PropTypes.string, // Bronson template: 'modifier'.
  label: PropTypes.string, // Bronson template: 'label'.
  icon: PropTypes.bool, // Bronson template: 'has-icon'.
  dropdownTitle: PropTypes.string, // Bronson template: 'dropdown-title'.
  onClick: PropTypes.func, // Added for onClick attribute.
  children: PropTypes.node, // Bronson template: 'dropdown-items'. Use 'SiteNav.Item.DropdownItem' component.
  active: PropTypes.bool,
  language: PropTypes.bool,
  reversed: PropTypes.bool,
}

/**
 * Bronson DropdownItem component (nested).
 *
 * @param children
 * @param {boolean} [active] - Marks the current item as active.
 * @param {function} [onClick] - The DropdownItem link click handler.
 * @param {object} [otherProps] - Other properties to pass to the DropdownItem element.
 * @return {JSX.Element} - The DropdownItem component.
 * @constructor
 */
function DropdownItem({ children, active, onClick, ...otherProps /* in <li> tag */ }) {
  // generated
  const aClassNameList = classNames({
    'c-site-nav-dropdown__link ': true,
    'is-active ': active,
  }).trim()

  // generated main result
  return (
    <li {...otherProps} className="c-site-nav-dropdown__item">
      <SiteNavContext.Consumer>
        {(value) => (
          <a
            className={aClassNameList}
            onClick={(clickParam) =>
              onClick && [
                // when a click action is available...
                value.setOpen(null), // close the open item that contains this dropdown item
                value.closeMenu(), // close whole menu/SiteNav (if openable menu, e.g. VW6, mobile)
                onClick(clickParam), // perform the click action
              ]
            }
          >
            {/* renderIfActive() */}
            {children}
          </a>
        )}
      </SiteNavContext.Consumer>
    </li>
  )
}

DropdownItem.propTypes = {
  children: PropTypes.node, // Bronson template: 'text'.
  active: PropTypes.bool, // Bronson template: 'is-active'.
  onClick: PropTypes.func, // Added for onClick attribute.
}

DropdownItem.displayName = 'SiteNavItem.DropdownItem'
SiteNavItem.DropdownItem = DropdownItem

SiteNavItem.displayName = 'SiteNav.Item'
SiteNav.Item = SiteNavItem
