import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Checkbox component.
 *
 * Generated React component. Do not modify.
 */
export function Checkbox({ id, error, children, testId, className, ...otherProps /* in <input> tag */ }) {
  // generated
  const inputClassNameList = classNames(
    {
      'c-checkbox__input ': true,
      'is-error ': error,
    },
    className
  ).trim()

  // generated main result
  return (
    <label
      className="c-checkbox"
      htmlFor={id} // applies condition
    >
      <input
        {...otherProps}
        data-testid={testId}
        className={inputClassNameList}
        type="checkbox"
        id={id} // applies condition
      />
      <span className="c-checkbox__label">{children}</span>
    </label>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string, // Bronson template: 'id'.
  error: PropTypes.bool, // Bronson template: 'checkbox-is-error'.
  // Bronson template: 'checkbox-attribute'. Replaced by {...otherProps}.
  children: PropTypes.node, // Bronson template: 'checkbox-label'.
  testId: PropTypes.string, // Added for data-testid attribute.
  className: PropTypes.string, // custom CSS classes on 'c-checkbox'
}
