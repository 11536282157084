import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Badge component.
 *
 * Generated React component. Do not modify.
 */
export function Badge({
  wrapperClass,
  modifier,
  className,
  children,
  icon,
  label,
  testId,
  ...otherProps /* in <span> tag */
}) {
  // generated
  const spanClassNameList = classNames({
    'c-badge__wrapper ': true,
    [`${wrapperClass} `]: wrapperClass,
  }).trim()

  // generated
  const spanClassNameList2 = classNames(
    {
      'c-badge ': true,
      [`c-badge--${modifier} `]: modifier,
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    'c-icon ': true,
    [`c-icon--[${icon}] `]: true,
    'c-badge__icon ': true,
  }).trim()

  // generated
  function renderIfBadgeIcon() {
    if (icon) {
      return <i className={iClassNameList} />
    }
    return null
  }

  // generated
  function renderIfBadgeLabel() {
    if (label) {
      return <span className="c-badge__label">{label}</span>
    }
    return null
  }

  // generated main result
  return (
    <span {...otherProps} data-testid={testId} className={spanClassNameList}>
      <span className={spanClassNameList2}>{children || <>{renderIfBadgeIcon()}</>}</span>
      {renderIfBadgeLabel()}
    </span>
  )
}

Badge.propTypes = {
  wrapperClass: PropTypes.string, // Bronson template: 'badge-wrapper-class'.
  modifier: PropTypes.string, // Bronson template: 'badge-modifier'.
  className: PropTypes.string, // Bronson template: 'badge-class'.
  children: PropTypes.node, // Bronson template: 'badge-content'.
  icon: PropTypes.string, // Bronson template: 'badge-icon'.
  label: PropTypes.string, // Bronson template: 'badge-label'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
