import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoIcon } from '../InfoIcon/InfoIcon'
import { useLazyRender } from '../../features/useLazyRender'

const AccordionContext = React.createContext()

/*
 * Bronson Accordion component.
 */
export function Accordion({
  className,
  multipleOpen,
  testId,
  itemKeyProp,
  children,
  withBackground,
  onChange,
  lazyRender = false,
  // disableJsFx,
  ...otherProps /* in <div> tag */
}) {
  const [open, setOpen] = useState([])

  const { isRendered, setRendered } = useLazyRender(lazyRender)

  const isOpen = (itemKey) => {
    return open.indexOf(itemKey) >= 0
  }

  const setOpenAndNotify = (items) => {
    setOpen(items)
    onChange && onChange(items)
  }

  const updateOpen = (itemKey, shouldOpen) => {
    const index = open.findIndex((entry) => entry === itemKey)
    if (multipleOpen) {
      // is not yet open + shouldOpen -> open
      if (index < 0 && shouldOpen) {
        setOpenAndNotify([...open, itemKey])
      } else if (index >= 0 && !shouldOpen) {
        // is open + !shouldOpen -> close
        open.splice(index, 1)
        setOpenAndNotify([...open])
      }
    } else if (shouldOpen) {
      // is not open -> open
      setOpenAndNotify([itemKey])
    } else {
      // is open -> close
      setOpenAndNotify([])
    }
    if (shouldOpen) {
      setRendered(itemKey)
    }
  }

  /*
   * Change open state for the given item (ref) without the need to know the current state.
   */
  const toggleOpen = (itemKey) => {
    updateOpen(itemKey, !isOpen(itemKey))
  }

  // generated
  const divClassNameList = classNames(
    {
      'c-accordion ': true,
      'js-accordion ': true,
      'js-is-fx ': false, // && !disableJsFx, // currently generally disabled
      // main class modifier convenience prop extension
      'c-accordion--bg-panel ': withBackground, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated main result
  return (
    <div
      {...otherProps}
      data-testid={testId}
      className={divClassNameList}
      data-accordion-multiple={!multipleOpen && 'true'}
    >
      <AccordionContext.Provider value={{ isOpen, toggleOpen, updateOpen, itemKeyProp, isRendered }}>
        {children}
      </AccordionContext.Provider>
    </div>
  )
}

Accordion.propTypes = {
  className: PropTypes.string, // Bronson template: 'accordion-modifier'.
  multipleOpen: PropTypes.bool, // Bronson template: 'group'.
  testId: PropTypes.string, // Added for data-testid attribute.
  // disableJsFx: PropTypes.bool, // Bronson template: 'disable-js-fx'.
  /* Convenience props */
  withBackground: PropTypes.bool, // Convenience prop for c-accordion--bg-panel (Bronson template: 'accordion-modifier').
  itemKeyProp: PropTypes.string, // property to identify the items, by default 'title' (hint: this is used to notify about changes, see onChange)
  onChange: PropTypes.func, // function called when the open state of the Accordion changes, all opened itemKeys are passed to the function
  lazyRender: PropTypes.bool, // enables conditional rendering of hidden elements to improve performance in case of complex children, default is false
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]), // use Accordion.Item
}

/*
 * Bronson AccordionItem component.
 */
export function AccordionItem(props) {
  const { className, contentClassName, title, subtitle, icon, tooltip, testId, children, defaultOpen, ...otherProps } =
    props

  const context = useContext(AccordionContext)
  const itemKey = context.itemKeyProp ? props[context.itemKeyProp] : title

  useEffect(() => {
    context.updateOpen(itemKey, defaultOpen)
  }, [defaultOpen])

  const childDivClassNameList = classNames(
    {
      'c-accordion__header ': true,
      'js-accordion__title ': true,
      'is-active': context.isOpen(itemKey),
    },
    className
  ).trim()

  const contentDivClassNameList = classNames('c-accordion__content', contentClassName).trim()

  /**
   * Needed e.g. because IconList within the panel would result in icons staying on screen even when collapsed.
   * This is handled by Bronson JS otherwise.
   */
  const panelStyle = {
    display: context.isOpen(itemKey) ? 'inherit' : 'none',
  }

  return (
    <>
      <div
        className={childDivClassNameList}
        onClick={() => context.toggleOpen(itemKey)}
        data-testid={testId}
        {...otherProps}
      >
        <span className="c-accordion__title-icon" />
        <h3 className="c-accordion__title">
          <span
            className="c-accordion__title-label js-accordion__title-label"
            aria-expanded={context.isOpen(itemKey)}
            role="button"
            tabIndex="0"
          >
            <span className="c-accordion__title-label-text">
              {title}
              {icon && (
                <InfoIcon className="c-accordion__title-info-icon" icon={icon}>
                  {tooltip}
                </InfoIcon>
              )}
            </span>
          </span>
        </h3>
        {subtitle && <p className="c-accordion__subtitle">{subtitle}</p>}
      </div>
      {context.isRendered(itemKey) && (
        <div
          className="c-accordion__panel js-accordion__panel"
          aria-hidden={!context.isOpen(itemKey)}
          style={panelStyle}
        >
          <div className={contentDivClassNameList}>{children}</div>
        </div>
      )}
    </>
  )
}

Accordion.Item = AccordionItem
Accordion.Item.displayName = 'Accordion.Item'

Accordion.Item.propTypes = {
  defaultOpen: PropTypes.bool, // set if the item should be open by default
  title: PropTypes.node, // Bronson template: 'title'.
  subtitle: PropTypes.node, // Bronson template: 'subtitle'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  tooltip: PropTypes.node, // Bronson template: 'tooltip'.
  testId: PropTypes.string, // Added for data-testid attribute.
  contentClassName: PropTypes.string, // custom CSS classes on 'c-accordion__content'
  className: PropTypes.string, // custom CSS classes on 'c-accordion__header'
  children: PropTypes.node, // Bronson template 'content' (placed inside 'c-accordion__content')
}
