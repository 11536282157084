import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * The SectionHeading component shows headings in a consistent manner.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-section-headings--section-headings.html
 *
 * @param {string} level - Headings level.
 * @param {boolean} [alternative] - Alternative secondary title, use with {@link titleSecondary}.
 * @param {boolean} [center] - Center heading horizontally.
 * @param {string} [className] - Additional classes on main header element.
 * @param {string} children - Headings title.
 * @param {InfoIcon} [infoIcon] - Optional InfoIcon to render.
 * @param {boolean} [primary] - Primary section heading.
 * @param {boolean} [smallSpacing] - Decrease spacing.
 * @param {string} [subtitle] - Optional subtitle `<p>` element.
 * @param {string} [superscript] - Optional inline superscript `<sup>` element.
 * @param {string} [titleSecondary] - Secondary title.
 * @param {string} [testId] - Value for `[data-testid]` attribute on main header element.
 * @param {Object} [otherProps] - Other props to be passed to main element.
 * @return {JSX.Element} - SectionHeading component.
 */
export function SectionHeading({
  alternative,
  center,
  children,
  className,
  infoIcon,
  level,
  primary,
  smallSpacing,
  subtitle,
  superscript,
  titleSecondary,
  testId,
  ...otherProps /* in <header> tag */
}) {
  const headerClassNameList = classNames(
    'c-section-heading',
    {
      'c-section-heading--center ': center,
      'c-section-heading--primary ': primary,
      'c-section-heading--small-spacing ': smallSpacing,
      'c-section-heading--subtitle ': subtitle && !smallSpacing,
      'c-section-heading--alternative ': alternative,
    },
    className
  ).trim()

  // tag containing a variable or condition
  const CustomTagSectionHeadingsLevel = `h${level}`

  function renderIfSectionHeadingsTitle() {
    if (children) {
      return (
        <CustomTagSectionHeadingsLevel className="c-section-heading__title">
          {alternative && titleSecondary ? (
            <>
              <span className="c-section-heading__title-text">{children}</span>
              <span className="c-section-heading__title-text u-heading-alternative">
                {titleSecondary}
                {superscript && <sup>{superscript}</sup>}
              </span>
            </>
          ) : (
            <span className="c-section-heading__title-text">
              {children}
              {superscript && <sup>{superscript}</sup>}
            </span>
          )}
          {infoIcon}
        </CustomTagSectionHeadingsLevel>
      )
    }
    return null
  }

  return (
    <header className={headerClassNameList} data-testid={testId} {...otherProps}>
      {renderIfSectionHeadingsTitle()}
      {subtitle && <p className="c-section-heading__subtitle">{subtitle}</p>}
    </header>
  )
}

SectionHeading.propTypes = {
  alternative: PropTypes.bool, // Convenience prop for c-section-heading--alternative (Bronson template: 'section-headings-modifier').
  center: PropTypes.bool, // Bronson template: 'section-headings-center'.
  children: PropTypes.string, // Bronson template: 'section-headings-title'.
  className: PropTypes.string, // Bronson template: 'section-headings-modifier'.
  infoIcon: PropTypes.node, // Bronson template: 'info-icon'.
  level: PropTypes.string.isRequired, // Bronson template: 'section-headings-headline'.
  primary: PropTypes.bool, // Convenience prop for c-section-heading--primary (Bronson template: 'section-headings-modifier').
  smallSpacing: PropTypes.bool, // Convenience prop for c-section-heading--small-spacing (Bronson template: 'section-headings-modifier').
  subtitle: PropTypes.node, // Bronson template: 'section-headings-text'.
  superscript: PropTypes.string, // Bronson template: 'section-headings-superscript'.
  titleSecondary: PropTypes.string, // Bronson template: 'section-headings-title-secondary'
  testId: PropTypes.string, // Added for data-testid attribute.
}
