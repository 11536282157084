import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NotificationsGroup, Notification } from './Notification'
import { Button } from '../Button/Button'

export const BrowserDeprecationNotification = ({
  content,
  title,
  moreInformationLabel,
  onMoreInformation,
  dismissButtonLabel,
  onDismiss,
  onClose,
  className,
  ...otherProps
}) => {
  const notificationClassName = classNames('c-notification--browser-deprecation', className).trim()

  return (
    <NotificationsGroup>
      <Notification
        title={title}
        status="warning"
        text={content}
        className={notificationClassName}
        onButtonClick={onClose}
        {...otherProps}
      >
        <span className="u-block u-text-right u-mt">
          {moreInformationLabel && (
            <Button link element="a" aria-label={moreInformationLabel} onClick={onMoreInformation}>
              {moreInformationLabel}
            </Button>
          )}
          {dismissButtonLabel && (
            <Button link className="u-ml" aria-label={dismissButtonLabel} onClick={onDismiss}>
              {dismissButtonLabel}
            </Button>
          )}
        </span>
      </Notification>
    </NotificationsGroup>
  )
}

BrowserDeprecationNotification.propTypes = {
  content: PropTypes.node, // Bronson template: 'browser-deprecation.content'
  title: PropTypes.string, // Bronson template: 'notification-headline'.
  moreInformationLabel: PropTypes.string, // link providing more information
  onMoreInformation: PropTypes.func, // callback on 'more information' button click
  dismissButtonLabel: PropTypes.string, // label for dismiss button
  onDismiss: PropTypes.func, // callback on dismiss button click
  onClose: PropTypes.func, // callback on close button click
  className: PropTypes.string,
}
