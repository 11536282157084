import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson FormHeading component.
 *
 */
export function FormHeading({ className, icon, description, children, testId, ...otherProps /* in <legend> tag */ }) {
  // generated
  const legendClassNameList = classNames(
    {
      'c-form-heading ': true,
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    'c-form-heading__icon ': true,
    'c-icon ': icon,
    [`c-icon--[${icon}] `]: icon,
  }).trim()

  // generated
  function renderIfIcon() {
    if (icon) {
      return <i className={iClassNameList} />
    }
    return null
  }

  // generated
  function renderIfText() {
    if (children) {
      return <small className="c-form-heading__text">{description}</small>
    }
    return null
  }

  // generated main result
  return (
    <legend {...otherProps} data-testid={testId} className={legendClassNameList}>
      <strong className="c-form-heading__title">
        {renderIfIcon()}
        <span className="c-form-heading__title-text">{children}</span>
      </strong>
      {renderIfText()}
    </legend>
  )
}

FormHeading.propTypes = {
  className: PropTypes.string, // Bronson template: 'heading-modifiers'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  description: PropTypes.string, // Bronson template: 'text'.
  children: PropTypes.node, // Bronson template: 'title'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
