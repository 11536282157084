import React from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson Footnote component.
 *
 * Generated React component. Do not modify.
 */
export function Footnote({ numbered, testId, children, ...otherProps /* in Complex tag name tag */ }) {
  // generated
  // tag containing a variable or condition
  const CustomTag = `${numbered ? 'ol' : 'ul'}`

  // generated main result
  return (
    <CustomTag {...otherProps} data-testid={testId} className="c-footnotes">
      {children /* Use 'Footnote.Item' component. */}
    </CustomTag>
  )
}

Footnote.propTypes = {
  numbered: PropTypes.bool, // Bronson template: 'footnote-numbered'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'footnote-item'. Use 'Footnote.Item' component.
}

/*
 * Bronson FootnoteItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function FootnoteItem({ bullet, children, ...otherProps /* in <li> tag */ }) {
  // generated main result
  return (
    <li
      {...otherProps}
      data-footnote-bullet={bullet} // applies condition
    >
      {children}
    </li>
  )
}

FootnoteItem.propTypes = {
  bullet: PropTypes.string, // Bronson template: 'bullet'.
  children: PropTypes.node, // Bronson template: 'text'.
}

FootnoteItem.displayName = 'Footnote.Item'
Footnote.Item = FootnoteItem
