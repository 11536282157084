import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Header component.
 *
 * Generated React component. Do not modify.
 */
export function Header({
  className,
  logo,
  testId,
  children,
  notificationCenter,
  staticHeader, // static is a reserved keyword
  ...otherProps /* in <header> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      'c-header ': true,
      // main class modifier convenience prop extension
      'c-header--static ': staticHeader, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  function renderIfNotificationCenter() {
    if (notificationCenter) {
      return <div className="c-header__notification-center">{notificationCenter}</div>
    }
    return null
  }

  // generated main result
  return (
    <header {...otherProps} data-testid={testId} className="c-header__wrapper">
      <div className={divClassNameList}>
        <div className="c-header__content-wrapper">
          <div className="c-header__logo">{logo}</div>
          {React.Children.map(children, (child) => {
            if (!child) {
              return null
            }
            return child.props.isContextBar ? child : <div className="c-header__nav">{child}</div>
          })}
          {renderIfNotificationCenter()}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string, // Bronson template: 'header-modifier'.
  logo: PropTypes.node, // Bronson template: 'bronson-logo'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]), // use ContextBar or SiteNav
  notificationCenter: PropTypes.element, // Bronson template: 'notification-center'. use NotificationCenter
  /* Convenience props */
  staticHeader: PropTypes.bool, // Convenience prop for c-header--static (Bronson template: 'header-modifier').
}
