import React from 'react'
import PropTypes from 'prop-types'
import { PageWrap } from '../../Objects/PageWrap/PageWrap'
import { ReactLink } from '../Link/Link'
/*
 * Bronson Footer component.
 */
export function Footer({ children, ...otherProps /* in <footer> tag */ }) {
  // generated main result
  return (
    <footer {...otherProps} className="c-footer">
      {children}
    </footer>
  )
}

Footer.propTypes = {
  children: PropTypes.node, // Combinstion of modules below, each 0..1
}

// Module 1

export function FooterModuleBreadcrumbs({ children, ...otherProps }) {
  return (
    <div {...otherProps} className="c-footer__module c-footer__module--breadcrumb">
      <PageWrap>
        <div className="c-footer-breadcrumb">{children /* Use breadcrumb component */}</div>
      </PageWrap>
    </div>
  )
}

FooterModuleBreadcrumbs.propTypes = {
  children: PropTypes.node, // Bronson template: 'footer-breadcrumb'.
}

Footer.ModuleBreadcrumbs = FooterModuleBreadcrumbs

// Module 2

export function FooterModuleNavColumns({ children, ...otherProps }) {
  return (
    <div {...otherProps} className="c-footer__module c-footer__module--nav">
      <PageWrap>
        <nav className="c-footer-nav">
          <div className="c-footer-nav__columns">{children /* Use 'FooterNavColumn' component. */}</div>
        </nav>
      </PageWrap>
    </div>
  )
}

FooterModuleNavColumns.propTypes = {
  children: PropTypes.node, // Bronson template: 'footer-nav-column'.
}

Footer.ModuleNavColumns = FooterModuleNavColumns

/*
 * Bronson FooterNavColumn component (nested).
 *
 * Generated React component. Do not modify.
 */
export function FooterNavColumn({
  title,
  children,
  // ...otherProps /* in no tag */
}) {
  // generated main result
  return (
    <div className="c-footer-nav__column js-footer-accordion">
      <div className="c-footer-nav__title js-footer-accordion__title">
        <h4 className="c-footer-nav__title-label js-footer-accordion__title-label">{title}</h4>
      </div>
      <ul className="c-footer-nav__list js-footer-accordion__panel">
        {children /* Use 'FooterNavColumnItem' component. */}
      </ul>
    </div>
  )
}

FooterNavColumn.propTypes = {
  title: PropTypes.node, // Bronson template: 'title'.
  children: PropTypes.node, // Bronson template: 'item'. Use 'Thisitem' component.
}

Footer.NavColumn = FooterNavColumn

/*
 * Bronson FooterNavColumnItem component (nested).
 *
 * Generated React component. Do not modify.
 */
export function FooterNavColumnItem({
  children,
  onClick,
  normalLink,
  href,
  linkProps,
  ...otherProps /* in <li> tag */
}) {
  // generated main result
  return (
    <li {...otherProps} className="c-footer-nav__item">
      <ReactLink className="c-footer-nav__link" onClick={onClick} href={href} normalLink={normalLink} {...linkProps}>
        {children}
      </ReactLink>
    </li>
  )
}

FooterNavColumnItem.propTypes = {
  children: PropTypes.node, // Bronson template: 'label'.
  onClick: PropTypes.func, // Added for onClick attribute.
  normalLink: PropTypes.bool,
  href: PropTypes.string,
  linkProps: PropTypes.object,
}

Footer.NavColumn.Item = FooterNavColumnItem

// Module 3

export function FooterModuleMetaNav({
  children,
  copyrightSign,
  copyrightText,
  // ...otherProps
}) {
  return (
    <div className="c-footer__module c-footer__module--meta-nav">
      <div className="o-page-wrap">
        <nav className="c-footer-meta-bar">
          <ul className="c-footer-meta-nav">{children /* Use 'FooterMetaNavItem' component. */}</ul>
          <span className="c-footer-copyright">
            <span className="c-footer-copyright__sign">{copyrightSign}</span>
            &nbsp;
            <span className="c-footer-copyright__text">{copyrightText}</span>
          </span>
        </nav>
      </div>
    </div>
  )
}

FooterModuleMetaNav.propTypes = {
  children: PropTypes.node,
  copyrightSign: PropTypes.string, // Bronson template: 'copyrightSign'.
  copyrightText: PropTypes.string, // Bronson template: 'copyrightText'.
}

FooterModuleMetaNav.defaultProps = {
  copyrightSign: '©',
}

Footer.ModuleMetaNav = FooterModuleMetaNav

/*
 * Bronson FooterMetaNavItem component (nested).
 *
 * Generated React component. Do not modify.
 */
export function FooterModuleMetaNavItem({
  children,
  onClick,
  normalLink,
  href,
  linkProps,
  ...otherProps /* in <li> tag */
}) {
  // generated main result
  return (
    <li {...otherProps} className="c-footer-meta-nav__item">
      <ReactLink
        className="c-footer-meta-nav__link"
        onClick={onClick}
        href={href}
        normalLink={normalLink}
        {...linkProps}
      >
        {children}
      </ReactLink>
    </li>
  )
}

FooterModuleMetaNavItem.propTypes = {
  children: PropTypes.node, // Bronson template: 'label'.
  onClick: PropTypes.func, // Added for onClick attribute.
  normalLink: PropTypes.bool,
  href: PropTypes.string,
  linkProps: PropTypes.object,
}

Footer.ModuleMetaNav.Item = FooterModuleMetaNavItem

// Module 4

export function FooterModuleFootnotes({
  children,
  footerText,
  // ...otherProps
}) {
  // generated
  function renderFooterText() {
    if (footerText) {
      return <p>{footerText}</p>
    }
    return null
  }

  return (
    <div className="c-footer__module c-footer__module--footnotes">
      <div className="o-page-wrap">
        <div className="c-footer-footnotes">
          {renderFooterText()}
          {children /* Use footnotes component */}
        </div>
      </div>
    </div>
  )
}

FooterModuleFootnotes.propTypes = {
  footerText: PropTypes.node, // Bronson template: 'footer-text'.
  children: PropTypes.node, // Bronson template: 'footnotes'.
}

Footer.ModuleFootnotes = FooterModuleFootnotes
