import React, { useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ProductSearchContext } from './ProductSearchContext'
import { productFilterReducer, productFilterReducerActions } from './ProductSearchReducer'

/**
 * @param children
 * @param sidebar
 * @param className
 * @param otherProps
 * @return {JSX.Element}
 * @constructor
 */
export function ProductSearch({ children, sidebar, initialState, className, ...otherProps /* in <div> tag */ }) {
  const [state, dispatch] = useReducer(productFilterReducer, initialState)

  const sectionClassName = classNames(
    {
      'c-product-search': true,
      'c-product-search--has-sidebar': sidebar,
    },
    className
  ).trim()

  return (
    <ProductSearchContext.Provider value={{ state, dispatch }}>
      <section className={sectionClassName} {...otherProps}>
        {children}
      </section>
    </ProductSearchContext.Provider>
  )
}

ProductSearch.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string, // Bronson template: 'product-search-modifier'.
  sidebar: PropTypes.bool, // Bronson template: 'sidebar'.
  initialState: PropTypes.shape({
    isInteractionLayerHidden: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.object),
    defaultFilters: PropTypes.arrayOf(PropTypes.object),
  }),
}

export function ProductSearchLayoutHeader({ children }) {
  return <header className="c-product-search__header">{children}</header>
}

export function ProductSearchLayoutSidebar({ children }) {
  return (
    <>
      <div className="c-product-search__sidebar">{children}</div>
    </>
  )
}

export function ProductSearchLayoutResults({ children }) {
  return <div className="c-product-search__results">{children}</div>
}

export function ProductSearchLayoutAside({ children }) {
  const { state, dispatch } = useContext(ProductSearchContext)
  return (
    <div className="c-product-search__aside">
      {React.cloneElement(children, {
        isHidden: state.isInteractionLayerHidden,
        onClose: () => dispatch({ type: productFilterReducerActions.hideFilters }),
      })}
    </div>
  )
}

export function ProductSearchLayoutFooter({ children }) {
  return <div className="c-product-search__footer">{children}</div>
}

export const toFilterTags = ({ maxValue, minValue, unit = '', value }) => {
  const v = Array.isArray(value) ? value.join(', ') : value
  if (v) {
    return `${v} ${unit}`
  }
  if (maxValue) {
    if (minValue) {
      return `${minValue}–${maxValue} ${unit}`
    }
    return `${maxValue} ${unit}`
  }
  return ''
}

ProductSearch.LayoutHeader = ProductSearchLayoutHeader
ProductSearch.LayoutHeader.displayName = 'ProductSearch.LayoutHeader'
ProductSearch.LayoutSidebar = ProductSearchLayoutSidebar
ProductSearch.LayoutSidebar.displayName = 'ProductSearch.LayoutSidebar'
ProductSearch.LayoutResults = ProductSearchLayoutResults
ProductSearch.LayoutResults.displayName = 'ProductSearch.LayoutResults'
ProductSearch.LayoutAside = ProductSearchLayoutAside
ProductSearch.LayoutAside.displayName = 'ProductSearch.LayoutAside'
ProductSearch.LayoutFooter = ProductSearchLayoutFooter
ProductSearch.LayoutFooter.displayName = 'ProductSearch.LayoutFooter'
