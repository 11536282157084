import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson TagList component.
 *
 * Generated React component. Do not modify.
 */
export function TagList({ className, testId, children, ...otherProps /* in <ul>, <li> tag */ }) {
  const ulClassName = classNames('c-tag-list', className).trim()

  // generated
  function renderChildren() {
    return React.Children.map(children, (child) => {
      if (!child) {
        return null
      }
      return <li {...otherProps}>{child}</li>
    })
  }

  // generated main result
  return (
    <ul {...otherProps} data-testid={testId} className={ulClassName}>
      {renderChildren()}
    </ul>
  )
}

TagList.propTypes = {
  classname: PropTypes.string, // Bronson template: 'modifiers'.
  testId: PropTypes.string, // Added for data-testid attribute.
}
