import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '../Button/Button'

export function TableButton({ className, sort, icon, testId, children, ...otherProps /* in <Button> tag */ }) {
  const sortAsc = sort === TableButton.SORT_ASC
  const sortDesc = sort === TableButton.SORT_DESC

  const componentListClass = classNames(
    {
      'c-table__btn  ': true,
      'c-table__btn-sort  ': sortAsc || sortDesc,
      'is-sort-asc  ': sortAsc,
    },
    className
  ).trim()

  const sortProps = sort
    ? {
        'data-sort-order': sortAsc ? 'asc' : 'desc',
        icon: true,
        iconViaCss: true,
        iconReversed: true,
      }
    : {}

  const nonSortProps = sort
    ? {}
    : {
        secondary: !!icon,
        small: !!icon,
        icon,
      }

  return (
    <Button className={componentListClass} {...sortProps} {...nonSortProps} data-testid={testId} {...otherProps}>
      {children}
    </Button>
  )
}

TableButton.SORT_ASC = 'asc'
TableButton.SORT_DESC = 'desc'

TableButton.propTypes = {
  className: PropTypes.string,
  sort: PropTypes.oneOf([TableButton.SORT_ASC, TableButton.SORT_DESC]),
  icon: PropTypes.string,
  testId: PropTypes.string,
  children: PropTypes.node,
}
