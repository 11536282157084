import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson HeroTeaser component.
 *
 * Generated React component.
 */
export function HeroTeaser({
  className,
  insideWrapper,
  imgProps,
  box,
  pretitle,
  title,
  sub,
  children,
  noImage,
  buttonIsHiddenOnLargeScreen,
  buttonText,
  infoIconOnTop,
  bar,
  infoIcon,
  testId,
  topLeft,
  topCenter,
  topRight,
  centerLeft,
  centerRight,
  bottomLeft,
  bottomCenter,
  bottomRight,
  shallow,
  auto,
  inverted,
  onButtonClick,
  ...otherProps /* in <div> tag */
}) {
  /*
   * Object-fit polyfill, see src/polyfill
   *
   * ObjectFitImages is also used by Bronson and therefore properly supported with CSS attributes.
   * Note: Alternative constancecchen/object-fit-polyfill has issues due to dynamic height.
   */
  const objectFitPolyfill = !!window.objectFitImages

  /*
   * Layout effect for modifying the DOM via polyfill synchronously before Browser painting.
   */
  useLayoutEffect(() => {
    if (objectFitPolyfill) {
      // @TODO: apply parameters more specifically
      window.objectFitImages('img.c-hero-teaser__image', { watchMQ: true })
    }
  })

  // generated
  const divClassNameList = classNames(
    {
      'c-hero-teaser ': true,
      // main class modifier convenience prop extension
      'c-hero-teaser--top-left ': topLeft, // Convenience prop from Bronson variants.
      'c-hero-teaser--top-center ': topCenter, // Convenience prop
      'c-hero-teaser--top-right ': topRight, // Convenience prop
      'c-hero-teaser--center-left ': centerLeft, // Convenience prop
      'c-hero-teaser--center-right ': centerRight, // Convenience prop
      'c-hero-teaser--bottom-center ': bottomCenter, // Convenience prop
      'c-hero-teaser--bottom-left ': bottomLeft, // Convenience prop
      'c-hero-teaser--bottom-right ': bottomRight, // Convenience prop
      'c-hero-teaser--shallow ': shallow, // Convenience prop from Bronson variants.
      'c-hero-teaser--auto ': auto, // Convenience prop from Bronson variants.
      'c-hero-teaser--inverted ': inverted, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  function renderHeroTeaserPretitle() {
    if (pretitle) {
      return (
        <div className="c-hero-teaser__pretitle">
          <p>{pretitle}</p>
        </div>
      )
    }
    return null
  }

  // generated
  function renderHeroTeaserSub() {
    if (sub) {
      return (
        <div className="c-hero-teaser__subtitle">
          <p>{sub}</p>
        </div>
      )
    }
    return null
  }

  // generated
  function renderHeroTeaserText() {
    if (children) {
      return (
        <div className="c-hero-teaser__text">
          <p>{children}</p>
        </div>
      )
    }
    return null
  }

  // generated
  const divClassNameList2 = classNames({
    'c-hero-teaser__button ': true,
    'c-hero-teaser__button--hidden-on-large-screen ': buttonIsHiddenOnLargeScreen,
  }).trim()

  // generated
  function renderHeroTeaserHasButton() {
    if (buttonText) {
      return (
        <div id="js-sticky-button" className={divClassNameList2}>
          <button className="c-btn" onClick={onButtonClick} type="button">
            <span className="c-btn__text">{buttonText}</span>
          </button>
        </div>
      )
    }
    return null
  }

  // generated
  function renderHeroTeaserHasInfoIconOnTop() {
    if (infoIconOnTop) {
      return (
        <div className="c-hero-teaser__info-icon">
          <button className="c-info-icon c-info-icon--fill c-icon c-icon--[control-info]" type="button" />
        </div>
      )
    }
    return null
  }

  // generated
  function renderHeroTeaserHasInfoIcon() {
    if (infoIcon) {
      return (
        <div className="c-hero-teaser__info-icon">
          <button className="c-info-icon c-info-icon--fill c-icon c-icon--[control-info]" type="button" />
        </div>
      )
    }
    return null
  }

  // generated
  function renderHeroTeaserHasBar() {
    if (bar) {
      return (
        <div className="c-hero-teaser__bar">
          {renderHeroTeaserHasInfoIcon()}
          <div className="o-page-wrap">{bar}</div>
        </div>
      )
    }
    return null
  }

  function dataObjectPosition() {
    if (topLeft) {
      return 'top left'
    }
    if (topCenter) {
      return 'top center'
    }
    if (topRight) {
      return 'top right'
    }
    if (centerLeft) {
      return 'center left'
    }
    if (centerRight) {
      return 'center right'
    }
    if (bottomLeft) {
      return 'bottom left'
    }
    if (bottomCenter) {
      return 'bottom center'
    }
    if (bottomRight) {
      return 'bottom right'
    }
    return ''
  }

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={divClassNameList}>
      {noImage ? (
        <div className="c-hero-teaser__image" />
      ) : (
        <img
          className="c-hero-teaser__image"
          {...imgProps}
          data-object-fit="cover"
          data-object-position={dataObjectPosition()}
          alt={title || ''}
        />
      )}
      <div id="js-hero-teaser-content" className="c-hero-teaser__content">
        <div className="c-hero-teaser__content-inner">
          {box ? (
            <div className="c-hero-teaser__box">{box}</div>
          ) : (
            <header className="c-hero-teaser__heading">
              {renderHeroTeaserPretitle()}
              <div className="c-hero-teaser__title">
                <h1>
                  <span className="c-hero-teaser__title-row">{title}</span>
                </h1>
              </div>
              {renderHeroTeaserSub()}
              {renderHeroTeaserText()}
            </header>
          )}
          {renderHeroTeaserHasButton()}
        </div>
      </div>
      {renderHeroTeaserHasInfoIconOnTop()}
      {renderHeroTeaserHasBar()}
    </div>
  )
}

HeroTeaser.propTypes = {
  className: PropTypes.string, // Bronson template: 'hero-teaser-modifier'.
  insideWrapper: PropTypes.bool, // Bronson template: 'hero-teaser-inside-wrapper'.
  imgProps: PropTypes.object, // Bronson template: 'srcset'.
  box: PropTypes.node, // Bronson template: 'bronson-user-form'.
  pretitle: PropTypes.node, // Bronson template: 'hero-teaser-pretitle'.
  title: PropTypes.node, // Bronson template: 'hero-teaser-title'.
  sub: PropTypes.node, // Bronson template: 'hero-teaser-sub'.
  children: PropTypes.node, // Bronson template: 'hero-teaser-text'.
  noImage: PropTypes.bool, // Bronson template: 'hero-teaser-has-no-image'.
  buttonIsHiddenOnLargeScreen: PropTypes.bool, // Bronson template: 'hero-teaser-button-is-hidden-on-large-screen'.
  buttonText: PropTypes.string, // Bronson template: 'hero-teaser-button-text'.
  infoIconOnTop: PropTypes.bool, // Bronson template: 'hero-teaser-has-info-icon-on-top'.
  bar: PropTypes.node, // Bronson template: 'hero-teaser-has-bar'.
  infoIcon: PropTypes.bool, // Bronson template: 'hero-teaser-has-info-icon'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onButtonClick: PropTypes.func,
  /* Convenience props */
  topLeft: PropTypes.bool, // Convenience prop for c-hero-teaser--top-left (Bronson template: 'hero-teaser-modifier').
  topCenter: PropTypes.bool,
  topRight: PropTypes.bool,
  centerLeft: PropTypes.bool,
  centerRight: PropTypes.bool,
  bottomCenter: PropTypes.bool,
  bottomLeft: PropTypes.bool,
  bottomRight: PropTypes.bool,
  shallow: PropTypes.bool, // Convenience prop for c-hero-teaser--shallow (Bronson template: 'hero-teaser-modifier').
  auto: PropTypes.bool, // Convenience prop for c-hero-teaser--auto (Bronson template: 'hero-teaser-modifier').
  inverted: PropTypes.bool, // Convenience prop for c-hero-teaser--inverted (Bronson template: 'hero-teaser-modifier').
}
