import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { ButtonContainer } from '../ButtonContainer/ButtonContainer'

/*
 * Bronson Card component.
 *
 * Generated React component. Do not modify.
 */
export function Card({
  element,
  className,
  icon,
  imageSrc,
  imageAlt,
  imageProps,
  mediaElement,
  title,
  subtitle,
  success,
  warning,
  error,
  inactive,
  scrollable,
  collapsible,
  expandable,
  buttons,
  disabled,
  offer,
  defaultExpanded,
  tags,
  contentShort,
  contentLarge,
  iconList,
  additionalInfo,
  footerContent,
  footer,
  small,
  testId,
  children,
  ...otherProps /* in Complex tag name tag */
}) {
  const [expanded, setExpanded] = useState(defaultExpanded)

  // generated
  // tag containing a variable or condition
  const CustomTagCardElement = `${element}`

  // generated
  const ClassNameList = classNames(
    {
      'c-card ': true,
      'c-card--image': (imageSrc || imageProps) && !offer,
      'c-card--button-group': buttons && buttons.length > 0 && !offer,
      'c-card--states': success || warning || error,
      'c-card--states-success': success,
      'c-card--states-warning': warning,
      'c-card--states-error': error,
      'c-card--inactive': inactive,
      'c-card--scrollable': scrollable,
      'c-card--collapsible': collapsible,
      'c-card--expandable': expandable,
      'c-card--small': small,
      'c-card--offer': offer,
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    'c-icon ': true,
    [`c-icon--[${icon}] `]: true,
  }).trim()

  // generated
  function renderIfIcon() {
    if (icon) {
      return (
        <div className="c-card__icon">
          <i className={iClassNameList} aria-hidden="true" role="img" />
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfImage() {
    if (imageSrc || imageProps) {
      return (
        <div className="c-card__image">
          <img src={imageSrc} alt={imageAlt} {...imageProps} />
          {renderBadge()}
        </div>
      )
    }
    return null
  }

  const renderIfMedia = () => {
    if (mediaElement) {
      return <div className="c-card__image">{mediaElement}</div>
    }
    return null
  }

  // generated
  function renderIfSubtitle() {
    if (subtitle) {
      return <p className="c-card__subtitle">{subtitle}</p>
    }
    return null
  }

  // generated
  function renderIfTitle() {
    if (title) {
      return (
        <header className="c-card__header">
          <h4 className="c-card__title">{title}</h4>
          {renderIfSubtitle()}
        </header>
      )
    }
    return null
  }

  // generated
  function renderIfOffer() {
    if (offer) {
      return <>{renderIfTitle()}</>
    }
    return null
  }

  // generated
  function renderBadge() {
    if (offer) {
      return (
        <div className="c-card__badge">
          <ul className="c-tag-list">
            <li>{tags}</li>
          </ul>
        </div>
      )
    }
    return null
  }

  // unless offer

  // generated
  function renderIfTitleUnlessOffer() {
    if (title) {
      return (
        <header className="c-card__header">
          {expandable ? (
            <>
              <div className="c-card__header-items">
                <h4 className="c-card__title">{title}</h4>
                {contentShort}
              </div>
            </>
          ) : (
            <>
              <h4 className="c-card__title">{title}</h4>
            </>
          )}
          {renderIfSubtitle()}
        </header>
      )
    }
    return null
  }

  // generated
  function renderUnlessOffer() {
    if (!offer) {
      return <>{renderIfTitleUnlessOffer()}</>
    }
    return null
  }

  // Body

  // generated
  function renderIfOfferBody() {
    if (offer) {
      return (
        <>
          <div className="c-card__icon-list">{iconList}</div>
          <hr className="c-card__divider" />
        </>
      )
    }
    return null
  }

  // generated
  function renderIfButtons() {
    if (buttons) {
      return Array.isArray(buttons) ? <ButtonContainer center>{buttons}</ButtonContainer> : buttons
    }
    return null
  }

  // generated
  const divClassNameList = classNames({
    'c-card__expandable ': true,
    'js-card-expandable ': true,
    'js-is-fx ': true,
    'js-is-ready': true,
    'is-open': expanded,
  }).trim()

  const divClassNameListPanel = classNames({
    'c-card__expandable-panel': true,
    'js-card-expandable__panel': true,
    'js-is-fx ': true,
    'js-is-ready': true,
    'is-closed': !expanded,
  }).trim()

  // generated
  const buttonIcon = 'semantic-expand' // todo
  const iClassNameList2 = classNames({
    'c-icon ': true,
    'c-btn__icon ': true,
    [`c-icon--[${buttonIcon}] `]: true,
    'c-btn--card-icon ': true,
  }).trim()

  // generated
  function renderIfContentLarge() {
    if (contentLarge) {
      return <>{contentLarge}</>
    }
    return null
  }

  const style = expanded
    ? {}
    : {
        height: '0px',
        overflowY: 'hidden',
        visibility: 'hidden',
      }

  // generated
  function renderIfIsExpandable() {
    if (expandable) {
      return (
        <>
          <div className={divClassNameList} aria-disabled={disabled && 'true'}>
            <button
              className="c-card__expandable-btn c-btn c-btn--icon-only c-btn--card js-card-expandable__trigger"
              aria-expanded={expanded ? 'true' : 'false'}
              onClick={() => {
                setExpanded(!expanded)
              }}
              type="button"
            >
              <i className={iClassNameList2} />
            </button>
            <div className={divClassNameListPanel} style={style} aria-hidden={expanded ? 'false' : 'true'}>
              {renderIfContentLarge()}
            </div>
          </div>
        </>
      )
    }
    return null
  }

  // End Body

  // generated
  function renderIfAdditionalInfo() {
    if (additionalInfo) {
      return <div className="u-text-center">{additionalInfo}</div>
    }
    return null
  }

  // generated
  function renderIfOfferButtons() {
    if (offer) {
      return (
        <ButtonContainer center stretch vertical>
          {buttons}
        </ButtonContainer>
      )
    }
    return null
  }

  // generated
  function renderIfOfferFooterDivider() {
    if (offer) {
      return <hr className="c-card__divider" />
    }
    return null
  }

  // generated
  function renderIfFooter() {
    if (footer) {
      return (
        <footer className="c-card__footer">
          {renderIfOfferFooterDivider()}
          {!offer && renderIfButtons()}
          {footerContent}
        </footer>
      )
    }
    return null
  }

  // generated main result
  return (
    <CustomTagCardElement {...otherProps} data-testid={testId} className={ClassNameList}>
      {renderIfIcon()}
      {renderIfOffer()}
      {renderIfImage()}
      {renderIfMedia()}
      {renderUnlessOffer()}
      <div className="c-card__body">
        {renderIfOfferBody()}
        {children}
        {renderIfIsExpandable()}
      </div>
      {renderIfAdditionalInfo()}
      {renderIfOfferButtons()}
      {renderIfFooter()}
    </CustomTagCardElement>
  )
}

Card.propTypes = {
  element: PropTypes.string, // Bronson template: 'card-element'.
  icon: PropTypes.string, // Bronson template: 'card-icon'.
  imageSrc: PropTypes.string, // Bronson template: 'card-image'.
  imageAlt: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  imageProps: PropTypes.object,
  mediaElement: PropTypes.node, // use it with media element, e.g. Google Maps
  title: PropTypes.node, // Bronson template: 'card-title'.
  subtitle: PropTypes.node, // Bronson template: 'card-subtitle'.
  className: PropTypes.string, // Bronson template: 'card-modifier'.
  buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.array]), // Bronson template: 'card-buttons'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'card-buttons'.
  success: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  inactive: PropTypes.bool,
  scrollable: PropTypes.bool,
  collapsible: PropTypes.bool,
  expandable: PropTypes.bool,
  disabled: PropTypes.bool,
  offer: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  tags: PropTypes.node,
  contentShort: PropTypes.node,
  contentLarge: PropTypes.node,
  iconList: PropTypes.node,
  additionalInfo: PropTypes.node,
  footerContent: PropTypes.node,
  footer: PropTypes.bool,
  small: PropTypes.bool, // Bronson modifier: 'c-card--small'
}
