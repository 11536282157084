import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'

/**
 * [1] 'interactive' does not work in combination with `appendTo`.
 *     https://atomiks.github.io/tippyjs/v6/all-props/#appendto
 *     https://atomiks.github.io/tippyjs/v6/faq/#my-tooltip-appears-cut-off-or-is-not-showing-at-all
 * [2] Please always try to position the tooltip in a clipping context to never
 *     hide tooltip content. Comes with the downside that tooltips sometimes are not centered etc..
 *     https://atomiks.github.io/tippyjs/v6/all-props/#popperoptions
 *     https://popper.js.org/docs/v2/modifiers/prevent-overflow/
 */

/**
 * Hide tooltips when they and their children lose focus.
 * @see https://atomiks.github.io/tippyjs/v6/plugins/#hideonpopperblur
 * @type {{defaultValue: boolean, name: string, fn(*=): {onCreate(): void}}}
 */
const hideOnPopperBlur = {
  name: 'hideOnPopperBlur',
  defaultValue: true,
  fn(instance) {
    return {
      onCreate() {
        instance.popper.addEventListener('focusout', (event) => {
          if (
            instance.props.hideOnPopperBlur &&
            event.relatedTarget &&
            !instance.popper.contains(event.relatedTarget)
          ) {
            instance.hide()
          }
        })
      },
    }
  },
}

/**
 * Additional {@link Popper} options.
 * @type {Popper.Options}
 */
const popperOptions = {
  // [2]
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom', 'right'],
      },
    },
    {
      name: 'preventOverflow',
      options: {
        altAxis: true,
        tether: false,
      },
    },
  ],
}

/**
 * Bronson Tooltip component.
 * @desc Tooltips are user-triggered messages that provide additional information about a page element or feature.
 *
 * @param {boolean} [alwaysVisible] - Display the tooltip permanently.
 * @param {React.ReactElement|string} [children] - And element or string that is labelling the tooltip.
 * @param {string} [className] - Additional modifier class(es). @TODO: Not in Bronson, needs to be synced.
 * @param {React.ReactNode} [content] - The content that is displayed inside the tooltip.
 * @param {Popper.Placement} [placement='bottom'] - Sets a fixed/preferred position for the tooltip.
 *                                                  Bronson uses the `[data-tippy-placement]` attribute for this.
 * @param {object} [otherProps] - Other props to apply on the {@link Tippy} element.
 * @return {JSX.Element} - The Tooltip component.
 * @constructor
 */
export function Tooltip({ alwaysVisible, children, className, content, placement = 'bottom', ...otherProps }) {
  const buttonClassNameList = classNames(
    {
      'c-tooltip': true,
    },
    className
  ).trim()

  function renderChildren() {
    if (typeof children === 'string') {
      return (
        <button type="button" className={buttonClassNameList}>
          {children}
        </button>
      )
    }
    return children
  }

  const triggerObj = useMemo(() => {
    if (alwaysVisible) {
      return { trigger: 'manual' }
    }
    return {}
  }, [alwaysVisible])

  const onTrigger = useCallback((instance) => {
    instance.reference.parentElement.style.setProperty('transition-duration', '0s')
    instance.reference.parentElement.style.setProperty('z-index', 2001)
  }, [])

  const onHidden = useCallback((instance) => {
    instance.reference.parentElement.style.removeProperty('z-index')
    instance.reference.parentElement.style.removeProperty('transition-duration')
  }, [])

  return (
    <Tippy
      content={content}
      arrow
      interactive
      allowHTML
      placement={placement}
      theme="default"
      animation="default"
      showOnCreate={alwaysVisible}
      hideOnClick={!alwaysVisible}
      onTrigger={onTrigger}
      onHidden={onHidden}
      popperOptions={popperOptions}
      plugins={[hideOnPopperBlur]}
      {...triggerObj}
      {...otherProps}
    >
      {renderChildren()}
    </Tippy>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  alwaysVisible: PropTypes.bool,
  // Bronson 'data-template' is not needed in React as we can pass easily any node element as tooltip content
}
