import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Select component.
 *
 * Generated React component. Do not modify.
 */
export function Select({ error, disabled, name, id, testId, children, ...otherProps /* in <select> tag */ }) {
  const divClassNameList = classNames(
    {
      'is-disabled': disabled,
    },
    'c-input c-input--select'
  ).trim()

  // generated
  const selectClassNameList = classNames({
    'c-input__input ': true,
    'is-error ': error,
  }).trim()

  // generated main result
  return (
    <div className={divClassNameList}>
      <select
        {...otherProps}
        disabled={disabled}
        data-testid={testId}
        className={selectClassNameList}
        name={name}
        id={id}
      >
        {children /* Use 'Select.Item' component. */}
      </select>
    </div>
  )
}

Select.propTypes = {
  error: PropTypes.bool, // Bronson template: 'select-is-error'.
  name: PropTypes.string, // Bronson template: 'select-name'.
  id: PropTypes.string, // Bronson template: 'select-id'.
  // Bronson template: 'select-attribute'. Replaced by {...otherProps}.
  disabled: PropTypes.bool, // disabled prop of select
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'select-item'. Use 'Select.Item' component.
}

/*
 * Bronson SelectItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function SelectItem({ value, children, ...otherProps /* in <option> tag */ }) {
  // generated main result
  return (
    <option {...otherProps} value={value}>
      {children}
    </option>
  )
}

SelectItem.propTypes = {
  value: PropTypes.string, // Bronson template: 'select-item-value'.
  children: PropTypes.node, // Bronson template: 'select-item-label'.
}

SelectItem.displayName = 'Select.Item'
Select.Item = SelectItem
