import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link as RouterLink } from 'react-router-dom'

/*
 * Bronson Link component.
 *
 * Generated React component. Do not modify.
 */
export function Link({
  normalLink,
  className,
  href,
  to,
  target,
  bodyClassName,
  children,
  icon,
  iconClassName,
  iconSize,
  reversed,
  onClick,
  testId,
  ...otherProps /* in <a> tag */
}) {
  // generated
  const aClassNameList = classNames(
    {
      'c-link ': true,
      // main class modifier convenience prop extension
      'c-link--reversed ': reversed, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  const spanClassNameList = classNames(
    {
      'c-link__body ': true,
    },
    bodyClassName
  ).trim()

  // generated
  const iClassNameList = classNames(
    {
      'c-link__icon ': true,
      'c-icon ': true,
      [`c-icon--[${icon}] `]: icon,
      [`c-icon--[${iconSize}] `]: iconSize,
    },
    iconClassName
  ).trim()

  // generated
  function renderIfIcon() {
    if (icon) {
      return <i className={iClassNameList} />
    }
    return null
  }

  function renderContent() {
    if (reversed) {
      return (
        <>
          <span className={spanClassNameList}>
            <span className="c-link__effect">{children}</span>
          </span>
          {renderIfIcon()}
        </>
      )
    }
    return (
      <>
        {renderIfIcon()}
        <span className={spanClassNameList}>
          <span className="c-link__effect">{children}</span>
        </span>
      </>
    )
  }

  // generated main result
  return (
    <ReactLink
      className={aClassNameList}
      normalLink={normalLink}
      href={href}
      to={to}
      target={target} // applies condition
      onClick={onClick}
      data-testid={testId}
      {...otherProps}
    >
      {renderContent()}
    </ReactLink>
  )
}

export const linkToType = PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])

Link.propTypes = {
  normalLink: PropTypes.bool,
  href: PropTypes.string,
  to: linkToType,
  className: PropTypes.string, // Bronson template: 'link-modifier'.
  target: PropTypes.string, // Bronson template: 'link-target'.
  bodyClassName: PropTypes.string, // Bronson template: 'link-body-modifier'.
  children: PropTypes.node, // Bronson template: 'label'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  iconSize: PropTypes.string,
  iconClassName: PropTypes.string, // Bronson template: 'icon-modifier'.
  reversed: PropTypes.bool, // Bronson template: 'link-is-reversed'.
  onClick: PropTypes.func, // Added for onClick attribute.
  testId: PropTypes.string, // Added for data-testid attribute.
}

export function ReactLink({
  normalLink,
  className,
  href,
  to,
  target,
  onClick,
  testId,
  children,
  ...otherProps /* in <a> tag */
}) {
  return normalLink ? (
    <a
      className={className}
      href={href}
      target={target} // applies condition
      onClick={onClick}
      data-testid={testId}
      {...otherProps}
    >
      {children}
    </a>
  ) : (
    <RouterLink
      className={className}
      to={to || href}
      target={target} // applies condition
      onClick={onClick}
      data-testid={testId}
      {...otherProps}
    >
      {children}
    </RouterLink>
  )
}

ReactLink.propTypes = {
  anchor: PropTypes.bool,
  href: PropTypes.string,
  to: linkToType,
  className: PropTypes.string, // Bronson template: 'link-modifier'.
  target: PropTypes.string, // Bronson template: 'link-target'.
  onClick: PropTypes.func, // Added for onClick attribute.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'label'.
}
