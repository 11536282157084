/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: @Bronson check usage of anchors!
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormContext } from '../Form/FormContext'
import { FormFieldContext } from './FormFieldContext'

/*
 * Values for 'type' prop.
 */
const typeValues = ['input', 'textarea', 'select', 'checkbox', 'other']

/**
 * Bronson FormField component.
 *
 * @param children
 * @param {string} [className] - Additional CSS class.
 * @param {string} [sideLink] - The side link text.
 * @param {string} [labelElement] - A valid HTML5 tag name to use as label element.
 * @param {node} [labelText] - The label text.
 * @param {string} [labelForId] - The identifier to use for the the label element’s 'for' attribute.
 *                                Only applies if {@link labelElement} equals 'label'.
 * @param {boolean} [notion]- A flag whether to display a required notion.
 * @param {string} [mark] - A string to denote a required field. Fallbacks to '* '.
 *                          Only applies if {@link notion} is set to 'true'.
 * @param {InfoIcon} [infoIcon] - An optional {@link InfoIcon} to display.
 * @param {string} [id] - Identifier prefix to use for labelling.
 * @param {string} [errorMessage] - A string to display validation errors.
 * @param {string} [testId] - The testId string.
 * @param {function} [onClick] - Handle side-link .
 * @param {node} [hint] - Flag to display the form field hint element.
 * @param {string} [type] - Hint the type for the containing element. Can be one of {@link typeValues}.
 * @param {boolean} [noFloatingLabel] - A flag to disable the floating label behavior.
 * @param {boolean} [inline] - Flag to enable the `inline` behavior.
 * @param {object} [otherProps] - Other properties to pass to the FormField div element.
 * @return {JSX.Element}
 * @constructor
 */
export function FormField({
  className,
  sideLink,
  labelElement = 'label',
  labelText,
  labelForId,
  children,
  notion,
  mark,
  infoIcon,
  id,
  errorMessage,
  testId,
  onClick,
  hint,
  type,
  noFloatingLabel,
  inline,

  ...otherProps /* in <div> tag */
}) {
  const context = React.useContext(FormContext)

  const [elementActive, setElementActive] = useState(false)

  const contextValue = {
    setElementActive: (active) => setElementActive(active),
  }

  const formFloatingLabel = context && context.floatingLabel

  useEffect(() => {
    if (!type) {
      const valueHint = typeValues.join(', ')
      if (formFloatingLabel) {
        console.error(
          `Warning: The prop \`type\` is required in <FormField> (possible values: ${valueHint}) because floating labels are enabled in <Form> element.`
        )
      } else {
        console.warn(
          `The prop \`type\` should be added to <FormField> (possible values: ${valueHint}). This warning has no negative effect because floating labels are not enabled in the <Form> element.`
        )
      }
    }
  })

  const standardLabel =
    // <Form floatingLabel> prop not set
    !formFloatingLabel ||
    // no floating when explicitly set
    noFloatingLabel ||
    // downstream element is active (e.g. contains text, triggered via onChange in element on callback)
    elementActive ||
    // no floating if not in [input, textarea]
    (type !== 'input' && type !== 'textarea') ||
    // no floating with InfoIcon
    infoIcon

  // generated
  const divClassNameList = classNames(
    'c-form-field ',
    {
      'c-form-field--no-floating ': noFloatingLabel,
      'c-form-field--floating-textarea ': formFloatingLabel && !noFloatingLabel && type === 'textarea',
      'is-active ': formFloatingLabel && standardLabel,
      'c-form-field--inline': inline,
    },
    className
  ).trim()

  // generated
  function renderIfSideLink() {
    if (sideLink) {
      return (
        <a
          id={`${id}-side-link`}
          data-testid={`${testId}-side-link`}
          onClick={onClick}
          className="c-form-field__side-link"
        >
          {sideLink}
        </a>
      )
    }
    return null
  }

  // generated
  // tag containing a variable or condition
  const CustomTagLabelElement = `${labelElement}`

  // generated
  function renderIfNotion() {
    if (notion) {
      return <span className="c-form-field__notion">{mark || '* '} </span>
    }
    return null
  }

  // generated
  function renderIfLabel() {
    if (labelText) {
      return (
        <CustomTagLabelElement
          htmlFor={labelElement === 'label' && labelForId ? labelForId : undefined}
          className="c-form-field__label"
          aria-describedby={(sideLink && `${id}-side-link`) || (hint && `${id}-hint`)}
        >
          {labelText}
          {renderIfNotion()}
          {renderIfInfoIcon()}
        </CustomTagLabelElement>
      )
    }
    return null
  }

  // generated
  function renderIfInfoIcon() {
    if (infoIcon) {
      return <>{infoIcon}</>
    }
    return null
  }

  // generated
  function renderIfErrorMessage() {
    if (errorMessage) {
      return <>{errorMessage}</>
    }
    return null
  }

  function renderIfHint() {
    if (hint) {
      return (
        <p id={`${id}-hint`} className="c-form-field__hint">
          {hint}
        </p>
      )
    }
    return null
  }

  // generated main result
  return (
    <div {...otherProps} id={id} data-testid={testId} className={divClassNameList}>
      {renderIfSideLink()}
      {renderIfLabel()}
      <div className="c-form-field__box">
        <FormFieldContext.Provider value={contextValue}>{children}</FormFieldContext.Provider>
        {renderIfErrorMessage()}
      </div>
      {renderIfHint()}
    </div>
  )
}

FormField.propTypes = {
  className: PropTypes.string, // Bronson template: 'modifiers'.
  sideLink: PropTypes.string, // Bronson template: 'side-link'.
  labelElement: PropTypes.string, // Bronson template: 'label-element'.
  labelText: PropTypes.node, // Bronson template: 'label'.
  labelForId: PropTypes.string, // id which the label references
  notion: PropTypes.bool, // Bronson template: 'notion'.
  mark: PropTypes.string, // Bronson template: 'mark'.
  infoIcon: PropTypes.node, // Bronson template: 'bronson-info-icon'.
  id: PropTypes.string, // Bronson template: 'id'.
  errorMessage: PropTypes.node, // Bronson template: 'bronson-error-message'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onClick: PropTypes.func, // Added for onClick attribute.
  hint: PropTypes.node, // Bronson template: 'hint'
  inline: PropTypes.bool,

  /*
   Floating label support. Note that according to Bronson doc, the *Form* itself has a <Form floatingLabel> 
   prop to enable floating labels.
   */
  type: PropTypes.oneOf(typeValues), // Hint on contained element in order to properly handle floating labels.
  noFloatingLabel: PropTypes.bool, // Bronson approach to disable floating label for e.g. checkbox
}
