import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson DefinitionListHorizontal component.
 *
 * Generated React component. Do not modify.
 */
export function DefinitionListHorizontal({
  className,
  testId,
  children,
  use33To66,
  use66To33,
  use50To50,
  ...otherProps /* in <dl> tag */
}) {
  // generated
  const dlClassNameList = classNames(
    {
      'c-dl-horizontal ': true,
      // main class modifier convenience prop extension
      'c-dl-horizontal--33-to-66 ': use33To66, // Convenience prop from Bronson variants., renamed automatically because '33-to-66' would start with number
      'c-dl-horizontal--66-to-33 ': use66To33, // Convenience prop from Bronson variants., renamed automatically because '66-to-33' would start with number
      'c-dl-horizontal--50-to-50 ': use50To50, // Convenience prop from Bronson variants., renamed automatically because '50-to-50' would start with number
    },
    className
  ).trim()

  // generated main result
  return (
    <dl {...otherProps} data-testid={testId} className={dlClassNameList}>
      {children /* Use 'DefinitionListHorizontal.Item' component. */}
    </dl>
  )
}

DefinitionListHorizontal.propTypes = {
  className: PropTypes.string, // Bronson template: 'definition-list-horizontal-modifier'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'definition-list-horizontal-items'. Use 'DefinitionListHorizontal.Item' component.
  /* Convenience props */
  use33To66: PropTypes.bool, // Convenience prop for c-dl-horizontal--33-to-66 (Bronson template: 'definition-list-horizontal-modifier').
  use66To33: PropTypes.bool, // Convenience prop for c-dl-horizontal--66-to-33 (Bronson template: 'definition-list-horizontal-modifier').
  use50To50: PropTypes.bool, // Convenience prop for c-dl-horizontal--50-to-50 (Bronson template: 'definition-list-horizontal-modifier').
}

/*
 * Bronson DefinitionListHorizontalItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function DefinitionListHorizontalItem({ title, children, testId, ...otherProps /* in <dt> tag */ }) {
  // generated main result
  return (
    <>
      <dt className="c-dl-horizontal__title" data-testid={testId} {...otherProps}>
        {title}
      </dt>
      <dd className="c-dl-horizontal__item" data-testid={`${testId}-dd`}>
        {children}
      </dd>
    </>
  )
}

DefinitionListHorizontalItem.propTypes = {
  title: PropTypes.node, // Bronson template: 'title'.
  children: PropTypes.node, // Bronson template: 'data'.
  testId: PropTypes.string, // Added for data-testid attribute.
}

DefinitionListHorizontalItem.displayName = 'DefinitionListHorizontal.Item'
DefinitionListHorizontal.Item = DefinitionListHorizontalItem
