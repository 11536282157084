import React, { useRef, useEffect, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import Plyr from 'plyr'
import { useBronson } from '../../features/useBronson'

/*
 * Bronson Video component.
 *
 */
export const Video = React.forwardRef(
  (
    {
      testid,
      selfHosted,
      poster,
      src,
      alt,
      iconUrl,
      autoPlay,
      loop,
      service,
      id,
      onPlaying,
      onPlay,
      onPause,
      onEnded,
      onEnterFullscreen,
      onExitFullscreen,
      ...otherProps /* in <video> or <div> tag */
    },
    ref
  ) => {
    const { cdn: bronsonCDN } = useBronson()

    const videoRef = useRef(null)
    const plyrInstance = useRef(null)

    // NOTE: refs and imperative handle are here intended as API for media playback is forwarded to parent
    // state resides within plyr player and is not mapped to another state object copy
    useImperativeHandle(ref, () => ({
      play: () => {
        plyrInstance.current.play()
      },
      pause: () => {
        plyrInstance.current.pause()
      },
      stop: () => {
        plyrInstance.current.stop()
      },
      fullscreen: () => {
        plyrInstance.current.fullscreen.enter()
      },
    }))

    useEffect(() => {
      if (!videoRef.current) return undefined
      const plyrConfig = {
        debug: false,
        autoPlay,
        invertTime: false,
        controls: ['play', 'play-large', 'progress', 'current-time', 'mute', 'volume', 'captions', 'fullscreen'],
        fullscreen: {
          fallback: true,
        },
        youtube: {
          noCookie: true,
        },
        urls: {
          youtube: {
            api: '', // Disable YouTube API (for title/aspect ratio calculation) calls through third-party noembed.com service.
          },
        },
      }
      const iconUrlValue = iconUrl || (bronsonCDN ? `${bronsonCDN}/svg/plyr-sprite.svg` : null)
      if (iconUrlValue) {
        plyrConfig.iconUrl = iconUrlValue
      }
      if (!plyrInstance.current) {
        plyrInstance.current = new Plyr(videoRef.current, plyrConfig)
        onPlaying && plyrInstance.current.on('playing', (event) => onPlaying(event.detail.plyr))
        onPlay && plyrInstance.current.on('play', (event) => onPlay(event.detail.plyr))
        onPause && plyrInstance.current.on('pause', (event) => onPause(event.detail.plyr))
        onEnded && plyrInstance.current.on('ended', (event) => onEnded(event.detail.plyr))
        onEnterFullscreen && plyrInstance.current.on('enterfullscreen', (event) => onEnterFullscreen(event.detail.plyr))
        onExitFullscreen && plyrInstance.current.on('exitfullscreen', (event) => onExitFullscreen(event.detail.plyr))
      }
      return () => {
        plyrInstance.current.destroy()
      }
    }, [
      videoRef,
      autoPlay,
      onPlaying,
      onPlay,
      onPause,
      onEnded,
      onEnterFullscreen,
      onExitFullscreen,
      iconUrl,
      bronsonCDN,
    ])

    // NOTE: need to wrap a div around as Plyr is manipulating complete DOM and React is otherwise not able to unmount
    return (
      <div>
        {selfHosted ? (
          <video
            ref={videoRef}
            poster={poster}
            className="js-video"
            controls
            autoPlay={autoPlay}
            loop={loop}
            {...otherProps}
          >
            {src &&
              Object.entries(src).map((entry) => (
                <source key={`${entry[0]}${entry[1]}`} type={entry[0]} src={entry[1]} />
              ))}
            {alt}
          </video>
        ) : (
          <div
            ref={videoRef}
            data-plyr-provider={service}
            data-plyr-embed-id={id}
            className="js-video"
            {...otherProps}
          />
        )}
      </div>
    )
  }
)

Video.propTypes = {
  testId: PropTypes.string,
  selfHosted: PropTypes.bool, // Bronson template: 'video-is-self-hosted'.
  poster: PropTypes.string,
  src: PropTypes.objectOf(PropTypes.string),
  alt: PropTypes.string,
  iconUrl: PropTypes.string, // Bronson template: 'path'.
  autoPlay: PropTypes.bool, // Bronson template: 'video-has-autoplay'.
  loop: PropTypes.bool, // Bronson template: 'video-has-loop'.
  service: PropTypes.string, // Bronson template: 'video-service'.
  id: PropTypes.string, // Bronson template: 'video-id'.
  onPlaying: PropTypes.func, // event callback when media begins to play, see https://github.com/sampotts/plyr
  onPlay: PropTypes.func, // event callback when media begins to play after being paused, see https://github.com/sampotts/plyr
  onPause: PropTypes.func, // event callback when media is paused, see https://github.com/sampotts/plyr
  onEnded: PropTypes.func, // event callback when media completes, see https://github.com/sampotts/plyr
  onEnterFullscreen: PropTypes.func, // event callback when fullscreen is entered, see https://github.com/sampotts/plyr
  onExitFullscreen: PropTypes.func, // event callback when on exit fullscreen, see https://github.com/sampotts/plyr
}
