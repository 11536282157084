import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson Pagination component.
 *
 * Generated React component. Do not modify.
 */
export function Pagination({
  title,
  onPrevClick,
  prevDisabled,
  prevText,
  onNextClick,
  nextDisabled,
  nextText,
  testId,
  children,
  ...otherProps /* in <nav> tag */
}) {
  // generated
  const aClassNameList = classNames({
    'c-pagination__link ': true,
    'c-pagination__link--stepper-prev ': true,
    'is-disabled ': prevDisabled,
  }).trim()

  // generated
  const aClassNameList2 = classNames({
    'c-pagination__link ': true,
    'c-pagination__link--stepper-next ': true,
    'is-disabled ': nextDisabled,
  }).trim()

  function renderIfNotPrevDisabled() {
    return prevDisabled ? null : (
      <a href="#" className={aClassNameList} title={prevText} onClick={onPrevClick}>
        <span className="u-visually-hidden">{prevText}</span>
      </a>
    )
  }

  function renderIfPrevDisabled() {
    return prevDisabled ? (
      <span className={aClassNameList} title={prevText}>
        <span className="u-visually-hidden">{prevText}</span>
      </span>
    ) : null
  }

  function renderIfNotNextDisabled() {
    return nextDisabled ? null : (
      <a href="#" onClick={onNextClick} className={aClassNameList2} title={nextText}>
        <span className="u-visually-hidden">{nextText}</span>
      </a>
    )
  }

  function renderIfNextDisabled() {
    return nextDisabled ? (
      <span className={aClassNameList2} title={nextText}>
        <span className="u-visually-hidden">{nextText}</span>
      </span>
    ) : null
  }

  // generated main result
  return (
    <nav {...otherProps} data-testid={testId} className="c-pagination" aria-label={title}>
      <ul className="c-pagination__list">
        <li className="c-pagination__item">
          {renderIfNotPrevDisabled()}
          {renderIfPrevDisabled()}
        </li>
        {children /* Use 'Pagination.Item' component. */}
        <li className="c-pagination__item">
          {renderIfNotNextDisabled()}
          {renderIfNextDisabled()}
        </li>
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  title: PropTypes.string, // Bronson template: 'title'.
  onPrevClick: PropTypes.func, // Bronson template: 'prev-href'.
  prevDisabled: PropTypes.bool, // Bronson template: 'prev-disabled'.
  prevText: PropTypes.string, // Bronson template: 'prev-text'.
  onNextClick: PropTypes.func, // Bronson template: 'next-href'.
  nextDisabled: PropTypes.bool, // Bronson template: 'next-disabled'.
  nextText: PropTypes.string, // Bronson template: 'next-text'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'list'. Use 'Pagination.List' component.
}

/*
 * Bronson List component (nested).
 *
 * Generated React component. Do not modify.
 */
function PaginationItem({ onClick, active, disabled, children, ...otherProps /* in <li> tag */ }) {
  // generated
  const aClassNameList = classNames({
    'c-pagination__link ': true,
    'is-active ': active,
    'is-disabled ': disabled,
  }).trim()

  function renderIfNotActiveAndNotDisabled() {
    return !active && !disabled ? (
      <a href="#" onClick={onClick} className={aClassNameList}>
        {children}
      </a>
    ) : null
  }

  function renderIfActiveOrDisabled() {
    return active || disabled ? (
      <span className={aClassNameList} aria-current={active ? 'page' : false}>
        {children}
      </span>
    ) : null
  }

  // generated main result
  return (
    <li {...otherProps} className="c-pagination__item">
      {renderIfNotActiveAndNotDisabled()}
      {renderIfActiveOrDisabled()}
    </li>
  )
}

PaginationItem.propTypes = {
  onClick: PropTypes.func, // Bronson template: 'href'.
  active: PropTypes.bool, // Bronson template: 'active'.
  disabled: PropTypes.bool, // Bronson template: 'disabled'.
  children: PropTypes.node, // Bronson template: 'index'.
}

PaginationItem.displayName = 'Pagination.Item'
Pagination.Item = PaginationItem
