import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Tooltip } from '../Tooltip/Tooltip'
/*
 * Bronson InfoIcon component.
 *
 * Generated React component. Do not modify.
 */
export function InfoIcon({
  element = 'button',
  className,
  icon,
  children,
  testId,
  tippyProps,
  ...otherProps /* in <button> tag */
}) {
  // generated
  // tag containing a variable or condition
  const CustomElement = `${element}`

  // generated
  const buttonClassNameList = classNames(
    {
      'c-icon ': true,
      'c-info-icon ': true,
      'js-tooltip ': children,
      [`c-icon--[${icon}] `]: true,
    },
    className
  ).trim()

  const typeProp = element === 'button' ? { type: 'button' } : {}

  // generated main result
  return (
    <Tooltip allowHTML content={children} {...tippyProps}>
      <CustomElement
        {...otherProps}
        data-testid={testId}
        {...typeProp}
        className={buttonClassNameList}
        data-tippy-content={children} // applies condition
        data-tippy-placement={children && 'top'}
      />
    </Tooltip>
  )
}

InfoIcon.propTypes = {
  /**
   * Note: Should be wrapped in a focusable element like <button> or <a>.
   * Use e.g. <span> only in special cases where
   */
  element: PropTypes.string,
  className: PropTypes.string, // Bronson template: 'info-icon-modifier'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  children: PropTypes.node, // Bronson template: 'info-icon-tooltip'.
  // eslint-disable-next-line react/forbid-prop-types
  tippyProps: PropTypes.object,
  testId: PropTypes.string, // Added for data-testid attribute.
}
