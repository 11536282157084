import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson NotificationsGroup component.
 *
 * Generated React component. Do not modify.
 *
 * TODO: JS
 */
export function NotificationsGroup({ children, testId, ...otherProps }) {
  // generated main result
  return (
    <section
      id="js-notifications-group"
      className="c-notifications-group js-panelgroup js-is-ready"
      data-testid={testId}
      {...otherProps}
    >
      {children /* use Notification component */}
    </section>
  )
}

NotificationsGroup.propTypes = {
  children: PropTypes.node, // use Notification component
  testId: PropTypes.string, // Added for data-testid attribute.
}

/*
 * Bronson Notification component (nested).
 *
 * Generated React component. Do not modify.
 */
export function Notification({
  shown,
  title,
  headline,
  className,
  status,
  icon,
  custom,
  children,
  buttonText,
  onButtonClick,
  notClosable,
  closeLabel = 'Close',
  text,
  testId,
  ...otherProps
}) {
  // eslint-disable-next-line no-underscore-dangle
  const _title = title || headline

  // generated
  const divClassName = classNames(
    {
      'c-notification ': true,
      [`c-notification--${status}`]: status,
      [`c-notification--custom`]: custom,
    },
    className
  ).trim()

  function renderButton() {
    if (!notClosable) {
      return buttonText ? (
        <button className="c-notification__button c-btn js-notification-close" onClick={onButtonClick} type="button">
          <span className="c-btn__text">{buttonText}</span>
        </button>
      ) : (
        <button
          className="js-notification-close c-notification__close"
          aria-label={closeLabel}
          onClick={onButtonClick}
          type="button"
        />
      )
    }
    return null
  }

  function renderIfStatus() {
    return status ? <i className="c-notification__status-icon" aria-hidden="true" role="img" /> : null
  }

  const iClassName = classNames(
    {
      'c-notification__status-icon ': true,
      'c-icon ': true,
      [`c-icon--[${icon}]`]: icon,
    },
    className
  ).trim()

  function renderIfIcon() {
    return icon ? <i className={iClassName} aria-hidden="true" role="img" /> : null
  }

  function renderIfTitle() {
    return _title ? <h3 className="c-notification__title">{_title}</h3> : null
  }

  // generated main result
  return shown ? (
    <div
      {...otherProps}
      className="c-notification__container js-notification-container js-is-ready"
      data-testid={testId}
    >
      <div className={divClassName}>
        <div className="c-notification__outer-wrap">
          <div className="c-notification__inner-wrap">
            {renderIfStatus()}
            {renderIfIcon()}
            <div className="c-notification__content">
              {renderIfTitle()}
              <p className="c-notification__text">{text || children}</p>
              {text ? children : ''}
            </div>
            {renderButton()}
          </div>
        </div>
      </div>
    </div>
  ) : null
}

Notification.propTypes = {
  className: PropTypes.string, // Bronson template: 'notification-modifier'.
  status: PropTypes.string, // Bronson template: 'notification-status'.
  custom: PropTypes.bool,
  shown: PropTypes.bool,
  children: PropTypes.node, // Bronson template: 'notification-text'.
  headline: PropTypes.string, // Bronson template: 'notification-headline'.
  buttonText: PropTypes.string, // Bronson template: 'notification-button'.
  onButtonClick: PropTypes.func,
  notClosable: PropTypes.bool,
  closeLabel: PropTypes.string, // Bronson template : 'close-label'
  text: PropTypes.node,
  testId: PropTypes.string, // Added for data-testid attribute.
}
