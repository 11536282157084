import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson DefinitionList component.
 *
 * Generated React component. Do not modify.
 */
export function DefinitionList({ className, testId, split, responsive, children, ...otherProps /* in <dl> tag */ }) {
  // generated
  const dlClassNameList = classNames(
    {
      'c-dl-split ': split,
      'c-dl-split--responsive ': responsive,
    },
    className
  ).trim()

  // generated main result
  return (
    <dl {...otherProps} data-testid={testId} className={dlClassNameList}>
      {children /* Use 'DefinitionList.Item' component. */}
    </dl>
  )
}

DefinitionList.propTypes = {
  className: PropTypes.string, // Bronson template: 'definition-list-modifiers'.
  testId: PropTypes.string, // Added for data-testid attribute.
  split: PropTypes.bool,
  responsive: PropTypes.bool,
  children: PropTypes.node, // Bronson template: 'definition-list-items'. Use 'DefinitionList.Item' component.
}

/*
 * Bronson DefinitionListItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function DefinitionListItem({ className, title, children, testId, ...otherProps /* in <dt> tag */ }) {
  // generated main result
  return (
    <>
      <dt className={className} data-testid={testId} {...otherProps}>
        {title}
      </dt>
      <dd data-testid={`${testId}-dd`}>{children}</dd>
    </>
  )
}

DefinitionListItem.propTypes = {
  className: PropTypes.string, // Bronson template: 'modifier'.
  testId: PropTypes.string, // Added for data-testid attribute.
  title: PropTypes.node, // Bronson template: 'title'.
  children: PropTypes.node, // Bronson template: 'description'.
}

DefinitionListItem.displayName = 'DefinitionList.Item'
DefinitionList.Item = DefinitionListItem
