import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson FormSectionGroup component.
 *
 * Generated React component. Do not modify.
 */
export function FormSectionGroup({ className, testId, ...otherProps /* in <div> tag */ }) {
  // generated
  const divClassNameList = classNames(
    {
      'c-form-section-group  ': true,
    },
    className
  ).trim()

  // generated main result
  return <div {...otherProps} data-testid={testId} className={divClassNameList} />
}

FormSectionGroup.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string, // Added for data-testid attribute.
}
