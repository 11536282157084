import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function FormFieldLabel(props) {
  const componentLabelClass = classNames(
    {
      'c-form-field__label  ': true,
    },
    props.className
  ).trim()

  return (
    <label data-testid={props.testId} htmlFor={props.for} className={componentLabelClass}>
      {props.children}
      {props.notion && <span className="c-form-field__notion">*</span>}
    </label>
  )
}

FormFieldLabel.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  for: PropTypes.string,
  notion: PropTypes.bool,
  children: PropTypes.node,
}

FormFieldLabel.defaultProps = {
  notion: false,
}
