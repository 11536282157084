import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * @callback onPressedChangeCB
 * @param {boolean} pressed - new pressed state (true='on', false='off')
 * @return {Void}
 */

/**
 * The FavoriteButton component is a toggle button with pressed states ('on', 'off').
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-favorite-button.html
 *
 * variants:
 * - reversed: reverse order of label and icon
 * - icon only: label is hidden
 *
 * @param {string} [className] - additional classes on main button element, @see 'fav-button-modifier'
 * @param {string} labelOn - label shown with 'on' state, @see 'fav-button-label-on'
 * @param {string} labelOff - label shown with 'off' state, @see 'fav-button-label-off'
 * @param {boolean} [reversed] - reversed variant, @see https://bronson.vwfs.tools/default/components/detail/bronson-favorite-button--favorite-button-reversed.html
 * @param {boolean} [iconOnly] - icon only variant, @see https://bronson.vwfs.tools/default/components/detail/bronson-favorite-button--favorite-button-icon-only.html
 * @param {string} testId - data-testid attribute on main button element
 * @param {onPressedChangeCB} onPressedChange - callback function on pressed
 * @param {Object} otherProps - other props to be passed to main button element
 * @return {JSX.Element} - FavoriteButton component
 */
export function FavoriteButton({
  className,
  labelOn,
  labelOff,
  reversed,
  iconOnly,
  testId,
  pressed: externalPressed = false,
  onPressedChange,
  ...otherProps /* in <button> tag */
}) {
  const [pressed, setPressed] = useState(externalPressed)

  useEffect(() => {
    setPressed(externalPressed)
  }, [externalPressed])

  function togglePressed() {
    setPressed(!pressed)
    onPressedChange(!pressed)
  }

  // generated
  const buttonClassNameList = classNames(
    'c-fav-button',
    'js-fav-button',
    {
      'c-fav-button--reversed': reversed,
      'c-fav-button--icon-only': iconOnly,
    },
    className
  ).trim()

  // generated main result
  return (
    <button
      {...otherProps}
      className={buttonClassNameList}
      data-testid={testId}
      aria-pressed={pressed ? 'true' : 'false'} // force attribute appearance also when 'false'
      onClick={() => togglePressed()}
      type="button"
    >
      <span className="c-fav-button__icon">
        <svg className="c-fav-button__svg" viewBox="0 0 24 24">
          <path d="m22.5 9.6-7.2-1-3.3-6.6-3.2 6.6-7.3 1 5.3 5.2-1.3 7.2 6.5-3.4 6.5 3.4-1.2-7.2z" />
        </svg>
      </span>
      <span className="c-fav-button__label c-fav-button__label--on">{labelOn}</span>
      <span className="c-fav-button__label c-fav-button__label--off">{labelOff}</span>
    </button>
  )
}

FavoriteButton.propTypes = {
  className: PropTypes.string,
  labelOn: PropTypes.node,
  labelOff: PropTypes.node,
  testId: PropTypes.string,
  iconOnly: PropTypes.bool,
  reversed: PropTypes.bool,
  onPressedChange: PropTypes.func,
}
