import React from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson ResponsiveImage component.
 *
 * Generated React component. Do not modify.
 */
export function ResponsiveImage({ src, srcSet, sizes, alt, testId, children, ...otherProps /* in <img> tag */ }) {
  // generated main result
  return (
    <figure data-testid={testId}>
      <img src={src} srcSet={srcSet} sizes={sizes} alt={alt} {...otherProps} />
      {children && <figcaption>{children}</figcaption>}
    </figure>
  )
}

ResponsiveImage.propTypes = {
  src: PropTypes.string, // Bronson template: 'srcset'.
  srcSet: PropTypes.string, // Bronson template: 'srcset'.
  sizes: PropTypes.string, // Bronson template: 'srcset'.
  alt: PropTypes.string,
  children: PropTypes.node, // figcaption
  testId: PropTypes.string, // Added for data-testid attribute.
}
