import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson ListUi component.
 *
 * Generated React component. Do not modify.
 */
export function ListUi({ className, testId, children, ordered, ...otherProps /* in Complex tag name tag */ }) {
  // generated
  // tag containing a variable or condition
  const CustomTagListUiElement = `${ordered ? 'ol' : 'ul'}`

  // generated
  const ClassNameList = classNames(
    {
      'c-list-ui ': true,
      // main class modifier convenience prop extension
      'c-list-ui--ordered ': ordered, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated main result
  return (
    <CustomTagListUiElement {...otherProps} data-testid={testId} className={ClassNameList}>
      {children /* Use 'ListUi.Item' component. */}
    </CustomTagListUiElement>
  )
}

ListUi.propTypes = {
  className: PropTypes.string, // Bronson template: 'list-ui-modifiers'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'list-ui-item'. Use 'ListUi.Item' component.
  /* Convenience props */
  ordered: PropTypes.bool, // Convenience prop for c-list-ui--ordered (Bronson template: 'list-ui-modifiers').
}

/*
 * Bronson ListUiItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function ListUiItem({ children, ...otherProps /* in <li> tag */ }) {
  // generated main result
  return (
    <li {...otherProps} className="c-list-ui__item">
      {children}
    </li>
  )
}

ListUiItem.propTypes = {
  children: PropTypes.node, // Bronson template: 'title'.
}

ListUiItem.displayName = 'ListUi.Item'
ListUi.Item = ListUiItem
