import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/*
 * Bronson OrderedList component.
 *
 * Generated React component. Do not modify.
 */
export function OrderedList({ nestedCounter, testId, children, ...otherProps /* in <ol> tag */ }) {
  // generated
  const olClassNameList = classNames({
    'c-list-nested-counter ': nestedCounter,
  }).trim()

  // generated main result
  return (
    <ol {...otherProps} data-testid={testId} className={olClassNameList}>
      {children /* Use 'OrderedList.Item' component. */}
    </ol>
  )
}

OrderedList.propTypes = {
  nestedCounter: PropTypes.bool, // Bronson template: 'ordered-list-nested-counter'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'item'. Use 'OrderedList.Item' component.
}

/*
 * Bronson Item component (nested).
 *
 * Generated React component. Do not modify.
 */
function Item({ children, ...otherProps /* in <li> tag */ }) {
  // generated main result
  return <li {...otherProps}>{children}</li>
}

Item.propTypes = {
  children: PropTypes.node, // Bronson template: 'content'.
}

Item.displayName = 'OrderedList.Item'
OrderedList.Item = Item
