/**
 * Handles the adding, updating and deletion of filters.
 * @param {object} state
 * @param {object} action
 * @return {{filters}}
 */
export function productFilterReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FILTER': {
      console.log('update filter => state', state)
      console.log('update filter => action.payload', action.payload)
      return {
        ...state,
        filters: action.payload, // @TODO: just if update always holds the complete filter state
      }
    }
    case 'DELETE_FILTER': {
      console.log('delete filter => state', state)
      console.log('delete filter => action.payload', action.payload)
      const filterToReset = state.defaultFilters.find((f) => f.field === action.payload)
      console.log('delete filter => filterToReset', filterToReset)
      return {
        ...state,
        filters: state.filters.map((filter) => {
          return filterToReset?.field === filter.field ? filterToReset : filter
        }),
      }
    }
    case 'DELETE_ALL_FILTER': {
      console.log('reset filter => state', state)
      return { ...state, filters: state.defaultFilters }
    }
    case 'HIDE_FILTERS':
      return { ...state, isInteractionLayerHidden: true }
    case 'SHOW_FILTERS':
      return { ...state, isInteractionLayerHidden: false }
    default:
      throw new Error('ProductSearchReducer → Unknown action.')
  }
}

export const productFilterReducerActions = {
  updateFilter: 'UPDATE_FILTER',
  deleteFilter: 'DELETE_FILTER',
  deleteAllFilter: 'DELETE_ALL_FILTER',
  hideFilters: 'HIDE_FILTERS',
  showFilters: 'SHOW_FILTERS',
}

/**
 * The ProductFilter state definition.
 * @typedef {{isInteractionLayerHidden: ProductFilterVisibility, filters: ProductFilterValues}} ProductFilterState
 */

/**
 * Determines whether the filters are visible.
 * @typedef {boolean} ProductFilterVisibility
 */

/**
 * A list of objects where the first index is the key and the second the value.
 * @typedef {Array<[string, (ProductFilterRangeValue | ProductFilterValue)]>} ProductFilterValues
 */

/**
 * @typedef {{minValue?: (number|string), maxValue?: (number|string), unit: string}} ProductFilterRangeValue
 */

/**
 *
 * @typedef {{value?: (number|string), unit: string}} ProductFilterValue
 */
