import React from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson UnorderedList component.
 *
 * Generated React component. Do not modify.
 */
export function UnorderedList({ testId, children, ...otherProps /* in <ul> tag */ }) {
  // generated main result
  return (
    <ul {...otherProps} data-testid={testId} className="c-ul">
      {children /* Use 'UnorderedList.Item' component. */}
    </ul>
  )
}

UnorderedList.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'item'. Use 'UnorderedList.Item' component.
}

/*
 * Bronson Item component (nested).
 *
 * Generated React component. Do not modify.
 */
function Item({ children, ...otherProps /* in <li> tag */ }) {
  // generated main result
  return <li {...otherProps}>{children}</li>
}

Item.propTypes = {
  children: PropTypes.node, // Bronson template: 'content'.
}

Item.displayName = 'UnorderedList.Item'
UnorderedList.Item = Item
