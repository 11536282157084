/* eslint-disable react-hooks/exhaustive-deps */
// TODO: check deps
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import nouislider from 'nouislider'

export function CustomRangeSlider(props) {
  const sliderRef = useRef(null)
  const sliderInstance = useRef(null)

  useEffect(() => {
    sliderInit()

    return () => sliderCleanup()
  }, [])

  const value = React.useRef(null)
  value.current = props.value

  useEffect(() => {
    if (parseFloat(sliderInstance.current.get()) !== props.value) {
      sliderInstance.current.set(props.value)
    }
  }, [props.value])

  useEffect(() => {
    removePipsClickHandlers()
    cleanUpPipsUpdateOnChange()
    sliderInstance.current.updateOptions({
      range: props.range,
      step: props.step,
      pips: props.pips,
    })
    if (props.pips) {
      addPipsClickHandlers()
      setUpPipsUpdateOnChange()
    }
  }, [
    props.range.min,
    props.range.max,
    props.step,
    props.pips && props.pips.mode,
    props.pips && props.pips.density,
    props.pips && props.pips.format && props.pips.format.to,
  ])

  const componentClasses = classNames(
    {
      'c-custom-range-slider  ': true,
      'custom-range-slider-tooltip--top  ':
        props.tooltips && (props.tooltipPosition === 'top' || !props.tooltipPosition),
      'custom-range-slider-tooltip--bottom  ': props.tooltipPosition === 'bottom',
    },
    props.className
  ).trim()

  const wrapTooltip = (customTooltips) => {
    const formatFunc = typeof customTooltips.to === 'function' ? customTooltips.to : (v) => v
    const tooltipMarkup = {
      to: (v) =>
        `<div class="c-custom-range-slider__tooltip">${formatFunc(
          v
        )}</div><div class="c-custom-range-slider__arrow"> </div>`,
    }
    const count = props.value.length || 1
    if (count === 1) {
      return tooltipMarkup
    }
    const result = []
    for (let i = 0; i < count; i += 1) {
      result.push(tooltipMarkup)
    }
    return result
  }

  function sliderInit() {
    sliderInstance.current = nouislider.create(sliderRef.current, {
      cssPrefix: 'c-custom-range-slider',
      cssClasses: {
        active: '--active',
        background: '--background',
        draggable: '--draggable',
        horizontal: '--horizontal',
        vertical: '--vertical',
        ltr: '--left-to-right',
        rtl: '--right-to-left',
        target: '__target',
        base: '__base',
        origin: '__origin',
        handle: '__thumb',
        handleLower: '__thumb--lower',
        handleUpper: '__thumb--upper',
        touchArea: '__touch-area',
        connects: '__connect-container',
        connect: '__connect',
        drag: '--drag',
        tap: '--tap',
        tooltip: '__tooltip-wrapper',
        pips: '__pips',
        pipsHorizontal: '__pips--horizontal',
        pipsVertical: '__pips--vertical',
        marker: '__marker',
        markerHorizontal: '__marker--horizontal',
        markerVertical: '__marker--vertical',
        markerNormal: '__marker--normal',
        markerLarge: '__marker--large',
        markerSub: '__marker--sub',
        value: '__value',
        valueHorizontal: '__value--horizontal',
        valueVertical: '__value--vertical',
        valueNormal: '__value--normal',
        valueLarge: '__value--large',
        valueSub: '__value--sub',
      },
      connect: props.connect || [true, false],
      start: props.value,
      range: props.range,
      step: props.step,
      pips: props.pips,
      tooltips: props.tooltips && wrapTooltip(props.tooltips),
    })

    if (props.pips) {
      addPipsClickHandlers()
      setUpPipsUpdateOnChange()
    }

    if (props.onChange) {
      sliderInstance.current.on('update', (values) => {
        if (values.length === 1) {
          // single handle
          if (parseFloat(value.current) !== parseFloat(values[0])) {
            props.onChange(parseFloat(values[0]))
          }
        } else {
          // multiple handles
          if (values.length !== value.current.length) {
            throw new Error('unexpected array length of values vs. value.current')
          }
          const hasChanged = values.some((val, i) => parseFloat(value.current[i]) !== parseFloat(val))
          if (hasChanged) {
            props.onChange(values)
          }
        }
      })
    }
    if (props.onSet) {
      sliderInstance.current.on('set', (values) => {
        if (parseFloat(value.current) !== parseFloat(values[0])) {
          props.onSet(parseFloat(values[0]))
        }
      })
    }
  }

  function sliderCleanup() {
    removePipsClickHandlers()
    sliderInstance.current.destroy()
  }

  const updateSliderValue = ({ target }) => {
    const currentValue = target.hasAttribute('data-value')
      ? target.getAttribute('data-value')
      : target.nextElementSibling.getAttribute('data-value')

    sliderInstance.current.set(currentValue)
  }

  function addPipsClickHandlers() {
    if (sliderRef.current) {
      sliderRef.current
        .querySelectorAll('.c-custom-range-slider__marker, .c-custom-range-slider__value')
        .forEach((node) => node.addEventListener('click', updateSliderValue))
    }
  }

  function removePipsClickHandlers() {
    if (sliderRef.current) {
      sliderRef.current
        .querySelectorAll('.c-custom-range-slider__marker, .c-custom-range-slider__value')
        .forEach((node) => node.removeEventListener('click', updateSliderValue))
    }
  }

  function setUpPipsUpdateOnChange() {
    if (sliderRef.current) {
      const valueProperty = Symbol('Marker Value')
      const markers = sliderRef.current.querySelectorAll('.c-custom-range-slider__marker')
      markers.forEach((marker) => {
        /**
         * We're using DOM live list here, we need to mutate it
         */
        // eslint-disable-next-line no-param-reassign
        marker[valueProperty] = parseFloat(marker.nextElementSibling.getAttribute('data-value'))
      })
      sliderInstance.current.on('update.pipsUpdate', (currentValue) => {
        const currentValueFloat = parseFloat(currentValue)
        markers.forEach((marker) => {
          marker.classList.remove('in-range', 'is-active')
          if (currentValueFloat >= marker[valueProperty]) {
            marker.classList.add(currentValueFloat === marker[valueProperty] ? 'is-active' : 'in-range')
          }
        })
      })
    }
  }

  function cleanUpPipsUpdateOnChange() {
    sliderInstance.current.off('update.pipsUpdate')
  }

  return <div data-testid={props.testId} ref={sliderRef} className={componentClasses} disabled={props.disabled} />
}

CustomRangeSlider.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  connect: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  range: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  step: PropTypes.number,
  pips: PropTypes.shape({
    mode: PropTypes.string,
    density: PropTypes.number,
    format: PropTypes.shape({
      to: PropTypes.func,
    }),
  }),
  disabled: PropTypes.bool,
  tooltips: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.shape({
      to: PropTypes.func,
    }),
  ]),
  tooltipPosition: PropTypes.oneOf(['top', 'bottom']),
  onChange: PropTypes.func,
  onSet: PropTypes.func,
}

CustomRangeSlider.defaultProps = {
  value: 0,
}
