import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Progress } from '../Progress/Progress'
/*
 * Bronson UploadItem component.
 *
 * Generated React component. Do not modify.
 */
export function UploadItem({
  fileSize,
  className,
  fileType,
  fileName,
  filesizeProgress,
  icon,
  progressValue,
  message,
  testId,
  loading,
  success,
  error,
  onMainButtonClick,
  onSecondaryButtonClick,
  hideMainButton,
  hideSecondaryButton,
  ...otherProps /* in <div> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      'c-upload-item ': true,
      // main class modifier convenience prop extension
      'is-loading ': loading, // Convenience prop from Bronson variants.
      'is-success ': success, // Convenience prop from Bronson variants.
      'is-error ': error, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    'c-upload-item__icon ': true,
    'c-icon ': true,
    [`c-icon--[${icon}] `]: true,
  }).trim()

  // generated
  function renderIfUploadItemMessage() {
    if (message) {
      return <span className="c-upload-item__message">{message}</span>
    }
    return null
  }

  function renderIfMain() {
    return hideMainButton ? null : (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        className="c-upload-item__interaction-icon c-upload-item__interaction-icon--main"
        onClick={onMainButtonClick}
        type="button"
      />
    )
  }

  function renderIfSecondary() {
    return hideSecondaryButton ? null : (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        className="c-upload-item__interaction-icon c-upload-item__interaction-icon--secondary"
        onClick={onSecondaryButtonClick}
        type="button"
      />
    )
  }

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={divClassNameList}>
      <i
        className={iClassNameList}
        data-filetype={fileType} // applies condition
      />
      <div className="c-upload-item__content">
        <div className="c-upload-item__title-row">
          <p className="c-upload-item__title">{fileName}</p>
          <div className="c-upload-item__interaction-icons">
            {renderIfMain()}
            {renderIfSecondary()}
            <i className="c-upload-item__status-icon" />
          </div>
        </div>
        <div className="c-upload-item__progress">
          <Progress value={progressValue} success={success} error={error} />
        </div>
        <p className="c-upload-item__subtitle-row">
          <span className="c-upload-item__filesize-progress">{filesizeProgress} of </span>
          <span className="c-upload-item__filesize">{fileSize}</span>
          <span className="c-upload-item__filesize-progress"> ({progressValue}% uploaded)</span>
          {renderIfUploadItemMessage()}
        </p>
      </div>
    </div>
  )
}

UploadItem.propTypes = {
  fileSize: PropTypes.string, // Bronson template: 'upload-item-file-size'.
  className: PropTypes.string, // Bronson template: 'upload-item-modifier'.
  fileType: PropTypes.string, // Bronson template: 'upload-item-file-type'.
  fileName: PropTypes.string, // Bronson template: 'upload-item-file-name'.
  bronsonProgress: PropTypes.node, // Bronson template: 'bronson-progress'.
  filesizeProgress: PropTypes.string, // Bronson template: 'upload-item-filesize-progress'.
  icon: PropTypes.string, // Bronson template: 'upload-item-icon'.
  progressValue: PropTypes.string, // Bronson template: 'progress-value'.
  message: PropTypes.node, // Bronson template: 'upload-item-message'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onMainButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  hideMainButton: PropTypes.bool,
  hideSecondaryButton: PropTypes.bool,
  /* Convenience props */
  loading: PropTypes.bool, // Convenience prop for is-loading (Bronson template: 'upload-item-modifier').
  success: PropTypes.bool, // Convenience prop for is-success (Bronson template: 'upload-item-modifier').
  error: PropTypes.bool, // Convenience prop for is-error (Bronson template: 'upload-item-modifier').
}
