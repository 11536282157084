import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Progress } from '../Progress/Progress'
import { FavoriteButton } from '../FavoriteButton/FavoriteButton'
import useResizeObserver from '../../features/useResizeObserver'
import { apply } from '../../features/ContainerQuery'

/**
 * Bronson ProductCard component.
 * Generated React component. Do not modify.
 *
 * The props can be mixed and matched if need be.
 * @param {object}   buttons - A combination of a primary and secondary button to display main actions.
 * @param {object}   carousel - A collection of images that describe the product.
 * @param {object}   tag - Can be used to highlight, if the product is new, or the price is reduced.
 * @param {object}   tagList - Can be used to display search categories for product.
 * @param {string}   tagListTitle - Title of tag list.
 * @param {object}   priceBox - Display price of product.
 * @param {string}   productTitle - Title of product.
 * @param {string}   [productSubtitle] - Optional subtitle of product.
 * @param {string}   [progressLabel] - Label of progress bar.
 * @param {string}   [text] - Present information about the product inside the body.
 * @param {boolean}  [expandable] - Optional expandable content to display further information.
 * @param {boolean}  [defaultExpanded] - Determines, if the Product Card should be expanded by default.
 * @param {string}   [expandableTitle] - Title of expandable content.
 * @param {string}   [expandableText] - Text of expandable content.
 * @param {string}   [expandableTriggerLabel] - Label beside the icon, that triggers expandable content.
 * @param {object}   [expandableDefinitionList] - Definition List, that provides further information about product.
 * @param {object}   [footnotes] - Option to display footnotes at the bottom of the product card.
 * @param {string}   [testId] - data-testid attribute on Product Card element.
 * @param {function} [onClick] - Defines onClick event.
 * @return {JSX.Element} - The ProductCard element.
 * @constructor
 */
export function ProductCard({
  buttons,
  carousel,
  tag,
  tagList,
  tagListTitle,
  priceBox,
  productTitle,
  productSubtitle,
  progressLabel,
  text,
  expandable,
  defaultExpanded,
  expandableTitle,
  expandableText,
  expandableDefinitionList,
  expandableTriggerLabel,
  footnotes,
  testId,
  onClick,
  ...otherProps /* in <article> tag */
}) {
  const cardRef = useRef(null)
  const [expanded, setExpanded] = useState(defaultExpanded)

  const applyQC = useCallback(apply, [apply])
  useResizeObserver({
    ref: cardRef,
    onResizeHandler: applyQC,
  })

  // generated
  function renderIfTag() {
    if (tag) {
      return <div className="c-product-card__features__item c-product-card__features__item--tag">{tag}</div>
    }
    return null
  }

  // generated
  function renderIfSubtitle() {
    if (productSubtitle) {
      return <h5 className="c-product-card__subtitle">{productSubtitle}</h5>
    }
    return null
  }

  // generated
  function renderIfProgress() {
    if (progressLabel) {
      return (
        <div className="c-product-card__progress__wrapper">
          <Progress value="20" className="c-product-card__progress" />
          <p className="c-product-card__progress__label">{progressLabel}</p>
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfBody() {
    if (text) {
      return (
        <div className="c-product-card__body">
          <p>{text}</p>
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfTitle() {
    if (expandableTitle) {
      return <h5 className="c-product-card__expandable-panel__title">{expandableTitle}</h5>
    }
    return null
  }

  // generated
  function renderIfText() {
    if (expandableText) {
      return <p className="c-product-card__expandable-panel__text">{expandableText}</p>
    }
    return null
  }

  // generated
  function renderIfExpandable() {
    if (expandable) {
      return (
        <div className="c-product-card__expandable js-card-expandable">
          <button
            type="button"
            className="c-btn c-btn--link c-btn--small c-btn--simple js-card-expandable__trigger"
            aria-expanded={expanded}
            onClick={() => {
              return setExpanded((prevExpanded) => !prevExpanded)
            }}
          >
            <i className="c-icon c-btn__icon c-icon--[semantic-expand]" />
            <span className="c-btn__text">{expandableTriggerLabel}</span>
          </button>
          <div className="c-product-card__expandable-panel js-card-expandable__panel" aria-hidden={!expanded}>
            {renderIfTitle()}
            {renderIfText()}
            {expandableDefinitionList}
            <a className="c-btn c-btn--link c-btn--small" onClick={onClick}>
              <i className="c-btn__icon c-icon c-icon--[semantic-forward]" aria-hidden="true" role="img" />
              <span className="c-btn__text">See full details </span>
            </a>
          </div>
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfFootnotes() {
    if (footnotes) {
      return footnotes
    }
    return null
  }

  // generated main result
  return (
    <article
      ref={cardRef}
      className="c-product-card"
      data-container-query="product-card"
      data-testid={testId}
      {...otherProps}
    >
      <div className="c-product-card__header">
        <div className="c-product-card__features">
          <div className="c-product-card__features__item c-product-card__features__item--fav-button">
            <FavoriteButton labelOff="Save for later" labelOn="Saved" className="c-fav-button--reversed" />
          </div>
          {renderIfTag()}
        </div>
        <div className="c-product-card__tag-list">
          <div className="c-product-card__tag-list__title">{tagListTitle}</div>
          {tagList}
        </div>
      </div>
      <div className="c-product-card__inline c-product-card__media">{carousel}</div>
      <div className="c-product-card__inline c-product-card__meta">
        <h4 className="c-product-card__title">{productTitle}</h4>
        {renderIfSubtitle()}
      </div>
      <div className="c-product-card__inline c-product-card__pricing">
        {priceBox}
        {renderIfProgress()}
      </div>
      {renderIfBody()}
      <div className="c-product-card__buttons">
        {buttons.map((button, index) => {
          const cssClasses = classNames('c-product-card__buttons__item', button.props.className)
          const keyIdx = `card-button-${index}`
          return React.cloneElement(button, { className: cssClasses, key: keyIdx })
        })}
      </div>
      {renderIfExpandable()}
      {renderIfFootnotes()}
    </article>
  )
}

ProductCard.propTypes = {
  carousel: PropTypes.element, // Bronson template: 'carousel'. Use 'Carousel' component.
  tag: PropTypes.element, // Bronson template: 'tag'. Use 'Tag' component.
  tagList: PropTypes.element, // Bronson template: 'tag-list'. Use 'TagList' component.
  tagListTitle: PropTypes.string, // Bronson template: 'tag-list.title'.
  priceBox: PropTypes.element, // Bronson template: 'price-box'. Use 'price box' component.
  productTitle: PropTypes.string, // Bronson template: 'product.title'.
  productSubtitle: PropTypes.string, // Bronson template: 'product.subtitle'.
  progressLabel: PropTypes.string, // Bronson template: 'progress.label'.
  text: PropTypes.string, // Bronson template: 'body.text'.
  expandable: PropTypes.bool, // Bronson template: 'expandable'.
  defaultExpanded: PropTypes.bool, // Bronson template: 'aria-expanded' attribute on 'c-product-card__expandable' button.
  expandableTitle: PropTypes.string, // Bronson template: 'expandable.title'.
  expandableText: PropTypes.string, // Bronson template: 'expandable.text'.
  expandableDefinitionList: PropTypes.element, // Bronson template: '@bronson-definition-list-horizontal'.
  expandableTriggerLabel: PropTypes.string, // Bronson template: 'expandable.trigger.label'.
  footnotes: PropTypes.element, // Bronson template: 'footnotes'. Use 'Footnote' component.
  testId: PropTypes.string, // Added for data-testid attribute.
  buttons: PropTypes.arrayOf(PropTypes.element), // Bronson template: 'buttons'. Use 'Button' component.
  onClick: PropTypes.func, // Added for onClick attribute.
}
