import React from 'react'
import PropTypes from 'prop-types'

/*
 * Bronson ListInline component.
 *
 * Generated React component. Do not modify.
 */
export function ListInline({ testId, children, ...otherProps /* in <ul> tag */ }) {
  // generated main result
  return (
    <ul {...otherProps} data-testid={testId} className="o-list-inline">
      {children /* Use 'ListInline.Item' component. */}
    </ul>
  )
}

ListInline.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'item'. Use 'ListInline.Item' component.
}

/*
 * Bronson Item component (nested).
 *
 * Generated React component. Do not modify.
 */
function Item({ children, ...otherProps /* in <li> tag */ }) {
  // generated main result
  return <li {...otherProps}>{children}</li>
}

Item.propTypes = {
  children: PropTypes.node, // Bronson template: 'content'.
}

Item.displayName = 'ListInline.Item'
ListInline.Item = Item
